export function getDomainFromEmail(email: string) {
  const partsOfEmail = email.split('@');
  if (partsOfEmail.length < 2) {
    return '';
  }
  return partsOfEmail[1];
}

// format email name gets the first part (part before @) of the email
// and formats it to look like a name
// formatEmailName('john.doe123@example.com'); // Output: "John Doe 123"
// mainly used incase there's no name for the email
export const formatEmailName = (email: string): string => {
  // Extract the part before the @ symbol
  const namePart = email.split('@')[0];

  // Split the name part by common separators and numbers
  const parts = namePart.split(/[._-]|\d+/).filter(Boolean);

  // Extract numbers separately
  const numbers = namePart.match(/\d+/g) || [];

  // Capitalize the first letter of each part and lowercase the rest
  const formattedParts = parts.map(
    (part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
  );

  // Combine formatted parts and numbers
  const result = formattedParts.concat(numbers).join(' ');

  return result.trim();
};
