// getInitials - takes a name and limit and returns the initials of the name
// e.g. name = John Doe and limit = 2 -> JD
// e.g. name = John Doe and limit = 1 -> J
export function getInitials(name: string, limit: number): string {
  if (!name) return '';

  return name
    .split(' ')
    .map((n) => n.charAt(0))
    .join('')
    .slice(0, limit)
    .toUpperCase();
}

export function toTitleCase(str: string): string {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
  );
}
