interface IconProps {
  src?: string;
  alt?: string;
  bgColor?: string;
  hoverBgColor?: string;
  borderColor?: string;
  borderWidth?: string;
  badge?: string;
}

function Icon({
  src = '',
  alt = '',
  bgColor = '#000',
  hoverBgColor = '#2A2B2B',
  borderColor = '#2A2B2B',
  badge = '',
  borderWidth = '0',
}: IconProps) {
  return (
    <div className="relative">
      {badge && (
        <span className="absolute -top-1 -right-1 z-10 rounded-full bg-red-500 px-1 text-xs text-white min-w-4 text-center">
          {badge}
        </span>
      )}
      <div
        className={`rounded-lg flex items-center justify-center overflow-hidden w-10 h-10 text-sm p-2.5 cursor-pointer bg-${bgColor} hover:bg-${hoverBgColor} ${borderWidth ? 'border-light' : ''} border-${borderColor}`}
      >
        <img src={src} alt={alt} className="w-full h-full object-cover" />
      </div>
    </div>
  );
}

export default Icon;
