import { useState } from 'react';

import * as Tabs from '@radix-ui/react-tabs';

import { Badge } from 'components/Badge';

import AllEvents from 'components/Events';

import {
  ALL_EMAILS_TAB,
  SENDER_EMAIL_TAB,
  RECEIVER_EMAIL_TAB,
  LINK_EMAIL_TAB,
  ATTACHMENT_EMAIL_TAB,
} from 'constants/tabName';

import { useGetVectorsQuery } from 'service/vectors';

import { GetEventsQueryParams } from 'models/events';
import { Metric } from 'models/metric';

import { useGetFlaggedEventsQuery } from 'service/eventsApi';
import { useGetTopDetectionsQuery } from 'service/topDetections';
import { VectorType as Vector } from 'enums/vectorTypes';

import { kFormatter } from 'utils/numberFormatter';
import { useOrgId } from 'hooks/useOrgId';
import { filterByInferencesCode } from 'utils/filterInferences';
import { VectorType } from 'models/emailVector';

import { EmailVectors } from './EmailVector';
import { EmailVectorsFrequency } from './EmailVectorsFrequency';
import { VectorFrequency } from './VectorFrequency';

// EmailAttributeTabsItem is custom type for each tab item
// it contains :
// - id of the tab which is used for switching the tab
// - the name of the tab
// - the formatted count of the tab like 2.7K for 2,700
type EmailAttributeTabsItem = {
  id: string;
  name: string;
  vectorName: VectorType;
};

export default function EmailAttributes() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [OrgId] = useOrgId();
  const { data } = useGetVectorsQuery(
    {
      orgId: OrgId,
    },
    {
      skip: activeTabIndex !== 0,
    }
  );

  const tabs: EmailAttributeTabsItem[] = [
    {
      id: ALL_EMAILS_TAB,
      name: 'All Emails Vectors',
      vectorName: VectorType.DEFAULT,
    },
    {
      id: SENDER_EMAIL_TAB,
      name: 'Sender',
      vectorName: VectorType.SENDER,
    },
    // commenting out the receiver tab as it's not used yet
    {
      id: RECEIVER_EMAIL_TAB,
      name: 'Recepients',
      vectorName: VectorType.RECIPIENT,
    },
    {
      id: LINK_EMAIL_TAB,
      name: 'URL',
      vectorName: VectorType.URL,
    },
    {
      id: ATTACHMENT_EMAIL_TAB,
      name: 'Attachments',
      vectorName: VectorType.ATTACHMENT,
    },
  ];

  const [tableFilters, setTableFilters] = useState<GetEventsQueryParams>({
    limit: 10,
    offset: 0,
    from: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString(),
    to: new Date().toISOString(),
    sortBy: 'time',
    sortOrder: 'desc',
    vector: VectorType.DEFAULT, // By default get all vectors events
  });

  const { data: flaggedData, isLoading } = useGetFlaggedEventsQuery({
    orgId: OrgId,
    ...tableFilters,
  });

  // fetching sender detections data
  const { data: detections } = useGetTopDetectionsQuery({
    orgId: OrgId,
  });

  const getInferenceDetailsByVector = (vector: Vector): { hits: Metric[]; total: number } => {
    const vectorDetails = filterByInferencesCode(vector, detections?.hits || []);
    const vectorTotal = vectorDetails.reduce((acc, item) => acc + item.count, 0);
    return { hits: vectorDetails, total: vectorTotal };
  };

  function getCountInFormat(tabId: string) {
    switch (tabId) {
      case ALL_EMAILS_TAB:
        return kFormatter(data?.total || 0);
      case SENDER_EMAIL_TAB:
        return kFormatter(getInferenceDetailsByVector('SENDER').total || 0);
      case RECEIVER_EMAIL_TAB:
        return kFormatter(getInferenceDetailsByVector('RECIPIENT').total || 0);
      case ATTACHMENT_EMAIL_TAB:
        return kFormatter(getInferenceDetailsByVector('ATTACHMENT').total || 0);
      case LINK_EMAIL_TAB:
        return kFormatter(getInferenceDetailsByVector('URL').total || 0);
      default:
        return '0';
    }
  }

  // Update the events query filter with corresponding vector type
  const handleSetTab = (idx: number) => {
    setActiveTabIndex(idx);

    setTableFilters({
      limit: 10,
      offset: 0,
      from: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString(),
      to: new Date().toISOString(),
      sortBy: 'time',
      sortOrder: null,
      vector: tabs[idx]?.vectorName ?? '',
    });
  };

  return (
    <div className="mt-3">
      <Tabs.Root className="flex flex-col w-full" defaultValue={ALL_EMAILS_TAB}>
        <Tabs.List className="flex items-center gap-20 border-b border-border-primary">
          {tabs.map((tab, index) => (
            <Tabs.Trigger
              key={tab.id}
              value={tab.id}
              className="text-light-grey  data-[state=active]:text-black text-sm flex items-center justify-start"
              onClick={() => handleSetTab(index)}
            >
              <div
                className={`flex items-center gap-2 py-3 px-1 ${activeTabIndex === index ? 'border-black border-b-2' : ''}`}
              >
                <span>{tab.name}</span>
                <div className="flex items-center gap-2">
                  <Badge
                    text={getCountInFormat(tab.id)}
                    bgColor={activeTabIndex === index ? 'black' : 'primary-background'}
                    borderColor={activeTabIndex === index ? 'white' : 'light-grey'}
                    textColor={activeTabIndex === index ? 'white' : 'light-grey'}
                  />
                  {/* {tab.shouldShowAlert && (
                    <img src={iconErrorAlert} className="w-3.5 h-3.5" alt="alert-icon" />
                  )} */}
                </div>
              </div>
            </Tabs.Trigger>
          ))}
        </Tabs.List>
        <div className="mt-8">
          <Tabs.Content id={ALL_EMAILS_TAB} value={ALL_EMAILS_TAB}>
            <div className="flex items-stretch gap-4">
              <EmailVectors
                title="Email Vectors"
                total={data?.total || 0}
                vectors={data?.vectors || []}
                comingSoon={[]}
              />
              <EmailVectorsFrequency />
            </div>
            <AllEvents
              title="All Events"
              isLoading={isLoading}
              filters={tableFilters}
              setFilters={setTableFilters}
              data={flaggedData}
            />
          </Tabs.Content>
          <Tabs.Content id={SENDER_EMAIL_TAB} value={SENDER_EMAIL_TAB}>
            <div className="flex items-stretch gap-4">
              <EmailVectors
                title="Sender Detections"
                total={getInferenceDetailsByVector('SENDER').total || 0}
                vectors={getInferenceDetailsByVector('SENDER').hits || []}
                comingSoon={[]}
              />
              <VectorFrequency vector={VectorType.SENDER} title="Sender Detection Frequency" />
            </div>
            <AllEvents
              title="Sender Events"
              isLoading={isLoading}
              filters={tableFilters}
              setFilters={setTableFilters}
              data={flaggedData}
            />
          </Tabs.Content>
          <Tabs.Content id={RECEIVER_EMAIL_TAB} value={RECEIVER_EMAIL_TAB}>
            <div className="flex items-stretch gap-4">
              <EmailVectors
                title="Recipient Detections"
                total={getInferenceDetailsByVector('RECIPIENT').total || 0}
                vectors={getInferenceDetailsByVector('RECIPIENT').hits || []}
                comingSoon={[]}
              />
              <VectorFrequency
                vector={VectorType.RECIPIENT}
                title="Recipient Detection Frequency"
              />
            </div>
            <AllEvents
              title="Recipient Events"
              isLoading={isLoading}
              filters={tableFilters}
              setFilters={setTableFilters}
              data={flaggedData}
            />
          </Tabs.Content>
          <Tabs.Content id={LINK_EMAIL_TAB} value={LINK_EMAIL_TAB}>
            <div className="flex items-stretch gap-4">
              <EmailVectors
                title="URL Detections"
                total={getInferenceDetailsByVector('URL').total || 0}
                vectors={getInferenceDetailsByVector('URL').hits || []}
                comingSoon={[]}
              />
              <VectorFrequency vector={VectorType.URL} title="URL Detection Frequency" />
            </div>
            <AllEvents
              title="URL Events"
              isLoading={isLoading}
              filters={tableFilters}
              setFilters={setTableFilters}
              data={flaggedData}
            />
          </Tabs.Content>
          <Tabs.Content id={ATTACHMENT_EMAIL_TAB} value={ATTACHMENT_EMAIL_TAB}>
            <div className="flex items-stretch gap-4">
              <EmailVectors
                title="Attachment Detections"
                total={getInferenceDetailsByVector('ATTACHMENT').total || 0}
                vectors={getInferenceDetailsByVector('ATTACHMENT').hits || []}
                comingSoon={[]}
              />
              <VectorFrequency
                vector={VectorType.ATTACHMENT}
                title="Attachment Detection Frequency"
              />
            </div>
            <AllEvents
              title="Attachment Events"
              isLoading={isLoading}
              filters={tableFilters}
              setFilters={setTableFilters}
              data={flaggedData}
            />
          </Tabs.Content>
        </div>
      </Tabs.Root>
    </div>
  );
}
