import { Navbar } from 'components/Layouts/Navbar';
import * as Tabs from '@radix-ui/react-tabs';
import { SettingsTab } from 'components/Settings/SettingsTab';
import { useState } from 'react';
import { ReactComponent as IconSearch } from 'assets/icons/magnifying-glass.svg';
import { InternalUsers } from 'components/MailAccounts/InternalUsers';
import { useAppSelector } from 'redux-store/hooks';
import { useQueryParam } from 'hooks/useQueryParam';
import ExternalUsers from 'components/MailAccounts/ExternalUsers';
import { useGetExternalUsersQuery, useSuggestedVendorsQuery } from 'service/externalUsers';
import { useOrgId } from 'hooks/useOrgId';
import { Pagination } from 'models/pagination';
import { AlertBar } from 'components/Alert/AlertBar';
import { SuggestedDomains } from 'components/MailAccounts/SuggestedDomain';

export default function Users() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleSelectTab = (tabIdx: number) => {
    setActiveTabIndex(tabIdx);
  };

  const { totalUserCount, externalUserCount } = useAppSelector((state) => ({
    totalUserCount: state.users.count,
    externalUserCount: state.users.externalCount,
  }));

  const [, setParamValue] = useQueryParam({ key: 'q' });

  const handleSearch = (query: string) => {
    // Set a timeout to update the debouncedSearchQuery after the specified delay
    const handler = setTimeout(() => {
      setParamValue(query);
    }, 500);

    // Clear the timeout if searchQuery changes before the delay period elapses
    return () => {
      clearTimeout(handler);
    };
  };

  const [OrgId] = useOrgId();

  useGetExternalUsersQuery({
    limit: 10,
    offset: 0,
    searchQuery: '',
    orgId: OrgId,
  });

  const [filters, setFilters] = useState<Pagination>({
    limit: 10,
    offset: 0,
  });

  const { data: suggestedVendorResults, isLoading } = useSuggestedVendorsQuery(
    {
      orgId: OrgId,
      ...filters,
    },
    {
      skip: activeTabIndex !== 1,
    }
  );

  const [openSuggestedVendors, setOpenSuggestedVendors] = useState(false);

  const handleReview = () => {
    setOpenSuggestedVendors(true);
  };

  const handleFetchMore = () => {
    if (suggestedVendorResults) {
      setFilters({
        ...filters,
        offset: filters.offset + suggestedVendorResults.limit,
      });
    }
  };

  return (
    <>
      <div className="w-full flex h-full">
        <div className="flex-1 px-8 py-5 overflow-y-auto no-scrollbar">
          <Navbar title="Mail Accounts" showBackButton showLogout={false} />
          {suggestedVendorResults?.total && !isLoading && activeTabIndex === 1 ? (
            <AlertBar
              title={`${suggestedVendorResults.total} External Account Detections`}
              description="Our system has detected your organisation’s vendor & regulator list. Please take a look and approve"
              actionText="Review"
              onAction={() => handleReview()}
            />
          ) : null}
          <Tabs.Root value={activeTabIndex.toString()}>
            <div className="flex justify-between items-center">
              <Tabs.List className="flex items-center border-light border-border-primary p-1 w-fit rounded-lg bg-white">
                <SettingsTab
                  title="Internal"
                  count={totalUserCount}
                  isSelected={activeTabIndex === 0}
                  onClick={() => handleSelectTab(0)}
                />
                <SettingsTab
                  title="External"
                  count={externalUserCount}
                  isSelected={activeTabIndex === 1}
                  onClick={() => handleSelectTab(1)}
                />
              </Tabs.List>
              <div className="rounded-lg py-2.5 px-3 border-light border-border-primary bg-white flex items-center gap-4 font-light min-w-60">
                <IconSearch className="w-4 h-4 fill-light-grey" />
                <input
                  className="flex-1 placeholder:text-light-grey text-black text-sm outline-none"
                  placeholder={`Search ${activeTabIndex === 0 ? 'Internal' : 'External'} Accounts`}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
            </div>
            <hr className="my-6" />
            <Tabs.Content value="0">
              <InternalUsers />
            </Tabs.Content>
            <Tabs.Content value="1">
              <ExternalUsers />
            </Tabs.Content>
          </Tabs.Root>
        </div>
      </div>
      {openSuggestedVendors ? (
        <SuggestedDomains
          open={openSuggestedVendors}
          suggestedDomains={
            suggestedVendorResults?.hits
              ? suggestedVendorResults.hits.map((d) => ({ ...d, checked: false }))
              : []
          }
          loading={isLoading}
          onClose={() => setOpenSuggestedVendors(false)}
          onFetchMore={() => handleFetchMore()}
          total={suggestedVendorResults?.total || 0}
          next={
            suggestedVendorResults?.total
              ? suggestedVendorResults.total > filters.limit + filters.offset
              : false
          }
        />
      ) : null}
    </>
  );
}
