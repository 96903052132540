import { useState } from 'react';

import * as Select from '@radix-ui/react-select';
import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';
import { RemediationCard } from 'components/Remediation/RemediationCard';
import { dateUtils } from 'utils/time';

export default function Remediation() {
  const timePeriodSelectOptions = [
    { value: '7', label: '7 days ago' },
    { value: '14', label: '14 days ago' },
    { value: '30', label: '30 days ago' },
    { value: '60', label: '60 days ago' },
  ];

  const [dateFilters, setDateFilters] = useState({
    from: dateUtils.subtract(new Date().toDateString(), 29, 'd').toDate().toISOString(),
    to: new Date().toISOString(),
  });

  const handleSelect = (value: string) => {
    const days = parseInt(value, 10);
    setDateFilters({
      from: dateUtils
        .subtract(new Date().toDateString(), days - 1, 'd')
        .toDate()
        .toISOString(),
      to: new Date().toISOString(),
    });
  };

  return (
    <div className="w-full px-8 py-5">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl">Remediated List</h2>
        <Select.Root defaultValue="30" onValueChange={handleSelect}>
          <Select.Trigger className="border-light border-border-primary p-2 rounded-md text-xs text-light-grey flex items-center gap-4 bg-white">
            <Select.Value />
            <Select.Icon>
              <IconArrowDown className="w-4 h-4" />
            </Select.Icon>
          </Select.Trigger>
          <Select.Portal>
            <Select.Content className="bg-white border border-gray-300 rounded-md shadow-lg text-xs text-light-grey p-2">
              <Select.Viewport>
                {timePeriodSelectOptions.map((option) => (
                  <Select.Item
                    key={option.value}
                    value={option.value}
                    className="p-2 cursor-pointer hover:bg-gray-100 text-light-grey data-[highlighted]:text-black data-[highlighted]:bg-select-background data-[highlighted]:outline-none rounded-sm"
                  >
                    <Select.ItemText>{option.label}</Select.ItemText>
                  </Select.Item>
                ))}
              </Select.Viewport>
            </Select.Content>
          </Select.Portal>
        </Select.Root>
      </div>
      <hr className="my-5" />
      <RemediationCard from={dateFilters.from} to={dateFilters.to} />
    </div>
  );
}
