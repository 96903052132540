import { Link, useLocation } from 'react-router-dom';

import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import { useAuth } from 'contexts/AuthContext';

import ravenLogo from 'assets/logo/logo.png';
import homeLogo from 'assets/icons/home.svg';
import homeLogoSelected from 'assets/icons/home-selected.svg';
import settings from 'assets/icons/settings.svg';
import settingsSelected from 'assets/icons/settings-selected.svg';
import users from 'assets/icons/users.svg';
import usersSelected from 'assets/icons/users-selected.svg';
import flags from 'assets/icons/flags.svg';
import flagsSelected from 'assets/icons/flags-selected.svg';

// import flags from 'assets/icons/flags.svg';
// import flagsSelected from 'assets/icons/flags-selected.svg';

function Sidebar() {
  // useLocation hook to get the current location of the page
  // this is used to determine which sidebar option is selected using the URL pathname
  const { pathname } = useLocation();

  const sidebarOptions = [
    {
      name: 'Home',
      urlPath: '/',
      selectedIcon: homeLogoSelected,
      icon: homeLogo,
    },
    {
      name: 'Mail Accounts',
      urlPath: '/users',
      selectedIcon: usersSelected,
      icon: users,
    },
    {
      name: 'Remediated List',
      urlPath: '/remediation',
      selectedIcon: flagsSelected,
      icon: flags,
    },
    {
      name: 'Settings',
      urlPath: '/settings',
      selectedIcon: settingsSelected,
      icon: settings,
    },
  ];

  const { mailadmin } = useAuth();

  return (
    <div className="p-6 bg-black h-full">
      <img src={ravenLogo} className="w-10 h-10" alt="ravenmail-logo" />
      <div className="flex flex-col gap-10 mt-10">
        {sidebarOptions
          .filter((option) => !(mailadmin && option.name === 'Settings'))
          .map((option) => {
            const isSelected = pathname === option.urlPath;

            return (
              <Tooltip key={option.name} text={option.name}>
                <Link to={option.urlPath}>
                  <Icon
                    key={option.name}
                    src={isSelected ? option.selectedIcon : option.icon}
                    bgColor={isSelected ? 'sidebar-background' : 'black'}
                    hoverBgColor="sidebar-background"
                    borderColor="black"
                  />
                </Link>
              </Tooltip>
            );
          })}
      </div>
    </div>
  );
}

export default Sidebar;
