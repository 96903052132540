import Avatar from 'components/Avatar';

interface IncidentItemProps {
  initials: string;
  subject: string;
  date: string;
}

export function IncidentItem({ initials, subject, date }: IncidentItemProps) {
  return (
    <div className="flex items-center gap-2">
      <Avatar
        initials={initials}
        bgColor="avatar-blue"
        textColor="white"
        customCssClass="w-7 h-7 text-2xs"
      />
      <div className="flex flex-col">
        <span className="text-sm">{subject}</span>
        <span className="text-xs text-light-grey">{date}</span>
      </div>
    </div>
  );
}
