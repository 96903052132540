import Avatar from 'components/Avatar';
import { getInitials } from 'utils/name';

interface ContactItemProps {
  name: string;
  email: string;
  avatarBgColor?: string;
}

export function ContactItem({ name, email, avatarBgColor = 'avatar-blue' }: ContactItemProps) {
  return (
    <div className="flex items-center gap-2">
      <Avatar
        initials={getInitials(name, 2)}
        bgColor={avatarBgColor}
        textColor="white"
        customCssClass="w-7 h-7 text-2xs"
      />
      <div className="flex flex-col">
        <span className="text-sm">{name}</span>
        <span className="text-xs text-light-grey">{email}</span>
      </div>
    </div>
  );
}
