import { VectorType } from 'enums/vectorTypes';

export const vectorToInference: Record<VectorType, string[]> = {
  SENDER: [
    'display_name_vip',
    'financial_request_intent',
    'suspicious_domain',
    'first_time_sender',
    'location_anamoly',
    'frequent_sender',
    'request_for_action',
    'sense_of_urgency_tone',
    'vendor_domain_lookalike',
    'regulator_domain_lookalike',
    'is_display_name_regulatory',
  ],
  RECIPIENT: ['is_recipient_finance_team', 'recurring_invoice'],
  URL: ['malicious_url'],
  ATTACHMENT: ['malicious_attachment'],
};

export const vectorTypeToDisplay: Record<string, string> = {
  SENDER: 'sender',
  RECIPIENT: 'recipients',
  URL: 'links',
  ATTACHMENT: 'attachments',
};
