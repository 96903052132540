interface MetricsProps {
  icon: string;
  name: string;
  value: number;
  hasRightBorder: boolean; // hasRightBorder is used to add a border to the right of the metrics, apart from the last one in the metric items should have a right border which will act like a divider
}

/*
Metrics is a component that renders a metric item with an icon, name, and value
It takes in an icon, name, value, and hasRightBorder prop

format of each object is {icon: string, name: string, value: number, hasRightBorder: boolean}

icon: used to display the icon of the metric
name: used to display the name of the metric
value: used to display the value of the metric in the format of a string with commas
hasRightBorder: used to add a border to the right of the metrics
*/
export function Metrics({ icon, name, value, hasRightBorder }: MetricsProps) {
  return (
    <div
      className={`flex justify-between items-center flex-1 py-3 px-6 ${hasRightBorder ? 'border-r-light' : ''}`}
    >
      <div className="flex gap-2 items-center">
        <img src={icon} className="w-3.5 h-3.5" alt="metric-icon" />
        <span className="text-light-grey text-sm">{name}</span>
      </div>
      <span className="text-lg text-black">{Intl.NumberFormat('en-US').format(value)}</span>
    </div>
  );
}
