import { useState, useEffect } from 'react';

import { ALL_ACCOUNTS, VENDORS, REGULATORS } from 'constants/tabName';

import * as Tabs from '@radix-ui/react-tabs';

import { Badge } from 'components/Badge';
import { ActionHeader } from 'components/Headers';

import { ReactComponent as IconVendorManagement } from 'assets/icons/vendor-management.svg';

import { ExternalUserFilter } from 'models/externalUsers';
import {
  useGetExternalUsersQuery,
  useGetRegulatorsQuery,
  useGetVendorsQuery,
} from 'service/externalUsers';
import { useOrgId } from 'hooks/useOrgId';
import { useQueryParam } from 'hooks/useQueryParam';

import { kFormatter } from 'utils/numberFormatter';

import { AllExternalAccountsTable } from './AllExternalAccountsTable';
import { AddExternalDomain } from './AddExternalDomain';
import { VendorsTable } from './VendorsTable';

export default function ExternalUsers(): JSX.Element {
  const [activeTab, setActiveTab] = useState(ALL_ACCOUNTS);

  const [searchQuery] = useQueryParam({ key: 'q' });

  const [externalUsersFilter, setExternalUsersFilter] = useState<ExternalUserFilter>({
    limit: 10,
    offset: 0,
    searchQuery: '',
    tags: [],
  });

  const tabs = [
    {
      id: ALL_ACCOUNTS,
      name: 'All Accounts',
    },
    {
      id: VENDORS,
      name: 'Vendors',
    },
    {
      id: REGULATORS,
      name: 'Regulatory Bodies',
    },
  ];

  useEffect(() => {
    setExternalUsersFilter((prevFilters) => ({
      ...prevFilters,
      searchQuery: searchQuery || '',
    }));
  }, [searchQuery]);

  const [OrgId] = useOrgId();

  const { data: externalUserSearchResults, isLoading } = useGetExternalUsersQuery({
    ...externalUsersFilter,
    orgId: OrgId,
  });

  const { data: vendorSearchResults, isLoading: vendorLoading } = useGetVendorsQuery({
    ...externalUsersFilter,
    orgId: OrgId,
  });

  const { data: regulatorSearchResults, isLoading: regulatorLoading } = useGetRegulatorsQuery({
    ...externalUsersFilter,
    orgId: OrgId,
  });

  const handleSetTab = (id: string) => {
    setActiveTab(id);
    setExternalUsersFilter((prevFilters) => ({
      ...prevFilters,
      offset: 0,
    }));
  };

  function getCountForTab(tab: string) {
    switch (tab) {
      case ALL_ACCOUNTS:
        return kFormatter(externalUserSearchResults?.total || 0);
      case VENDORS:
        return kFormatter(vendorSearchResults?.total || 0);
      case REGULATORS:
        return kFormatter(regulatorSearchResults?.total || 0);
      default:
        return '0';
    }
  }

  const [addAccountOpen, setAddAccountOpen] = useState(false);

  const handleAddAccountOpen = () => {
    setAddAccountOpen(true);
  };

  const handleAddAccountClose = () => {
    setAddAccountOpen(false);
  };

  return (
    <Tabs.Root value={activeTab}>
      <Tabs.List className="flex items-center gap-20 border-b border-border-primary">
        {tabs.map((tab) => (
          <Tabs.Trigger
            key={tab.id}
            value={tab.id}
            className="text-light-grey  data-[state=active]:text-black text-sm flex items-center justify-start"
            onClick={() => handleSetTab(tab.id)}
          >
            <div
              className={`flex items-center gap-2 py-3 px-1 ${activeTab === tab.id ? 'border-black border-b-2' : ''}`}
            >
              <span>{tab.name}</span>
              <Badge
                text={getCountForTab(tab.id)}
                bgColor={activeTab === tab.id ? 'black' : 'primary-background'}
                borderColor={activeTab === tab.id ? 'white' : 'light-grey'}
                textColor={activeTab === tab.id ? 'white' : 'light-grey'}
              />
            </div>
          </Tabs.Trigger>
        ))}
      </Tabs.List>
      <div className="my-8">
        <Tabs.Content
          id={ALL_ACCOUNTS}
          key={ALL_ACCOUNTS}
          value={ALL_ACCOUNTS}
          className="flex flex-col gap-4"
        >
          <ActionHeader
            title="Vendor & Regulatory Body Management"
            description="Add & assign Vendor and Regulatory roles to improve your organisation’s security posture"
            logoSvg={<IconVendorManagement />}
            actionDescription="Add Vendors/Regulators"
            actionComponent={<AddExternalDomain onClose={() => handleAddAccountClose()} />}
            actionComponentOpen={addAccountOpen}
            onActionClick={() => handleAddAccountOpen()}
            onClose={() => handleAddAccountClose()}
            width="w-1/4"
          />
          <AllExternalAccountsTable
            users={externalUserSearchResults?.hits || []}
            loading={isLoading}
            filters={externalUsersFilter}
            setFilters={setExternalUsersFilter}
            total={externalUserSearchResults?.total || 0}
          />
        </Tabs.Content>
        <Tabs.Content id={VENDORS} key={VENDORS} value={VENDORS} className="flex flex-col gap-4">
          <ActionHeader
            title="Vendor Management"
            description="Add & assign Vendor roles to improve your organisation’s security posture"
            logoSvg={<IconVendorManagement />}
            actionDescription="Add Vendors"
            actionComponent={
              <AddExternalDomain onClose={() => handleAddAccountClose()} defaultRole="vendor" />
            }
            actionComponentOpen={addAccountOpen}
            onActionClick={() => handleAddAccountOpen()}
            onClose={() => handleAddAccountClose()}
            width="w-1/4"
          />
          <VendorsTable
            vendors={vendorSearchResults?.hits || []}
            filters={externalUsersFilter}
            setFilters={setExternalUsersFilter}
            loading={vendorLoading}
            total={vendorSearchResults?.total || 0}
          />
        </Tabs.Content>
        <Tabs.Content
          id={REGULATORS}
          key={REGULATORS}
          value={REGULATORS}
          className="flex flex-col gap-4"
        >
          <ActionHeader
            title="Regulatory Body Management"
            description="Add & assign Regulatory roles to improve your organisation’s security posture"
            logoSvg={<IconVendorManagement />}
            actionDescription="Add Regulators"
            actionComponent={
              <AddExternalDomain onClose={() => handleAddAccountClose()} defaultRole="regulator" />
            }
            actionComponentOpen={addAccountOpen}
            onActionClick={() => handleAddAccountOpen()}
            onClose={() => handleAddAccountClose()}
            width="w-1/4"
          />
          <VendorsTable
            vendors={regulatorSearchResults?.hits || []}
            filters={externalUsersFilter}
            setFilters={setExternalUsersFilter}
            loading={regulatorLoading}
            total={regulatorSearchResults?.total || 0}
          />
        </Tabs.Content>
      </div>
    </Tabs.Root>
  );
}
