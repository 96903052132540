import { useState } from 'react';

import { Badge } from 'components/Badge';

import { Event } from 'models/events';

import { dateUtils } from 'utils/time';

import { ReactComponent as IconRemediation } from 'assets/icons/remediation.svg';
import { Alert } from 'components/Alert';

import { useToast } from 'hooks/toast';
import { useForceLogoutMutation } from 'service/users';
import { useRemediateMessageMutation } from 'service/eventsApi';
import { useOrgId } from 'hooks/useOrgId';
import { ActionToast } from 'components/Toasts/ActionToast';
import { getInitials } from 'utils/name';
import { WarningToast } from 'components/Toasts/WarningToast';
import { MOVE_TO_JUNK, QUARANTINE_MESSAGE } from 'constants/remediationActions';

import { EventStatusBadge } from './EventStatusBadge';
// import { EventActionMenu } from './EventActionMenu';
import { getInferences, getThreatDetailsByCode } from './data';
import { EventOverview } from './EventOverview';
import { EventAlert } from './EventAlert';
import { EventRemediation } from './EventRemediation';

export function renderRecepients(event: Event) {
  if (event?.recipients?.length) {
    if (event.recipients.length === 1) {
      return (
        <>
          <span className="text-xs break-words">{event.recipients[0].name}</span>
          <span className="text-xs text-light-grey break-words">{event.recipients[0].email}</span>
        </>
      );
    }

    return <span className="text-xs">{event.recipients?.length} Recipients</span>;
  }

  return <span className="text-xs text-light-grey"> No Recipients </span>;
}

export const generateActionDescription = (
  checkedStatus: Record<'logout' | 'quarantine', boolean>,
  actionResult: Record<'logout' | 'quarantine', boolean>
) => {
  if (checkedStatus.logout && checkedStatus.quarantine) {
    if (actionResult.logout && actionResult.quarantine) {
      return 'Email quarantined and recepient logged out successfully';
    }

    const toastDescription = 'Action partially completed.';
    if (actionResult.logout) {
      return `${toastDescription} Recepient logged out successfully and failed to quarantine email`;
    }

    if (actionResult.quarantine) {
      return `${toastDescription} Email quarantined successfully and failed to logout recepient`;
    }
  }

  if (checkedStatus.logout) {
    if (actionResult.logout) {
      return 'Recepient logged out successfully';
    }

    return 'Recepient logout failed';
  }

  if (checkedStatus.quarantine) {
    if (actionResult.quarantine) {
      return `Email Remediated successfully`;
    }

    return 'Email Remediation failed';
  }

  return 'Something went wrong';
};

export function EventRow({ event }: { event: Event }) {
  const [openOverview, setOpenOverview] = useState(false);

  const { showToast } = useToast();

  const [alertOpen, setAlertOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [forceLogoutUser] = useForceLogoutMutation();

  const [remediateMessage] = useRemediateMessageMutation();

  const [OrgId] = useOrgId();

  const handleRemediateClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (event.status !== 'Pending') {
      return;
    }
    setAlertOpen(true);
  };

  const [checkedStateOfActions, setCheckedStateOfActions] = useState({
    logout: false,
    quarantine: false,
  });

  const handleToggle = (action: 'logout' | 'quarantine') => {
    setCheckedStateOfActions((prevState) => ({
      ...prevState,
      [action]: !prevState[action],
    }));
  };

  const [remediationAction, setRemediationAction] = useState<'junk' | 'quarantine' | ''>('');

  const handleRemediateCheck = (remediateAction: 'junk' | 'quarantine') => {
    setRemediationAction(checkedStateOfActions.quarantine === true ? '' : remediateAction);
    handleToggle('quarantine');
  };

  const handleRemediateConfirm = async () => {
    if (!(checkedStateOfActions.logout || checkedStateOfActions.quarantine)) {
      showToast({
        component: <WarningToast message="Please select at least one action" />,
      });
      return;
    }

    setLoading(true);

    const actionResult = {
      logout: false,
      quarantine: false,
    };

    try {
      if (checkedStateOfActions.logout) {
        await forceLogoutUser({
          orgId: OrgId,
          employeeId: event.employeeId,
        }).unwrap();

        actionResult.logout = true;
      }

      if (checkedStateOfActions.quarantine) {
        await remediateMessage({
          orgId: OrgId,
          messageId: event.messageId,
          folder: remediationAction === 'junk' ? MOVE_TO_JUNK : QUARANTINE_MESSAGE,
        }).unwrap();

        actionResult.quarantine = true;
      }

      showToast({
        component: (
          <ActionToast
            description={generateActionDescription(checkedStateOfActions, actionResult)}
            showUndo={false}
            initials={getInitials(event.subject, 1)}
          />
        ),
      });
    } catch {
      showToast({
        component: (
          <ActionToast
            description={generateActionDescription(checkedStateOfActions, actionResult)}
            showUndo={false}
            initials={getInitials(event.subject, 1)}
          />
        ),
      });
    } finally {
      setAlertOpen(false);
      setLoading(false);
    }
  };

  return (
    <>
      <tr className="border-b align-top cursor-pointer" onClick={() => setOpenOverview(true)}>
        <td className="text-start px-6 py-3">
          <div className="flex flex-col gap-2">
            <div className="flex flex-col">
              <span className="text-xs break-words">{event.sender.name}</span>
              <span className="text-xs text-light-grey break-words">{event.sender.email}</span>
            </div>
            <EventAlert count={getInferences('sender', event).length} />
          </div>
        </td>
        <td className="text-start px-6 py-3">
          <div className="flex flex-col gap-2">
            <div className="flex flex-col">{renderRecepients(event)}</div>
            <EventAlert count={getInferences('recipients', event).length} />
          </div>
        </td>
        <td className="text-start px-6 py-3 text-black text-xs">{event.subject}</td>
        <td className="text-start px-6 py-3 text-black text-xs">
          <div className="flex flex-col gap-2">
            {event?.links?.length ? (
              <span className="break-words">
                {event?.links?.length > 1
                  ? `${event?.links?.length} Links`
                  : `${event.links[0].root}${event.links[0].path}`}
              </span>
            ) : (
              <span className="text-light-grey">No Links</span>
            )}
            <EventAlert count={getInferences('links', event).length} />
          </div>
        </td>
        <td className="text-start px-6 py-3 text-black text-xs">
          <div className="flex flex-col gap-2">
            {event?.attachments?.length ? (
              <span className="break-words">
                {event?.attachments?.length > 1
                  ? `${event?.attachments?.length} Attachments`
                  : `${event.attachments[0].name}`}
              </span>
            ) : (
              <span className="text-light-grey">No Attachments</span>
            )}
            <EventAlert count={getInferences('attachments', event).length} />
          </div>
        </td>
        <td className="text-start px-6 py-3 text-black text-xs flex flex-wrap gap-2">
          {event?.threats?.length ? (
            <>
              {event.threats.map((threat) => {
                const badgeDetails = getThreatDetailsByCode(threat.code);
                return (
                  <Badge
                    text={badgeDetails.text}
                    bgColor={badgeDetails.bgColor}
                    borderColor={badgeDetails.bgColor}
                    key={threat.code}
                    textColor={badgeDetails.textColor}
                    customCssClass="mono w-full"
                    borderRadius="rounded"
                  />
                );
              })}
            </>
          ) : (
            <span className="text-light-grey">No Threats</span>
          )}
        </td>
        <td className="text-start px-6 py-3 text-black text-xs">
          <div>{dateUtils.format(event?.time, 'DD/MM/YYYY')?.toString()}</div>
          <div className="text-light-grey">
            {dateUtils?.format(event.time, 'hh:mm A')?.toString()}
          </div>
        </td>
        <td className="text-start px-6 py-3 text-xs">
          <EventStatusBadge event={event} />
        </td>
        <td className="text-center px-6 py-3 text-black text-xs cursor-pointer">
          <button
            type="button"
            className={`bg-${event.status === 'Pending' ? 'sidebar-background' : 'success-green'} px-3 py-1.5 rounded-lg text-white`}
            onClick={handleRemediateClick}
          >
            {event.status === 'Pending' ? 'Remediate' : 'Remediated'}
          </button>
        </td>
      </tr>
      <EventOverview
        event={event}
        open={openOverview}
        onClose={() => setOpenOverview(false)}
        generateActionDescription={generateActionDescription}
      />
      <Alert
        open={alertOpen}
        cancelText="Cancel"
        confirmText="Confirm"
        content={
          <EventRemediation
            checkedStateOfActions={checkedStateOfActions}
            handleToggle={handleToggle}
            handleRemediateCheck={handleRemediateCheck}
            remediationAction={remediationAction}
          />
        }
        onConfirm={() => handleRemediateConfirm()}
        onCancel={() => setAlertOpen(false)}
        message="Are you sure you want to remediate this email? The following actions will be performed"
        icon={<IconRemediation />}
        loading={loading}
      />
    </>
  );
}
