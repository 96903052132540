import { createApi } from '@reduxjs/toolkit/query/react';

import { StatisticsResponse, StatisticsFilter } from 'models/statistics';
import { STATISTICS_URL } from 'constants/apiUrls';

import { baseQuery } from './baseQuery';

export const statisticsApi = createApi({
  reducerPath: 'statisticsApi',
  baseQuery: baseQuery,
  tagTypes: ['Statistics'],
  endpoints: (builder) => ({
    getStatistics: builder.query<StatisticsResponse, { orgId: string } & StatisticsFilter>({
      query: ({ orgId, from, to }) => {
        const params = new URLSearchParams();

        if (from) params.append('from', from);
        if (to) params.append('to', to);

        return `${STATISTICS_URL(orgId)}?${params.toString()}`;
      },
      providesTags: ['Statistics'],
    }),
  }),
});

export const { useGetStatisticsQuery } = statisticsApi;
