import { useSearchParams } from 'react-router-dom';

interface QueryParams {
  key: string;
  defaultValue?: string;
}

export function useQueryParam({
  key,
  defaultValue = '',
}: QueryParams): [string, (newValue: string) => void] {
  const [searchParams, setSearchParams] = useSearchParams();

  const paramValue = searchParams.get(key) ?? defaultValue;

  const setValue = (newValue: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (newValue === '') {
      newSearchParams.delete(key);
    } else {
      newSearchParams.set(key, newValue);
    }
    setSearchParams(newSearchParams);
  };

  return [paramValue, setValue];
}
