import * as Select from '@radix-ui/react-select';

import Card from 'components/Card';
import CardHeader from 'components/Card/CardHeader';
import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';

import { EventsResponse, GetEventsQueryParams } from 'models/events';
import { dateUtils } from 'utils/time';

import EventTable from './EventTable';
import { NoEvents } from './NoEvents';

interface AllEventsProps {
  title: string;
  filters: GetEventsQueryParams;
  setFilters: React.Dispatch<React.SetStateAction<GetEventsQueryParams>>;
  isLoading: boolean;
  data?: EventsResponse;
}

export default function AllEvents({
  title,
  filters,
  setFilters,
  isLoading,
  data = {
    hits: [],
    total: 0,
    limit: 10,
    offset: 0,
  },
}: AllEventsProps) {
  // timePeriodSelectOptions is an array of the options in the select dropdown for selecting the time period
  const timePeriodSelectOptions = [
    { value: '7', label: '7 days ago' },
    { value: '14', label: '14 days ago' },
    { value: '30', label: '30 days ago' },
    { value: '60', label: '60 days ago' },
  ];

  // handleSelect is used to update the filters when the time period is changed
  // it subtracts the time period from the current date and updates the filters
  const handleSelect = (value: string) => {
    const days = parseInt(value, 10);
    setFilters({
      ...filters,
      from: dateUtils.subtract(new Date().toDateString(), days, 'd').toDate().toISOString(),
      to: new Date().toISOString(),
    });
  };

  return (
    <div className="mt-4 w-full">
      <Card width="100%">
        <CardHeader>
          <div className="flex items-center justify-between">
            <span> {title} </span>
            <div className="flex items-center gap-8">
              {/* commenting out the search input */}
              {/* <div className="border-light border-border-primary p-2 flex items-center gap-2 rounded-md">
                <IconMagnifyingGlass className="w-3 h-3" />
                <input
                  type="text"
                  placeholder="Search"
                  className="w-full bg-transparent outline-none text-xs text-light-grey placeholder:font-light placeholder:text-light-grey"
                />
              </div> */}
              <Select.Root defaultValue="30" onValueChange={handleSelect}>
                <Select.Trigger className="border-light border-border-primary p-2 rounded-md text-xs text-light-grey flex items-center gap-4">
                  <Select.Value />
                  <Select.Icon>
                    <IconArrowDown className="w-4 h-4" />
                  </Select.Icon>
                </Select.Trigger>
                <Select.Portal>
                  <Select.Content className="bg-white border border-gray-300 rounded-md shadow-lg text-xs text-light-grey p-2">
                    <Select.Viewport>
                      {timePeriodSelectOptions.map((option) => (
                        <Select.Item
                          key={option.value}
                          value={option.value}
                          className="p-2 cursor-pointer hover:bg-gray-100 text-light-grey data-[highlighted]:text-black data-[highlighted]:bg-select-background data-[highlighted]:outline-none rounded-sm"
                        >
                          <Select.ItemText>{option.label}</Select.ItemText>
                        </Select.Item>
                      ))}
                    </Select.Viewport>
                  </Select.Content>
                </Select.Portal>
              </Select.Root>
            </div>
          </div>
        </CardHeader>
        <div className="w-full relative">
          {isLoading && (
            <div className="inset-0 absolute bg-black opacity-50 rounded-lg z-20 cursor-not-allowed" />
          )}
          {data?.hits?.length > 0 ? (
            <EventTable
              events={data.hits}
              onSort={(sortType) => {
                setFilters({
                  ...filters,
                  sortOrder: sortType,
                });
              }}
              initialSortType={filters.sortOrder === 'asc' ? 0 : 1}
            />
          ) : (
            <NoEvents />
          )}
        </div>
        {data?.hits?.length > 0 && !isLoading && (
          <div className="flex justify-between px-6 py-4 items-center">
            <span className="text-xs font-light">
              Showing {data && data?.hits?.length > 0 ? data.offset + 1 : '0'} -{' '}
              {data && data?.hits.length > 0 ? data.offset + data.hits.length : '0'} of{' '}
              {data?.total} events
            </span>
            <div className="flex rounded-full items-center border border-pagination-border cursor-pointer">
              <IconArrowDown
                className={`w-6 h-6 rotate-90 border-t-light border-t-pagination-border ${data && data?.offset > 0 ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
                onClick={() => {
                  if (data) {
                    if (data.offset > 0) {
                      setFilters({
                        ...filters,
                        offset: data.offset - data.limit,
                      });
                    }
                  }
                }}
              />
              <IconArrowDown
                className={`w-6 h-6 -rotate-90 ${data && data.offset + data.limit < data.total ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
                onClick={() => {
                  if (data && data.offset + data.limit < data.total) {
                    setFilters({
                      ...filters,
                      offset: data.offset + data.limit,
                    });
                  }
                }}
              />
            </div>
          </div>
        )}
      </Card>
    </div>
  );
}
