import { useEffect, useState } from 'react';

import Card from 'components/Card';
import CardHeader from 'components/Card/CardHeader';
// import { ReactComponent as IconMagnifyingGlass } from 'assets/icons/magnifying-glass.svg';
import { RemediatedEventsFilter } from 'models/events';

import { useGetRemediatedEventsQuery } from 'service/eventsApi';
import { useOrgId } from 'hooks/useOrgId';

import { RemediationTable } from './RemediationTable';

interface RemediationCardProps {
  from: string;
  to: string;
}

export function RemediationCard({ from, to }: RemediationCardProps) {
  const [filters, setFilters] = useState<RemediatedEventsFilter>({
    limit: 10,
    offset: 0,
    searchQuery: '',
    from: from || '',
    to: to || '',
    sortBy: 'action_at',
    sortOrder: 'desc',
  });

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      from,
      to,
    }));
  }, [from, to]);

  const [OrgId] = useOrgId();

  const { data: remediatedEvents, isLoading } = useGetRemediatedEventsQuery({
    orgId: OrgId,
    ...filters,
  });

  return (
    <Card width="100%">
      <CardHeader padding="py-5 px-6">
        <div className="flex items-center justify-between">
          <span> Remediated Events </span>
          <div className="flex items-center gap-8">
            {/* <div className="border-light border-border-primary p-2 flex items-center gap-2 rounded-md">
              <IconMagnifyingGlass className="w-3 h-3" />
              <input
                type="text"
                placeholder="Search"
                className="w-full bg-transparent outline-none text-2xs text-black placeholder:font-light placeholder:text-light-grey"
              />
            </div> */}
          </div>
        </div>
      </CardHeader>
      <RemediationTable
        loading={isLoading}
        data={remediatedEvents?.hits || []}
        total={remediatedEvents?.total || 0}
        filters={filters}
        setFilters={setFilters}
      />
    </Card>
  );
}
