import { useState } from 'react';
import ActionMenu from 'components/ActionMenu';
import { Badge } from 'components/Badge';
import { VIPName } from 'components/Settings/VIPName';
import { User } from 'models/user';
import { ReactComponent as IconMenu } from 'assets/icons/icon-action-menu.svg';
import {
  useAddVIPUsersMutation,
  useRemoveVIPUsersMutation,
  useAddFinanceAndAccUsersMutation,
  useRemoveFinanceAndAccUsersMutation,
  useForceLogoutMutation,
} from 'service/users';
import { useOrgId } from 'hooks/useOrgId';
import { useToast } from 'hooks/toast';
import { ActionToast } from 'components/Settings/AddToast';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { Alert } from 'components/Alert';
import { SuccessToast } from 'components/Toasts/SuccessToast';
import { SidePanel } from 'components/Sidepanel';

import { UserAlertDetails } from './UserAlertDetails';
import { UserProfileOverview } from './UserProfileOverview';

interface UserRowProps {
  user: User;
}

export function UserRow({ user }: UserRowProps) {
  const [alertOpen, setAlertOpen] = useState(false);

  const { showToast } = useToast();

  const [OrgId] = useOrgId();

  const [addToVIP] = useAddVIPUsersMutation();

  const [removeFromVIP] = useRemoveVIPUsersMutation();

  const [alertMessage, setAlertMessage] = useState('');

  const [action, setAction] = useState<'force-logout' | 'vip' | 'finance-accounting'>('vip');

  const handleRemoveFromVIPList = async () => {
    try {
      await removeFromVIP({
        orgId: OrgId,
        userIds: [user.id],
      });

      showToast({
        component: <ActionToast users={[user]} showUndo={false} action="remove" roleType="VIP" />,
      });
    } catch {
      showToast({
        component: <ErrorToast message="Failed to remove user from VIP list" />,
      });
    } finally {
      setAlertOpen(false);
    }
  };
  const handleAddToVIPList = async () => {
    try {
      await addToVIP({
        orgId: OrgId,
        userIds: [user.id],
      });

      showToast({
        component: (
          <ActionToast
            users={[user]}
            roleType="VIP"
            onUndo={() => {
              handleRemoveFromVIPList();
            }}
          />
        ),
      });
    } catch {
      showToast({
        component: <ErrorToast message="Failed to add user to VIP list" />,
      });
    } finally {
      setAlertOpen(false);
    }
  };

  const [addToFinanceAndAccounting] = useAddFinanceAndAccUsersMutation();

  const [removeFromFinanceAndAccounting] = useRemoveFinanceAndAccUsersMutation();

  const handleRemoveFromFinanceAndAccountingList = async () => {
    try {
      await removeFromFinanceAndAccounting({
        orgId: OrgId,
        userIds: [user.id],
      });

      showToast({
        component: (
          <ActionToast
            users={[user]}
            showUndo={false}
            action="remove"
            roleType="Finance & Accounting"
          />
        ),
      });
    } catch {
      showToast({
        component: <ErrorToast message="Failed to remove user from Finance and Accounting list" />,
      });
    } finally {
      setAlertOpen(false);
    }
  };

  const handleAddToFinanceAndAccountingList = async () => {
    try {
      await addToFinanceAndAccounting({
        orgId: OrgId,
        userIds: [user.id],
      });

      showToast({
        component: (
          <ActionToast
            users={[user]}
            roleType="Finance & Accounting"
            onUndo={() => {
              handleRemoveFromFinanceAndAccountingList();
            }}
          />
        ),
      });
    } catch {
      showToast({
        component: <ErrorToast message="Failed to add user to Finance and Accounting list" />,
      });
    } finally {
      setAlertOpen(false);
    }
  };

  const [forceLogout] = useForceLogoutMutation();

  const handleForceLogout = async () => {
    try {
      await forceLogout({
        orgId: OrgId,
        employeeId: user.id,
      });

      showToast({
        component: <SuccessToast message="User logged out successfully" />,
      });
    } catch {
      showToast({
        component: <ErrorToast message="Failed to force logout user" />,
      });
    } finally {
      setAlertOpen(false);
    }
  };

  const userActions = [
    {
      title: 'Force password change',
      onClick: async () => {
        setAlertOpen(() => {
          setAction('force-logout');
          setAlertMessage('Are you sure you want to force logout this user?');
          return true;
        });
      },
    },
    {
      title: user.vip ? 'Remove From VIP List' : 'Add To VIP List',
      onClick: () => {
        setAlertOpen(() => {
          setAction('vip');
          setAlertMessage(
            `Are you sure you want to ${user.vip ? 'remove' : 'add'} ${user.name}(${user.email}) ${user.vip ? 'from' : 'to'} the VIP list?`
          );
          return true;
        });
      },
    },
    {
      title: user.financeAndAccounting
        ? 'Remove From Finance and Accounting List'
        : 'Add To Finance and Accounting List',
      onClick: () => {
        setAlertOpen(() => {
          setAction('finance-accounting');
          setAlertMessage(
            `Are you sure you want to ${user.financeAndAccounting ? 'remove' : 'add'} ${user.name}(${user.email}) ${user.financeAndAccounting ? 'from' : 'to'} Finance and Accounting list?`
          );
          return true;
        });
      },
    },
  ];

  const [panelOpen, setPanelOpen] = useState(false);

  const handleClose = () => {
    setPanelOpen(false);
  };

  const handleSelectUser = () => {
    setPanelOpen(true);
  };

  const handleConfirm = () => {
    if (action === 'force-logout') {
      handleForceLogout();
    } else if (action === 'vip') {
      if (user.vip) {
        handleRemoveFromVIPList();
      } else {
        handleAddToVIPList();
      }
    } else if (action === 'finance-accounting') {
      if (user.financeAndAccounting) {
        handleRemoveFromFinanceAndAccountingList();
      } else {
        handleAddToFinanceAndAccountingList();
      }
    }
  };

  const getTags = () => {
    const tags = [];

    if (user.vip) {
      tags.push(
        <Badge
          text="VIP USER"
          bgColor="soft-blue"
          borderColor="soft-blue"
          textColor="avatar-blue"
          customCssClass="mono"
          borderRadius="rounded"
        />
      );
    }

    if (user.financeAndAccounting) {
      tags.push(
        <Badge
          text="FINANCE & ACCOUNTING"
          bgColor="soft-red"
          borderColor="soft-red"
          textColor="critical-red"
          customCssClass="mono"
          borderRadius="rounded"
        />
      );
    }

    return tags;
  };

  return (
    <>
      <tr
        className="border-b-light border-border-primary align-middle border-spacing-x-2 cursor-pointer"
        onClick={() => handleSelectUser()}
      >
        <td className="text-start px-6 py-3 text-xs">
          <VIPName name={user.name} isVIP={user.vip} />
        </td>
        <td className="text-start px-6 py-3 text-xs">
          <span>{user.email}</span>
        </td>
        <td className="text-start px-6 py-3 text-xs">{getTags()}</td>
        <td className="px-6 py-3 flex flex-row-reverse">
          <ActionMenu icon={<IconMenu />} actions={userActions} />
        </td>
      </tr>
      <Alert
        open={alertOpen}
        onCancel={() => setAlertOpen(false)}
        onConfirm={() => handleConfirm()}
        message={alertMessage}
        content={<UserAlertDetails name={user.name} email={user.email} />}
      />
      <SidePanel open={panelOpen} onClose={handleClose}>
        <UserProfileOverview user={user} />
      </SidePanel>
    </>
  );
}
