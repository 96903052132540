interface BadgeProps {
  text: string;
  bgColor?: string;
  borderColor?: string;
  textColor?: string;
  borderRadius?: string;
  customCssClass?: string;
}

export function Badge({
  text,
  bgColor,
  borderColor,
  textColor,
  borderRadius = 'rounded-full',
  customCssClass = '',
}: BadgeProps) {
  return (
    <span
      className={`text-center uppercase ${borderRadius} border border-${borderColor} text-${textColor} bg-${bgColor} px-2 py-0.5 text-xs ${customCssClass}`}
    >
      {text}
    </span>
  );
}
