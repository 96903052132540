import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'models/user';

interface UserState {
  count: number;
  externalCount: number;
  selectedUser: User;
  loading: boolean;
}

const initialState: UserState = {
  count: 0,
  externalCount: 0,
  selectedUser: {
    id: '',
    name: '',
    email: '',
    admin: false,
    vip: false,
    financeAndAccounting: false,
  },
  loading: true,
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    addSelectedUser: (state, action: PayloadAction<User>) => {
      // this is to avoid changing selected user if getUsers is called again
      // because that updates the selected user
      return {
        ...state,
        selectedUser: action.payload,
      };
    },
    updateCount: (state, action: PayloadAction<number>) => {
      // updating only the initial count, because we are using the state to show
      // the total count of users, which is initially fetch
      return {
        ...state,
        count: action.payload,
      };
    },
    updateExternalCount: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        externalCount: action.payload,
      };
    },
    setUserLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
  },
});

export const { addSelectedUser, updateCount, setUserLoading, updateExternalCount } =
  userSlice.actions;
export default userSlice.reducer;
