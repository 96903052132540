import { createApi } from '@reduxjs/toolkit/query/react';
import { EmailVectorResponse, VectorFrequencyFilters } from 'models/emailVector';
import { EMAIL_VECTOR_FREQUENCY_URL } from 'constants/apiUrls';

import { baseQuery } from './baseQuery';

export const emailVectorFrequencyApi = createApi({
  reducerPath: 'emailVectorFrequencyApi',
  baseQuery: baseQuery,
  tagTypes: ['EmailVectorFrequency'],
  endpoints: (builder) => ({
    getEmailVectorFrequency: builder.query<EmailVectorResponse, VectorFrequencyFilters>({
      query: ({ orgId, from, to, vector, frequency }) => {
        const params = new URLSearchParams();

        if (from) params.append('from', from);
        if (to) params.append('to', to);
        if (frequency) params.append('frequency', frequency);
        if (vector) params.append('vector', vector);

        return `${EMAIL_VECTOR_FREQUENCY_URL(orgId)}?${params.toString()}`;
      },
      providesTags: ['EmailVectorFrequency'],
    }),
  }),
});

export const { useGetEmailVectorFrequencyQuery } = emailVectorFrequencyApi;
