export interface VectorFrequencyFilters {
  orgId: string;
  from?: string;
  to?: string;
  frequency?: string;
  vector?: string;
}

export interface Frequency {
  date: string;
  count: number;
}

export interface FrequencyVector {
  code: string;
  frequency: Frequency[];
}

export interface EmailVectorResponse {
  vectors: FrequencyVector[];
}

export enum VectorType {
  DEFAULT = '',
  SENDER = 'SENDER',
  RECIPIENT = 'RECIPIENT',
  URL = 'URL',
  ATTACHMENT = 'ATTACHMENT',
}
