export const ALL_EMAILS_TAB = 'all-emails';
export const SENDER_EMAIL_TAB = 'sender-email';
export const RECEIVER_EMAIL_TAB = 'receiver-email';
export const ATTACHMENT_EMAIL_TAB = 'attachment-email';
export const LINK_EMAIL_TAB = 'link-email';

// tabs common across mail accounts page
export const ALL_ACCOUNTS = 'all-accounts';

// tabs for internal users
export const VIP_USERS = 'vip-users';
export const GROUP_USERS = 'group-users';

// tabs for external users
export const VENDORS = 'vendors';
export const REGULATORS = 'regulators';

// tabs for threats section in overview page
export const IMPERSONATION = 'impersonation';
export const BEC = 'bec';
export const MALWARE = 'malware';
