import { ReactComponent as IconNoEvents } from 'assets/icons/icon-no-events.svg';

export function NoEvents(): JSX.Element {
  return (
    <div className="px-6 py-3 flex items-center my-8 gap-4">
      <IconNoEvents className="w-6 h-6" />
      <span className="text-sm text-light-grey">
        {' '}
        No events yet. Please reload the page to update
      </span>
    </div>
  );
}
