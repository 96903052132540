import { useState } from 'react';

import { Badge } from 'components/Badge';
import { IdentityTile } from 'components/Tile/IdentityTile';
import { ExternalUser } from 'models/externalUsers';
import { ReactComponent as IconMenu } from 'assets/icons/icon-action-menu.svg';
import { ReactComponent as IconVendor } from 'assets/icons/vendor.svg';
import { ReactComponent as IconRegulator } from 'assets/icons/regulator.svg';

import ActionMenu from 'components/ActionMenu';
import { Alert } from 'components/Alert';
import { formatEmailName, getDomainFromEmail } from 'utils/email';

import { useAssignDomainMutation, useRemoveDomainMutation } from 'service/externalUsers';
import { useToast } from 'hooks/toast';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { useOrgId } from 'hooks/useOrgId';
import { ActionToast } from 'components/Toasts/ActionToast';
import { getInitials } from 'utils/name';

import { UserAlertDetails } from './UserAlertDetails';
import { ExternalUserOverview } from './ExternalUserOverview';

interface ExternalUserRowProps {
  user: ExternalUser;
}

export function ExternalUserRow({ user }: ExternalUserRowProps): JSX.Element {
  const { showToast, setOpen: setToastOpen } = useToast();

  const [alert, setAlert] = useState({
    open: false,
    message: '',
    action: '',
  });

  const userAction = [
    {
      icon: <IconVendor className="w-3 h-3 fill-inherit" />,
      title: `${user.isVendor ? 'Unmark' : 'Mark'} domain as Vendor`,
      onClick: async () => {
        setAlert({
          open: true,
          message: `Are you sure you want to ${user.isVendor ? 'unmark' : 'mark'}  this domain as a Vendor?`,
          action: 'vendor',
        });
      },
    },
    {
      icon: <IconRegulator className="w-3 h-3 fill-inherit" />,
      title: `${user.isRegulator ? 'Unmark' : 'Mark'} domain as Regulator`,
      onClick: async () => {
        setAlert({
          open: true,
          message: `Are you sure you want to ${user.isRegulator ? 'unmark' : 'mark'}  this domain as a Regulator?`,
          action: 'regulator',
        });
      },
    },
  ];

  const getTags = () => {
    const tags = [];

    if (user.isRegulator) {
      tags.push(
        <Badge
          text="Regulator"
          bgColor="soft-orange"
          borderColor="soft-orange"
          textColor="warning-yellow"
          customCssClass="mono"
          borderRadius="rounded"
        />
      );
    }

    if (user.isVendor) {
      tags.push(
        <Badge
          text="Vendor"
          bgColor="soft-orange"
          borderColor="soft-orange"
          textColor="warning-yellow"
          customCssClass="mono"
          borderRadius="rounded"
        />
      );
    }

    return tags;
  };

  const handleCancel = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const [assignDomain] = useAssignDomainMutation();

  const [removeDomain] = useRemoveDomainMutation();

  const [OrgId] = useOrgId();

  const handleUndoMark = async () => {
    try {
      await removeDomain({
        orgId: OrgId,
        domainId: user.domainId,
        group: alert.action === 'vendor' ? 'vendor' : 'regulator',
      });

      setToastOpen(false);
    } catch {
      showToast({
        component: <ErrorToast message="Something went wrong! Please try again." />,
      });
    }
  };

  const handleUndoUnmark = async () => {
    try {
      await assignDomain({
        orgId: OrgId,
        domainId: user.domainId,
        group: alert.action === 'vendor' ? 'vendor' : 'regulator',
      });

      setToastOpen(false);
    } catch {
      showToast({
        component: <ErrorToast message="Something went wrong! Please try again." />,
      });
    }
  };

  const handleUndo = (action: string) => {
    if (action === 'mark') {
      handleUndoMark();
    } else {
      handleUndoUnmark();
    }
  };

  const handleVendorAction = async () => {
    try {
      if (!user.isVendor) {
        await assignDomain({
          orgId: OrgId,
          domainId: user.domainId,
          group: 'vendor',
        });
      } else {
        await removeDomain({
          orgId: OrgId,
          domainId: user.domainId,
          group: 'vendor',
        });
      }

      showToast({
        component: (
          <ActionToast
            initials={getInitials(getDomainFromEmail(user.email), 1)}
            description={`${getDomainFromEmail(user.email)} ${user.isVendor ? 'unmarked' : 'marked'} as Vendor successfully`}
            avatarBgColor="warning-yellow"
            showUndo
            onUndo={() => handleUndo(user.isVendor ? 'unmark' : 'mark')}
          />
        ),
      });

      setAlert({
        ...alert,
        open: false,
      });
    } catch {
      showToast({
        component: <ErrorToast message="Something went wrong! Please try again." />,
      });
    }
  };

  const handleRegulatorAction = async () => {
    try {
      if (!user.isRegulator) {
        await assignDomain({
          orgId: OrgId,
          domainId: user.domainId,
          group: 'regulator',
        });
      } else {
        await removeDomain({
          orgId: OrgId,
          domainId: user.domainId,
          group: 'regulator',
        });
      }

      showToast({
        component: (
          <ActionToast
            initials={getInitials(getDomainFromEmail(user.email), 1)}
            description={`${getDomainFromEmail(user.email)} ${user.isRegulator ? 'unmarked' : 'marked'} as Regulator successfully`}
            avatarBgColor="warning-yellow"
            showUndo
            onUndo={() => handleUndo(user.isRegulator ? 'unmark' : 'mark')}
          />
        ),
      });

      setAlert({
        ...alert,
        open: false,
      });
    } catch {
      showToast({
        component: <ErrorToast message="Something went wrong! Please try again." />,
      });
    }
  };

  const handleConfirm = () => {
    if (alert.action === 'vendor') {
      handleVendorAction();
    } else {
      handleRegulatorAction();
    }
  };

  const [panelOpen, setPanelOpen] = useState(false);

  const handlePanelClose = () => {
    setPanelOpen(false);
  };

  const handleClickRow = () => {
    setPanelOpen(true);
  };

  return (
    <>
      <tr
        className="border-b-light border-border-primary align-middle border-spacing-x-2 cursor-pointer"
        onClick={() => handleClickRow()}
      >
        <td className="text-start px-6 py-3 text-xs">
          <IdentityTile
            name={user.name !== '' ? user.name : formatEmailName(user.email)}
            bgColor="warning-yellow"
          />
        </td>
        <td className="text-start px-6 py-3 text-xs">{user.email}</td>
        <td className="text-start px-6 py-3 text-xs">
          <div className="flex gap-2 flex-wrap">{getTags()}</div>
        </td>
        <td className="px-6 py-3 flex flex-row-reverse">
          <ActionMenu icon={<IconMenu />} actions={userAction} />
        </td>
      </tr>
      <Alert
        open={alert.open}
        cancelText="Cancel"
        confirmText="Confirm"
        message={alert.message}
        onCancel={() => handleCancel()}
        onConfirm={() => handleConfirm()}
        content={
          <UserAlertDetails
            name={getDomainFromEmail(user.email)}
            email={user.email}
            avatarBgColor="warning-yellow"
          />
        }
      />
      <ExternalUserOverview onClose={handlePanelClose} open={panelOpen} user={user} />
    </>
  );
}
