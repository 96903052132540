import { createApi } from '@reduxjs/toolkit/query/react';

import { VectorsFilter, VectorsResponse } from 'models/vectors';
import { VECTORS_URL } from 'constants/apiUrls';

import { baseQuery } from './baseQuery';

export const vectorsApi = createApi({
  reducerPath: 'vectorsApi',
  baseQuery: baseQuery,
  tagTypes: ['vectors'],
  endpoints: (builder) => ({
    getVectors: builder.query<VectorsResponse, { orgId: string } & VectorsFilter>({
      query: ({ orgId, from, to }) => {
        const params = new URLSearchParams();

        if (from) params.append('from', from);
        if (to) params.append('to', to);

        return `${VECTORS_URL(orgId)}?${params.toString()}`;
      },
      providesTags: ['vectors'],
    }),
  }),
});

export const { useGetVectorsQuery } = vectorsApi;
