import Avatar from 'components/Avatar';
import { getInitials } from 'utils/name';

interface UserAlertDetails {
  name: string;
  email: string;
  avatarBgColor?: string;
}

export function UserAlertDetails({ name, email, avatarBgColor = 'avatar-blue' }: UserAlertDetails) {
  return (
    <div className="flex flex-col gap-2 w-full h-full items-center p-8 bg-select-background rounded-lg">
      <Avatar
        bgColor={avatarBgColor}
        textColor="white"
        initials={getInitials(name !== '' ? name : email, 2)}
      />
      <div className="flex flex-col gap-1 items-center">
        <span className="text-sm">{name || '-'}</span>
        <span className="text-xs text-light-grey">{email || '-'}</span>
      </div>
    </div>
  );
}
