import { useEffect, useState } from 'react';

export const useRealm = () => {
  const [realm, setRealm] = useState<string>(() => {
    return localStorage.getItem('realm') || '';
  });

  // Update localStorage whenever realm changes
  useEffect(() => {
    localStorage.setItem('realm', realm);
  }, [realm]);

  return [realm, setRealm] as const;
};
