import { createApi } from '@reduxjs/toolkit/query/react';

import { ThreatInferencesFilter, ThreatInferencesResponse } from 'models/threatInferences';
import { THREATS_INFERENCES_URL } from 'constants/apiUrls';

import { baseQuery } from './baseQuery';

export const threatInferencesApi = createApi({
  reducerPath: 'threatInferencesApi',
  baseQuery: baseQuery,
  tagTypes: ['threat-inferences'],
  endpoints: (builder) => ({
    getThreatInferences: builder.query<
      ThreatInferencesResponse,
      { orgId: string } & ThreatInferencesFilter
    >({
      query: ({ orgId, from, to, limit, sort, order, threat }) => {
        const params = new URLSearchParams();

        if (from) params.append('from', from);
        if (to) params.append('to', to);
        if (limit) params.append('limit', limit.toString());
        if (sort) params.append('sort', sort);
        if (order) params.append('order', order);
        if (threat) params.append('threats', threat);

        return `${THREATS_INFERENCES_URL(orgId)}?${params.toString()}`;
      },
      providesTags: ['threat-inferences'],
      transformResponse: (response: ThreatInferencesResponse) => {
        const total = response?.hits?.reduce((acc, hit) => {
          return acc + hit.count;
        }, 0);

        response.total = total;

        return response;
      },
    }),
  }),
});

export const { useGetThreatInferencesQuery } = threatInferencesApi;
