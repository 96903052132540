import { useState } from 'react';

import * as Tabs from '@radix-ui/react-tabs';

import { SettingsTab } from 'components/Settings/SettingsTab';
// import { SettingsHeader } from 'components/Settings/SettingsHeader';
import { ReactComponent as IconUserAdmin } from 'assets/icons/user-admin.svg';

import { AdminTable } from 'components/Settings/AdminTable';
import { GetUsersFilter } from 'models/user';
import { useOrgId } from 'hooks/useOrgId';
import { useGetAdminsQuery } from 'service/users';
import { useAuth } from 'contexts/AuthContext';
import { ActionHeader } from 'components/Headers';
import { AddAdminForm } from 'components/Settings/AddAdminForm';

export default function Settings() {
  const { superadmin } = useAuth();

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleSelectTab = (tabIdx: number) => {
    setActiveTabIndex(tabIdx);
  };

  const [tableFilters, setTableFilters] = useState<GetUsersFilter>({
    limit: 10,
    offset: 0,
    searchQuery: '',
  });

  const [OrgId] = useOrgId();

  const { data: adminData, isLoading: adminLoading } = useGetAdminsQuery({
    orgId: OrgId,
    ...tableFilters,
  });

  const [actionHeaderOpen, setActionHeaderOpen] = useState(false);

  const handleOpenActionHeader = () => {
    setActionHeaderOpen(true);
  };

  const handleCloseActionHeader = () => {
    setActionHeaderOpen(false);
  };

  return (
    <div className="w-full px-8 py-5">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl">Settings</h2>
      </div>
      <hr className="my-5" />
      <Tabs.Root value={activeTabIndex.toString()}>
        <Tabs.List className="flex items-center border-light border-border-primary p-1 w-fit rounded-lg bg-white">
          {superadmin && (
            <Tabs.Trigger value="0">
              <SettingsTab
                title="Administrators"
                count={adminData?.total || 0}
                isSelected={activeTabIndex === 0}
                onClick={() => handleSelectTab(0)}
              />
            </Tabs.Trigger>
          )}
        </Tabs.List>
        <div className="mt-8">
          {superadmin ? (
            <Tabs.Content value="0">
              <ActionHeader
                title="Admin Management"
                description="Assign administrative roles by adding a user."
                logoSvg={<IconUserAdmin className="w-4 h-4" />}
                logoBg="bg-mauve"
                width="w-1/3"
                actionComponent={<AddAdminForm onClose={() => handleCloseActionHeader()} />}
                actionComponentOpen={actionHeaderOpen}
                actionDescription="Add Admin"
                onClose={() => handleCloseActionHeader()}
                onActionClick={() => handleOpenActionHeader()}
              />
              <div className="mt-8">
                <AdminTable
                  admins={adminData?.hits || []}
                  isLoading={adminLoading}
                  total={adminData?.total || 0}
                  filters={tableFilters}
                  setFilters={setTableFilters}
                />
              </div>
            </Tabs.Content>
          ) : null}
        </div>
      </Tabs.Root>
    </div>
  );
}
