import { ReactComponent as IconStar } from 'assets/icons/star.svg';

import Avatar from 'components/Avatar';
import Loader from 'components/Loader';
import { ErrorToast } from 'components/Toasts/ErrorToast';

import { useGetProfileOverviewQuery } from 'service/profileOverviewApi';
import { useForceLogoutMutation } from 'service/users';

import { useOrgId } from 'hooks/useOrgId';
import { useToast } from 'hooks/toast';

import { getInitials } from 'utils/name';
import { User } from 'models/user';

import { UserDetails } from './UserDetails';
// import { UserContactDetails } from './UserContactDetails';
import { IncidentsList } from './PreviousIncidents';

type UserProfileOverviewProps = {
  user: User;
};

export function UserProfileOverview({ user }: UserProfileOverviewProps): JSX.Element {
  const { showToast } = useToast();

  const [OrgId] = useOrgId();

  const { data, isLoading } = useGetProfileOverviewQuery(
    { orgId: OrgId, userId: user?.id },
    {
      skip: !user?.id,
    }
  );

  const [forceLogout] = useForceLogoutMutation();

  const handleForceLogout = async () => {
    try {
      await forceLogout({
        orgId: OrgId,
        employeeId: user?.id,
      });
    } catch {
      showToast({
        component: <ErrorToast message="Failed to force logout user" />,
      });
    }
  };

  return (
    <div className="w-full h-full bg-white">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="p-10">
            <div className="flex items-center gap-2">
              <Avatar
                initials={getInitials(user?.name !== '' ? user?.name : user.email, 2)}
                bgColor="avatar-blue"
                textColor="white"
                customCssClass="w-8 h-8 text-xs"
              />
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <span className="text-base">{user.name}</span>
                  {user.vip && <IconStar className="w-3 h-3" />}
                </div>
                <span className="text-xs text-light-grey">{user.email}</span>
              </div>
            </div>
          </div>
          <hr className="mb-4" />
          <div className="px-10">
            <UserDetails
              email={data?.email || '-'}
              phone={data?.mobile || '-'}
              officeLocation={data?.officeLocation || '-'}
              designation={data?.designation || '-'}
            />
            <hr className="my-6" />
            {/* <UserContactDetails /> */}
            <IncidentsList title="Previous Incidents" incidents={data?.latestIncidents || []} />
          </div>
          <div className="p-10 mt-auto flex flex-col-reverse">
            <button
              type="button"
              className="w-full rounded-full text-base text-white bg-black py-4 text-center"
              onClick={() => handleForceLogout()}
            >
              Force password change
            </button>
          </div>
        </>
      )}
    </div>
  );
}
