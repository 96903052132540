import { EmailMetadata } from 'models/profileOverview';

import { dateUtils } from 'utils/time';
import { getInitials } from 'utils/name';

import { IncidentItem } from './IncidentItem';

interface IncidentsListProps {
  title: string;
  incidents: EmailMetadata[];
  showHighlight?: boolean;
}

export function IncidentsList({ title, incidents, showHighlight = true }: IncidentsListProps) {
  return (
    <div>
      <div className="border-b border-border-primary mt-8">
        <div
          className={`px-1 py-3 ${showHighlight ? 'border-black border-b-2' : ''} w-fit text-sm`}
        >
          {title}
        </div>
      </div>
      <div className="mt-6">
        {incidents.length > 0 ? (
          <div className="flex flex-col gap-4">
            {incidents?.map((incident) => (
              <IncidentItem
                key={incident.date}
                initials={getInitials(incident.sender.email, 2)}
                subject={incident.subject}
                date={dateUtils.format(incident.date, 'h:mm A | DD MMM YYYY').toString()}
              />
            ))}
          </div>
        ) : (
          <span className="text-sm text-light-grey">No incidents found</span>
        )}
      </div>
    </div>
  );
}
