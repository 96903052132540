import { RouterProvider } from 'react-router-dom';

import 'styles/globals.css';
import appRoutes from 'routes/AppRoutes';
import { ToastProvider } from 'components/Toasts';

function App() {
  return (
    <ToastProvider>
      <RouterProvider router={appRoutes} />
    </ToastProvider>
  );
}

export default App;
