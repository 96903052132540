import { createApi } from '@reduxjs/toolkit/query/react';

import { TopDetectionsFilter, TopDetectionsResponse } from 'models/topDetections';
import { TOP_DETECTIONS_URL } from 'constants/apiUrls';

import { baseQuery } from './baseQuery';

export const topDetectionsApi = createApi({
  reducerPath: 'topDetectionsApi',
  baseQuery: baseQuery,
  tagTypes: ['top-detections'],
  endpoints: (builder) => ({
    getTopDetections: builder.query<TopDetectionsResponse, { orgId: string } & TopDetectionsFilter>(
      {
        query: ({ orgId, from, to, limit, sort, order, vector }) => {
          const params = new URLSearchParams();

          if (from) params.append('from', from);
          if (to) params.append('to', to);
          if (limit) params.append('limit', limit.toString());
          if (sort) params.append('sort', sort);
          if (order) params.append('order', order);
          if (vector) params.append('vector', vector);

          return `${TOP_DETECTIONS_URL(orgId)}?${params.toString()}`;
        },
        providesTags: ['top-detections'],
        transformResponse: (response: TopDetectionsResponse) => {
          // sum the count in the hit and add it as a total in the top detections response
          const total = response.hits?.reduce((acc, hit) => {
            return acc + hit.count;
          }, 0);

          response.total = total;

          return response;
        },
      }
    ),
  }),
});

export const { useGetTopDetectionsQuery } = topDetectionsApi;
