import { useState } from 'react';

import { Badge } from 'components/Badge';
import { IdentityTile } from 'components/Tile/IdentityTile';
import { ReactComponent as IconMenu } from 'assets/icons/icon-action-menu.svg';
import { ReactComponent as IconVendor } from 'assets/icons/vendor.svg';
import { ReactComponent as IconRegulator } from 'assets/icons/regulator.svg';

import ActionMenu from 'components/ActionMenu';
import { Alert } from 'components/Alert';

import { Domain } from 'models/domains';
import { splitURL } from 'utils/url';
import { useAssignDomainMutation, useRemoveDomainMutation } from 'service/externalUsers';
import { useToast } from 'hooks/toast';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { useOrgId } from 'hooks/useOrgId';
import { ActionToast } from 'components/Toasts/ActionToast';
import { getInitials } from 'utils/name';

import { UserAlertDetails } from './UserAlertDetails';
import { DomainOverview } from './DomainOverview';

interface VendorRowProps {
  vendor: Domain;
}

export function VendorRow({ vendor }: VendorRowProps): JSX.Element {
  const { showToast, setOpen: setToastOpen } = useToast();

  const [alert, setAlert] = useState({
    open: false,
    message: '',
    action: '',
  });

  const vendorAction = [
    {
      icon: <IconVendor className="w-3 h-3 fill-inherit" />,
      title: `${vendor.isVendor ? 'Unmark' : 'Mark'} domain as Vendor`,
      onClick: async () => {
        setAlert({
          open: true,
          message: `Are you sure you want to ${vendor.isVendor ? 'unmark' : 'mark'}  this domain as a Vendor?`,
          action: 'vendor',
        });
      },
    },
    {
      icon: <IconRegulator className="w-3 h-3 fill-inherit" />,
      title: `${vendor.isRegulator ? 'Unmark' : 'Mark'} domain as Regulator`,
      onClick: async () => {
        setAlert({
          open: true,
          message: `Are you sure you want to ${vendor.isRegulator ? 'unmark' : 'mark'}  this domain as a Regulator?`,
          action: 'regulator',
        });
      },
    },
  ];

  const handleCancel = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const [assignDomain] = useAssignDomainMutation();

  const [removeDomain] = useRemoveDomainMutation();

  const [OrgId] = useOrgId();

  const handleUndoMark = async () => {
    try {
      await removeDomain({
        orgId: OrgId,
        domainId: vendor.id,
        group: alert.action === 'vendor' ? 'vendor' : 'regulator',
      });

      setToastOpen(false);
    } catch {
      showToast({
        component: <ErrorToast message="Something went wrong! Please try again." />,
      });
    }
  };

  const handleUndoUnmark = async () => {
    try {
      await assignDomain({
        orgId: OrgId,
        domainId: vendor.id,
        group: alert.action === 'vendor' ? 'vendor' : 'regulator',
      });

      setToastOpen(false);
    } catch {
      showToast({
        component: <ErrorToast message="Something went wrong! Please try again." />,
      });
    }
  };

  const handleUndo = (action: string) => {
    if (action === 'mark') {
      handleUndoMark();
    } else {
      handleUndoUnmark();
    }
  };

  const handleVendorAction = async () => {
    try {
      if (!vendor.isVendor) {
        await assignDomain({
          orgId: OrgId,
          domainId: vendor.id,
          group: 'vendor',
        });
      } else {
        await removeDomain({
          orgId: OrgId,
          domainId: vendor.id,
          group: 'vendor',
        });
      }

      showToast({
        component: (
          <ActionToast
            initials={getInitials(vendor.name, 1)}
            description={`${vendor.name} ${vendor.isVendor ? 'unmarked' : 'marked'} as Vendor successfully`}
            avatarBgColor="warning-yellow"
            showUndo
            onUndo={() => handleUndo(vendor.isVendor ? 'unmark' : 'mark')}
          />
        ),
      });

      setAlert({
        ...alert,
        open: false,
      });
    } catch {
      showToast({
        component: <ErrorToast message="Something went wrong! Please try again." />,
      });
    }
  };

  const handleRegulatorAction = async () => {
    try {
      if (!vendor.isRegulator) {
        await assignDomain({
          orgId: OrgId,
          domainId: vendor.id,
          group: 'regulator',
        });
      } else {
        await removeDomain({
          orgId: OrgId,
          domainId: vendor.id,
          group: 'regulator',
        });
      }

      showToast({
        component: (
          <ActionToast
            initials={getInitials(vendor.name, 1)}
            description={`${vendor.name} ${vendor.isRegulator ? 'unmarked' : 'marked'} as Regulator successfully`}
            avatarBgColor="warning-yellow"
            showUndo
            onUndo={() => handleUndo(vendor.isVendor ? 'unmark' : 'mark')}
          />
        ),
      });

      setAlert({
        ...alert,
        open: false,
      });
    } catch {
      showToast({
        component: <ErrorToast message="Something went wrong! Please try again." />,
      });
    }
  };

  const getTags = () => {
    const tags = [];

    if (vendor.isRegulator) {
      tags.push(
        <Badge
          text="Regulator"
          bgColor="soft-orange"
          borderColor="soft-orange"
          textColor="warning-yellow"
          customCssClass="mono"
          borderRadius="rounded"
        />
      );
    }

    if (vendor.isVendor) {
      tags.push(
        <Badge
          text="Vendor"
          bgColor="soft-orange"
          borderColor="soft-orange"
          textColor="warning-yellow"
          customCssClass="mono"
          borderRadius="rounded"
        />
      );
    }

    return tags;
  };

  const handleConfirm = () => {
    if (alert.action === 'vendor') {
      handleVendorAction();
    } else {
      handleRegulatorAction();
    }
  };
  const vendorName = splitURL(vendor.name);

  const [panelOpen, setPanelOpen] = useState(false);

  const handleClose = () => {
    setPanelOpen(false);
  };

  const handleSelectUser = () => {
    setPanelOpen(true);
  };

  return (
    <>
      <tr
        className="border-b-light border-border-primary align-middle border-spacing-x-2 cursor-pointer"
        onClick={handleSelectUser}
      >
        <td className="text-start px-6 py-3 text-xs">
          <IdentityTile name={vendorName} bgColor="warning-yellow" />
        </td>
        <td className="text-start px-6 py-3 text-xs">{vendor.name}</td>
        <td className="text-start px-6 py-3 text-xs">
          <div className="flex gap-2 flex-wrap">{getTags()}</div>
        </td>
        <td className="px-6 py-3 flex flex-row-reverse">
          <ActionMenu icon={<IconMenu />} actions={vendorAction} />
        </td>
      </tr>
      <Alert
        open={alert.open}
        cancelText="Cancel"
        confirmText="Confirm"
        message={alert.message}
        onCancel={() => handleCancel()}
        onConfirm={() => handleConfirm()}
        content={
          <UserAlertDetails name={vendorName} email={vendor.name} avatarBgColor="warning-yellow" />
        }
      />
      <DomainOverview open={panelOpen} onClose={handleClose} domain={vendor} name={vendorName} />
    </>
  );
}
