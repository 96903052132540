import { createContext, useState, ReactNode, useMemo } from 'react';
import * as ToastPrimitive from '@radix-ui/react-toast';

interface ToastContextProps {
  showToast: (content: ToastContent) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ToastContent {
  component: ReactNode;
}

export const ToastContext = createContext<ToastContextProps | undefined>(undefined);

export function ToastProvider({ children }: { children: ReactNode }) {
  const [open, setOpen] = useState(false);
  const [toastContent, setToastContent] = useState<ToastContent>({ component: null });

  // Function to show toast with dynamic content
  const showToast = useMemo(() => {
    return (content: ToastContent) => {
      setToastContent(content);
      setOpen(true);

      setTimeout(() => {
        setOpen(false);
      }, 3000);
    };
  }, [setToastContent, setOpen]);

  return (
    // eslint-disable-next-line  react/jsx-no-constructed-context-values
    <ToastContext.Provider value={{ showToast, setOpen }}>
      {children}
      <ToastPrimitive.Provider swipeDirection="up">
        <ToastPrimitive.Root
          open={open}
          onOpenChange={setOpen}
          className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50 w-1/2 data-[state=open]:animate-slideIn data-[state=closed]:animate-hide flex justify-center items-center"
        >
          {toastContent.component}
        </ToastPrimitive.Root>
        <ToastPrimitive.Viewport />
      </ToastPrimitive.Provider>
    </ToastContext.Provider>
  );
}
