import { useState } from 'react';

import { Badge } from 'components/Badge';
import { getInferences, getThreatDetailsByCode } from 'components/Events/data';
import { EventAlert } from 'components/Events/EventAlert';
import { renderRecepients } from 'components/Events/EventRow';
import { Alert } from 'components/Alert';

import { ReactComponent as IconUndoRemediation } from 'assets/icons/undo-remediation.svg';

import { useUndoRemediationMutation } from 'service/eventsApi';
import { useOrgId } from 'hooks/useOrgId';
import { useToast } from 'hooks/toast';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { ActionToast } from 'components/Toasts/ActionToast';
import { getInitials } from 'utils/name';
import { useAuth } from 'contexts/AuthContext';

import { Event } from 'models/events';
import { dateUtils } from 'utils/time';
import { UNDO_MOVE_TO_JUNK, UNDO_QUARANTINE_MESSAGE } from 'constants/remediationActions';

import { RemediateOverview } from './RemediateOverview';
import { UndoRemediationAlert } from './UndoRemediation';
import { remediationActionDetails } from './data';

interface RemediationRowProps {
  event: Event;
  quarantinedBy: string;
  quarantinedDate: string;
  action: string;
}

export function RemediationRow({
  event,
  quarantinedBy,
  quarantinedDate,
  action,
}: RemediationRowProps) {
  const { showToast } = useToast();

  const { email } = useAuth();

  const [openOverview, setOpenOverview] = useState(false);
  const [loading, setLoading] = useState(false);

  const [alertOpen, setAlertOpen] = useState(false);

  const handleAlertOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setAlertOpen(true);
  };

  const [undoRemediation] = useUndoRemediationMutation();

  const [OrgId] = useOrgId();

  const handleConfirm = async () => {
    // avoid multiple api calls
    if (loading) return;

    setLoading(true);

    try {
      await undoRemediation({
        orgId: OrgId,
        messageId: event.messageId,
        action: action === 'MOVE_TO_JUNK' ? UNDO_MOVE_TO_JUNK : UNDO_QUARANTINE_MESSAGE,
      }).unwrap();

      showToast({
        component: (
          <ActionToast
            description="Moved message to 'Inbox'"
            showUndo={false}
            initials={getInitials(email, 1)}
          />
        ),
      });
    } catch {
      showToast({
        component: (
          <ErrorToast message='Failed to move message to "Inbox", please try again later' />
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <tr className="border-b align-top cursor-pointer" onClick={() => setOpenOverview(true)}>
        <td className="text-start px-6 py-3 text-black text-xs break-words">{event.subject}</td>
        <td className="text-start px-4 py-3">
          <div className="flex flex-col gap-2">
            <div className="flex flex-col">
              <span className="text-xs break-words">{event.sender.name}</span>
              <span className="text-xs text-light-grey break-words">{event.sender.email}</span>
            </div>
            <EventAlert count={getInferences('sender', event).length} />
          </div>
        </td>
        <td className="text-start px-4 py-3">
          <div className="flex flex-col gap-2">
            <div className="flex flex-col">{renderRecepients(event)}</div>
            <EventAlert count={getInferences('recepient', event).length} />
          </div>
        </td>
        <td className="text-start px-4 py-3 text-black text-xs flex flex-wrap gap-2">
          {event?.threats?.length ? (
            <>
              {event.threats.map((threat) => {
                const badgeDetails = getThreatDetailsByCode(threat.code);
                return (
                  <Badge
                    text={badgeDetails.text}
                    bgColor={badgeDetails.bgColor}
                    borderColor={badgeDetails.bgColor}
                    key={threat.code}
                    textColor={badgeDetails.textColor}
                    customCssClass="mono w-full"
                    borderRadius="rounded"
                  />
                );
              })}
            </>
          ) : (
            <span className="text-light-grey">No Threats</span>
          )}
        </td>
        <td className="text-start px-4 py-3 text-black text-xs break-words">{quarantinedBy}</td>
        <td className="text-start px-4 py-3 text-black text-xs">
          <div>{dateUtils.format(event?.time, 'DD/MM/YYYY')?.toString()}</div>
          <div className="text-light-grey">
            {dateUtils?.format(event.time, 'hh:mm A')?.toString()}
          </div>
        </td>
        <td className="text-start px-4 py-3 text-black text-xs">
          <div>{dateUtils.format(quarantinedDate, 'DD/MM/YYYY')?.toString()}</div>
          <div className="text-light-grey">
            {dateUtils?.format(quarantinedDate, 'hh:mm A')?.toString()}
          </div>
        </td>
        <td className="text-start px-4 py-3 text-black text-xs flex flex-wrap gap-2">
          <Badge
            key={action}
            text={remediationActionDetails[action].text}
            bgColor="soft-green"
            borderColor="soft-green"
            textColor="dark-green"
            customCssClass="mono w-full"
            borderRadius="rounded"
          />
        </td>
        <td className="text-start px-4 py-3 text-xs">
          <button
            type="button"
            className={`bg-${event.status === 'Remediated' ? 'sidebar-background' : 'success-green'} px-3 py-1.5 rounded-lg text-white`}
            onClick={handleAlertOpen}
          >
            {event.status === 'Remediated' ? 'Undo Remediation' : 'Reverted'}
          </button>
        </td>
      </tr>
      <RemediateOverview
        event={event}
        open={openOverview}
        onClose={() => setOpenOverview(false)}
        action={action}
      />
      <Alert
        open={alertOpen}
        cancelText="Cancel"
        confirmText="Confirm"
        content={<UndoRemediationAlert />}
        onConfirm={() => handleConfirm()}
        onCancel={() => setAlertOpen(false)}
        message="Are you sure you want to undo the remediation actions taken for this email? The following actions will be performed"
        icon={<IconUndoRemediation />}
        loading={loading}
      />
    </>
  );
}
