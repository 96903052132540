import { MOVE_TO_JUNK, QUARANTINE_MESSAGE, UNDO_MOVE_TO_JUNK } from 'constants/remediationActions';
import { FolderType } from 'enums/eventStatusTypes';

export const getActionDetails = (folderType: FolderType) => {
  switch (folderType) {
    case MOVE_TO_JUNK:
      return 'Moves the email to the junk folder';
    case UNDO_MOVE_TO_JUNK:
      return 'Restore email from junk to inbox';
    case QUARANTINE_MESSAGE:
      return 'Isolate suspicious email for security review';
    // folder type can only have 4 types if they are not the above
    // 3 cases then it will be UNDO_QUARANTINE_MESSAGE
    default:
      return 'Release email from quarantine to the inbox';
  }
};
