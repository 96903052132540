interface GridTileProps {
  details: {
    title: string;
    value: string;
  }[];
}

export default function GridTile({ details }: GridTileProps) {
  return (
    <div className="grid grid-cols-2 gap-2">
      {details.map((eachDetail) => (
        <div className="flex flex-col gap-1">
          <span className="text-xs text-light-grey">{eachDetail.title}</span>
          <span className="text-sm break-words w-full">{eachDetail.value}</span>
        </div>
      ))}
    </div>
  );
}
