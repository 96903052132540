import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';

// isFetchBaseQueryError checks if error is an instance of FetchBaseQueryError
function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

// isSerializedError checks if error is an instance of SerializedError
function isSerializedError(error: unknown): error is SerializedError {
  return typeof error === 'object' && error != null && 'name' in error && 'message' in error;
}

// getErrorMessage is a utility function to get the error message from an error object of unknown type
export function getErrorMessage(error: unknown) {
  let errorMessage = 'Something went wrong!';

  if (isFetchBaseQueryError(error)) {
    errorMessage = 'error' in error ? error.error : (error.data as string);
  } else if (isSerializedError(error)) {
    errorMessage = error.message || errorMessage;
  }

  return errorMessage;
}
