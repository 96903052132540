interface UserDetailsProps {
  email: string;
  phone: string;
  officeLocation: string;
  designation: string;
}

export function UserDetails({ email, phone, officeLocation, designation }: UserDetailsProps) {
  return (
    <div className="grid grid-cols-2 gap-2">
      <div className="flex flex-col gap-1">
        <span className="text-xs text-light-grey">Email ID</span>
        <span className="text-sm break-words w-full">{email}</span>
      </div>
      <div className="flex flex-col gap-1">
        <span className="text-xs text-light-grey">Phone Number</span>
        <span className="text-sm break-words">{phone}</span>
      </div>
      <div className="flex flex-col gap-1">
        <span className="text-xs text-light-grey">Office Location</span>
        <span className="text-sm break-words">{officeLocation}</span>
      </div>
      <div className="flex flex-col gap-1">
        <span className="text-xs text-light-grey">Designation</span>
        <span className="text-sm break-words">{designation}</span>
      </div>
    </div>
  );
}
