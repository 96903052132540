import Avatar from 'components/Avatar';
import { getInitials } from 'utils/name';
import { ReactComponent as IconStar } from 'assets/icons/star.svg';

interface VIPNameProps {
  name: string;
  isVIP: boolean;
}

export function VIPName({ name, isVIP }: VIPNameProps): JSX.Element {
  return (
    <div className="flex items-center gap-2">
      <Avatar
        initials={getInitials(name, 2)}
        bgColor="avatar-blue"
        textColor="white"
        customCssClass="w-6 h-6 p-1 text-[0.5rem]"
      />
      <span className="text-xs">{name}</span>
      {isVIP && <IconStar className="w-3 h-3" />}
    </div>
  );
}
