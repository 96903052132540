import * as Select from '@radix-ui/react-select';

import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';
import LegendMarker from 'components/LegendMarker';
import {
  CartesianGrid,
  Line,
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { dateUtils } from 'utils/time';

import { useOrgId } from 'hooks/useOrgId';
import { VectorFrequencyFilters, VectorType } from 'models/emailVector';
import { useState } from 'react';
import { useGetEmailVectorFrequencyQuery } from 'service/emailVectorFrequency';
import { FrequencyGraphToolTip } from 'components/FrequencyGraph/FrequencyGraphToolTip';
import { getFrequencyDataByVector, transformToTimeseries } from './data';

export function EmailVectorsFrequency() {
  // timePeriodSelectOptions is an array of the options in the select dropdown for selecting the time period
  const timePeriodSelectOptions = [
    { value: '7', label: '7 days ago' },
    { value: '14', label: '14 days ago' },
    { value: '30', label: '30 days ago' },
    { value: '60', label: '60 days ago' },
  ];

  const [OrgId] = useOrgId();

  const [filters, setFilters] = useState<VectorFrequencyFilters>({
    orgId: OrgId,
    from: dateUtils.subtract(new Date().toDateString(), 29, 'd').toDate().toISOString(),
    to: new Date().toISOString(),
    frequency: 'daily',
  });

  const { data } = useGetEmailVectorFrequencyQuery(filters, {
    refetchOnMountOrArgChange: true,
  });

  // handleSelect is used to update the filters when the time period is changed
  // it subtracts the time period from the current date and updates the filters
  const handleSelect = (value: string) => {
    const days = parseInt(value, 10);
    setFilters({
      ...filters,
      from: dateUtils
        .subtract(new Date().toDateString(), days - 1, 'd')
        .toDate()
        .toISOString(),
      to: new Date().toISOString(),
    });
  };

  const getTransformedData = () => {
    const senderData = getFrequencyDataByVector(VectorType.SENDER, data);
    const recipientData = getFrequencyDataByVector(VectorType.RECIPIENT, data);
    const attachmentData = getFrequencyDataByVector(VectorType.ATTACHMENT, data);
    const linkData = getFrequencyDataByVector(VectorType.URL, data);

    const graphData = [];

    for (let i = 0; i < senderData.length; i += 1) {
      graphData.push({
        date: senderData[i].date,
        count: senderData[i]?.count,
        recipientCount: recipientData[i]?.count,
        attachmentCount: attachmentData[i]?.count,
        linkCount: linkData[i]?.count,
      });
    }

    return transformToTimeseries(filters.from || '', filters.to || '', data ? graphData : []);
  };

  const graphLabels = [
    {
      label: 'Sender',
      value: 'count',
    },
    {
      label: 'Recipient',
      value: 'recipientCount',
    },
    {
      label: 'URL',
      value: 'linkCount',
    },
    {
      label: 'Attachment',
      value: 'attachmentCount',
    },
  ];

  return (
    <div className="flex-1">
      <div className="bg-white rounded-lg border-light border-primary-border w-full">
        <div className="py-3.5 px-8 border-b-light border-border-primary">
          <div className="flex items-center justify-between text-xs">
            <span>Email Vector Frequency</span>
            <Select.Root defaultValue="30" onValueChange={handleSelect}>
              <Select.Trigger className="border-light border-border-primary p-2 rounded-md text-xs text-light-grey flex items-center gap-4">
                <Select.Value />
                <Select.Icon>
                  <IconArrowDown className="w-4 h-4" />
                </Select.Icon>
              </Select.Trigger>
              <Select.Portal>
                <Select.Content className="bg-white border border-gray-300 rounded-md shadow-lg text-xs text-light-grey p-2">
                  <Select.Viewport>
                    {timePeriodSelectOptions.map((option) => (
                      <Select.Item
                        key={option.value}
                        value={option.value}
                        className="p-2 cursor-pointer hover:bg-gray-100 text-light-grey data-[highlighted]:text-black data-[highlighted]:bg-select-background data-[highlighted]:outline-none rounded-sm"
                      >
                        <Select.ItemText>{option.label}</Select.ItemText>
                      </Select.Item>
                    ))}
                  </Select.Viewport>
                </Select.Content>
              </Select.Portal>
            </Select.Root>
          </div>
        </div>
        <div className="flex items-center w-full gap-8 px-8 py-4 border-b-light border-primary-border">
          <LegendMarker markerColor="light-blue" text="Sender" />
          <LegendMarker markerColor="light-green" text="Receiver" />
          <LegendMarker markerColor="light-pink" text="URL" />
          <LegendMarker markerColor="mauve" text="Attachment" />
        </div>
        <div className="w-full bg-white py-3.5 px-8 rounded-b-lg">
          <ResponsiveContainer width="100%" height={240}>
            <LineChart
              data={getTransformedData()}
              accessibilityLayer
              margin={{
                left: 20,
                right: 20,
              }}
            >
              <XAxis
                dataKey="date"
                className="text-xs"
                color="#E1E5E8"
                axisLine={false}
                tickLine={false}
                tick={{
                  fill: '#9FAFB5',
                  fontSize: '10px',
                }}
                tickMargin={12}
                tickFormatter={(value) => {
                  const dateText = value as string;
                  return dateUtils.format(dateText, 'MMM DD').toString();
                }}
                scale="point"
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                orientation="right"
                tick={{
                  fill: '#9FAFB5',
                  fontSize: '10px',
                }}
                tickMargin={16}
                tickCount={3}
                domain={[0, (dataMax: number) => Math.ceil(dataMax * 1.1)]} // Add 10% to the max value to make sure the max value is visible
              />
              <CartesianGrid
                vertical={false}
                color="#E1E5E8"
                strokeWidth={0.5}
                strokeOpacity={0.5}
                horizontalCoordinatesGenerator={({ height }) => {
                  const count = 10; // Number of horizontal lines you want
                  return Array(count)
                    .fill(0)
                    .map((_, index) => height * (index / count));
                }}
              />
              <Tooltip content={<FrequencyGraphToolTip labels={graphLabels} />} cursor={false} />
              <Line dataKey="count" stroke="#85D8FF" dot={false} strokeWidth={1} />
              <Line dataKey="recipientCount" stroke="#2CD77A" dot={false} strokeWidth={1} />
              <Line dataKey="linkCount" stroke="#FFB6C1" dot={false} strokeWidth={1} />
              <Line dataKey="attachmentCount" stroke="#B7597B" dot={false} strokeWidth={1} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}
