import React from 'react';

// CardHeader component is a wrapper component for all the card headers in the application
// it takes in a children prop which is the content of the card header
// it applies the styling required for the card header which makes it uniform
export default function CardHeader({
  children,
  padding = 'py-5 px-8',
}: {
  children: React.ReactNode;
  padding?: string;
}) {
  return <div className={`${padding} border-b-light border-border-primary w-full`}>{children}</div>;
}
