import { useEffect, useState } from 'react';

import Card from 'components/Card';
import { CardContent } from 'components/Card/CardContent';
import CardHeader from 'components/Card/CardHeader';

import { ReactComponent as IconSearch } from 'assets/icons/magnifying-glass.svg';
// import { useGetUsersQuery } from 'service/users';
import { useAddVIPUsersMutation, useRemoveVIPUsersMutation, useGetUsersQuery } from 'service/users';
import { useToast } from 'hooks/toast';
import { ActionToast } from 'components/Settings/AddToast';
import { useOrgId } from 'hooks/useOrgId';

import { UserListItem, AddUser } from './UserListItem';

interface AddVIPFormProps {
  onClose: () => void;
}

export function AddVIPForm({ onClose }: AddVIPFormProps): JSX.Element {
  const { showToast, setOpen: setToastOpen } = useToast();

  const [allUsers, setAllUsers] = useState<AddUser[]>([]);

  const [addedUsers, setAddedUsers] = useState<AddUser[]>([]);

  // search query is used to maintain the state of the input
  // while the user is typing
  const [searchQuery, setSearchQuery] = useState('');

  // debouned query is used to maintain the state for sending debounded
  // the query to the backend
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');

  useEffect(() => {
    // Set a timeout to update the debouncedSearchQuery after the specified delay
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);

    // Clear the timeout if searchQuery changes before the delay period elapses
    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const [OrgId] = useOrgId();

  const { data } = useGetUsersQuery(
    {
      limit: 5,
      offset: 0,
      searchQuery: debouncedSearchQuery,
      orgId: OrgId,
    },
    {
      skip: debouncedSearchQuery === '',
      refetchOnMountOrArgChange: true,
    }
  );

  const handleAdd = (id: string) => {
    const addedUser = allUsers.find((user) => user.id === id);
    if (addedUser) {
      setAddedUsers([...addedUsers, addedUser]);
    }
  };

  useEffect(() => {
    if (data) {
      setAllUsers(
        data.hits
          .filter((hit) => !addedUsers.some((addedUser) => addedUser.id === hit.id))
          .map((user) => ({ ...user, added: false }))
      );
    }
  }, [data, addedUsers]);

  const handleRemove = (id: string) => {
    setAddedUsers(addedUsers.filter((user) => user.id !== id));
  };

  const [addVIPUsers] = useAddVIPUsersMutation();

  const [removeVIPUsers] = useRemoveVIPUsersMutation();

  const handleRemoveUsers = async (userIds: string[]) => {
    try {
      await removeVIPUsers({
        orgId: OrgId,
        userIds: userIds,
      });
    } catch (error) {
      // TODO: handle errors
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const handleSave = async () => {
    try {
      const addedUserIds = addedUsers.map((user) => user.id);
      await addVIPUsers({
        orgId: OrgId,
        userIds: addedUserIds,
      });

      showToast({
        component: (
          <ActionToast
            users={addedUsers}
            roleType="VIP"
            onUndo={() => {
              handleRemoveUsers(addedUserIds);
              setToastOpen(false);
            }}
          />
        ),
      });
    } catch (error) {
      // TODO: handle errors
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      onClose();
    }
  };

  return (
    <Card width="100%">
      <CardHeader>
        <span>Add VIP User</span>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-6">
          <div className="border-light border-border-primary p-2 flex items-center gap-2 rounded-md w-full">
            <IconSearch className="w-3 h-3 fill-light-grey" />
            <input
              type="text"
              placeholder="Search"
              className="w-full bg-transparent outline-none text-xs placeholder:font-light placeholder:text-light-grey"
              onChange={handleSearch}
            />
          </div>
          <div className="flex flex-col gap-6">
            {addedUsers.map((user) => (
              <UserListItem
                key={user.id}
                name={user.name}
                vip={user.vip}
                financeAndAccounting={user.financeAndAccounting}
                id={user.id}
                email={user.email}
                added
                onAdd={() => handleRemove(user.id)}
              />
            ))}
            {allUsers
              .filter((user) => !user.vip)
              .map((user) => (
                <UserListItem
                  key={user.id}
                  name={user.name}
                  vip={user.vip}
                  financeAndAccounting={user.financeAndAccounting}
                  id={user.id}
                  email={user.email}
                  added={user.added}
                  onAdd={() => handleAdd(user.id)}
                />
              ))}
          </div>
        </div>
      </CardContent>
      <div className="flex items-center justify-center gap-4 py-5 px-8 border-t-light border-t-border-primary">
        <button
          type="button"
          className="flex-1 rounded-full border-black/20 border-light text-black/20 px-8 py-2 text-xs hover:text-black hover:border-black"
          onClick={() => onClose()}
        >
          Cancel
        </button>
        <button
          type="button"
          className={`flex-1 ${addedUsers.length === 0 ? 'bg-black/20' : 'bg-black'} px-8 py-2 rounded-full text-white text-xs`}
          onClick={() => handleSave()}
        >
          Save
        </button>
      </div>
    </Card>
  );
}
