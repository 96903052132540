import { vectorTypeToDisplay } from 'constants/vectorToInferenceMap';
import dayjs from 'dayjs';

import { EmailVectorResponse, VectorType } from 'models/emailVector';

interface Frequency {
  date: string;
  count: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any; // Allow additional attributes
}

export function getFrequencyDataByVector(
  vectorName: VectorType,
  data: EmailVectorResponse | undefined
) {
  if (!data) return [];
  return (
    data?.vectors?.find((vector) => vector.code === vectorTypeToDisplay[vectorName])?.frequency ||
    []
  );
}

export function transformToTimeseries(from: string, to: string, data: Frequency[]) {
  const fromDate = dayjs(from);
  const toDate = dayjs(to);

  const timeseriesData: Frequency[] = [];

  // Extract additional keys from the first entry in the data, if available
  const additionalKeys =
    data.length > 0 ? Object.keys(data[0]).filter((key) => key !== 'date' && key !== 'count') : [];

  for (
    let currentDate = fromDate;
    currentDate.isBefore(toDate) || currentDate.isSame(toDate, 'day');
    currentDate = currentDate.add(1, 'day')
  ) {
    const formattedDate = currentDate.format('YYYY-MM-DD');
    const inputEntry = data.find((item) => dayjs(item.date).isSame(formattedDate, 'day'));

    const transformedEntry: Frequency = {
      date: formattedDate,
      count: inputEntry ? inputEntry.count : 0,
    };

    if (inputEntry) {
      // Include all additional keys from the input data
      additionalKeys.forEach((key) => {
        transformedEntry[key] = inputEntry[key] || 0;
      });
    } else {
      // If the entry is not present, add the additional keys with default value of 0
      additionalKeys.forEach((key) => {
        transformedEntry[key] = 0;
      });
    }

    timeseriesData.push(transformedEntry);
  }

  return timeseriesData;
}
