import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';

import { GetUsersFilter, User } from 'models/user';
import Loader from 'components/Loader';

import { VIPRow } from './VIPRow';

export interface VIPItem {
  name: string;
  email: string;
  isChecked: boolean;
}

interface VIPTableProps {
  vips: User[];
  isLoading: boolean;
  filters: GetUsersFilter;
  setFilters: React.Dispatch<React.SetStateAction<GetUsersFilter>>;
  total: number;
}

export function VIPTable({
  vips,
  isLoading,
  filters,
  setFilters,
  total,
}: VIPTableProps): JSX.Element {
  const vipHeaderColumnNames = ['Name', 'Email ID'];

  return (
    <div className="w-full overflow-auto">
      <table className="w-full bg-white overflow-auto rounded-t-lg">
        <thead className="text-[10px] text-light-grey w-full border-b-light border-b-border-primary">
          <tr>
            {vipHeaderColumnNames.map((header) => {
              return (
                <th key={header} scope="col" className="px-6 py-3 font-normal text-start w-1/3">
                  <div className="inline-flex items-center gap-1">
                    <span>{header}</span>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="w-full">
          {isLoading ? (
            <tr>
              <td colSpan={vipHeaderColumnNames.length}>
                <div className="w-full flex items-center justify-center p-4">
                  <Loader />
                </div>
              </td>
            </tr>
          ) : (
            vips.map((data) => {
              return <VIPRow key={data.id} user={data} />;
            })
          )}
        </tbody>
        {/* )} */}
      </table>
      {!isLoading && (
        <div className="bg-white rounded-b-lg">
          {vips?.length > 0 && !isLoading && (
            <div className="flex justify-between px-6 py-4 items-center">
              <span className="text-xs font-light">
                Showing {vips?.length > 0 ? filters.offset + 1 : '0'} -{' '}
                {vips.length > 0 ? filters.offset + vips.length : '0'} of {total}
              </span>
              <div className="flex rounded-full items-center border border-pagination-border cursor-pointer">
                <IconArrowDown
                  className={`w-6 h-6 rotate-90 border-t-light border-t-pagination-border ${filters.offset > 0 ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
                  onClick={() => {
                    if (vips) {
                      if (filters.offset > 0) {
                        setFilters({
                          ...filters,
                          offset: filters.offset - filters.limit,
                        });
                      }
                    }
                  }}
                />
                <IconArrowDown
                  className={`w-6 h-6 -rotate-90 ${filters.offset + filters.limit < total ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
                  onClick={() => {
                    if (filters.offset + filters.limit < total) {
                      setFilters({
                        ...filters,
                        offset: filters.offset + filters.limit,
                      });
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
