// eslint-disable-next-line import/no-extraneous-dependencies
import { useState } from 'react';

import * as Checkbox from '@radix-ui/react-checkbox';
import ActionMenu from 'components/ActionMenu';

import { ReactComponent as IconCheck } from 'assets/icons/check-grey.svg';
import { ReactComponent as IconMenu } from 'assets/icons/icon-action-menu.svg';

import { useAuth } from 'contexts/AuthContext';

import { UserAlertDetails } from 'components/MailAccounts/UserAlertDetails';
import { Alert } from 'components/Alert';

import { VIPName } from './VIPName';

export interface AdminRowProps {
  name: string;
  email: string;
  role: string;
  isChecked: boolean;
  isVip: boolean;
  onToggle: () => void;
  onRemove: () => void;
}

export function AdminRow({
  name,
  email,
  isChecked,
  isVip,
  role,
  onToggle,
  onRemove,
}: AdminRowProps): JSX.Element {
  const { email: authEmail } = useAuth();

  const [alertOpen, setAlertOpen] = useState(false);

  const getTextUsingRole = () => {
    switch (role) {
      case 'mailadmin':
        return 'Administrator';
      case 'superadmin':
        return 'Super Administrator';
      default:
        return 'No Role';
    }
  };

  const isDisabled = authEmail.toLowerCase() === email.toLowerCase();

  const adminActions = [
    {
      title: 'Remove From List',
      onClick: () => {
        setAlertOpen(true);
      },
    },
  ];

  const handleRemove = async () => {
    await onRemove();
    setAlertOpen(false);
  };

  return (
    <>
      <tr className="border-b-light border-border-primary align-middle border-spacing-x-2">
        <td className="text-start px-6 py-3 text-xs">
          <Checkbox.Root
            id="select-all"
            className="w-3.5 h-3.5 border border-light-grey rounded text-center flex justify-center items-center p-1"
            checked={isChecked}
            onClick={() => onToggle()}
          >
            <Checkbox.CheckboxIndicator>
              <IconCheck />
            </Checkbox.CheckboxIndicator>
          </Checkbox.Root>
        </td>
        <td className="text-start px-6 py-3 text-xs">
          <VIPName name={name} isVIP={isVip} />
        </td>
        <td className="text-start px-6 py-3 text-xs">
          <span>{email}</span>
        </td>
        <td className="text-start px-6 py-3 text-xs">
          <span>{getTextUsingRole()}</span>
        </td>
        <td className="px-6 py-3 flex flex-row-reverse relative">
          {isDisabled && (
            <div className="absolute inset-0 bg-white bg-opacity-50 cursor-not-allowed" />
          )}
          <ActionMenu icon={<IconMenu />} actions={adminActions} />
        </td>
      </tr>
      <Alert
        open={alertOpen}
        onCancel={() => setAlertOpen(false)}
        onConfirm={() => handleRemove()}
        message={`Are you sure you want to remove ${name}(${email}) from admin list?`}
        content={<UserAlertDetails name={name} email={email} />}
      />
    </>
  );
}
