import { ReactComponent as Spy } from 'assets/icons/icon-spy-black.svg';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { ReactComponent as Dollar } from 'assets/icons/dollar.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/icon-location-error.svg';
import { ReactComponent as WorldIcon } from 'assets/icons/icon-internet-error.svg';
import { ReactComponent as DomainAlt } from 'assets/icons/domain-alt.svg';
import { ReactComponent as Bomb } from 'assets/icons/bomb.svg';
import { ReactComponent as Urgency } from 'assets/icons/urgent.svg';
import { ReactElement } from 'react';
import { Event } from 'models/events';

type ThreatDetails = {
  text: string;
  icon: ReactElement;
  textColor: string;
  bgColor: string;
  borderColor: string;
};

export function getThreatDetailsByCode(code: string) {
  const allThreats: Record<string, ThreatDetails> = {
    vip_impersonation: {
      text: 'VIP Impersonation',
      icon: <Spy className="w-2.5 h-2.5" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    qr_scam: {
      text: 'QR Scam',
      icon: <Dollar className="w-2.5 h-2.5" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    vendor_impersonation: {
      text: 'Vendor Impersonation',
      icon: <Spy className="w-2.5 h-2.5" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    regulator_impersonation: {
      text: 'Regulator Impersonation',
      icon: <Spy className="w-2.5 h-2.5" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    malware: {
      text: 'Malware',
      icon: <Spy className="w-2.5 h-2.5" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
  };

  return (
    allThreats[code] || {
      text: code,
      icon: <Warning className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'avatar-blue',
      bgColor: 'soft-blue',
      borderColor: 'soft-blue',
    }
  );
}

export function getInferenceDetailsByCode(code: string) {
  const allInferences: Record<string, ThreatDetails> = {
    vip_impersonation: {
      text: 'VIP Impersonation',
      icon: <Spy className="w-2.5 h-2.5" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    qr_scam: {
      text: 'QR Scam',
      icon: <Dollar className="w-2.5 h-2.5" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    suspicious_domain: {
      text: 'Suspicious Domain',
      icon: <WorldIcon className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    financial_request_intent: {
      text: 'Financial Request Intent',
      icon: <Dollar className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    display_name_vip: {
      text: 'Display Name VIP',
      icon: <Spy className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    frequent_sender: {
      text: 'Frequent Sender',
      icon: <Bomb className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    request_for_action_intent: {
      text: 'Action Required',
      icon: <Warning className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    sense_of_urgency_tone: {
      text: 'Urgency Detected',
      icon: <Urgency className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    location_anomaly: {
      text: 'Location Anomaly',
      icon: <LocationIcon className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    first_time_sender: {
      text: 'First Time Sender',
      icon: <Spy className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    vendor_domain_lookalike: {
      text: 'Vendor Domain Lookalike',
      icon: <DomainAlt className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    regulator_domain_lookalike: {
      text: 'Regulator Domain Lookalike',
      icon: <DomainAlt className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    malicious_url: {
      text: 'Malicious URL',
      icon: <Warning className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    malicious_attachment: {
      text: 'Malicious Attachment',
      icon: <Warning className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    recurring_invoice: {
      text: 'Recurring Invoice',
      icon: <Warning className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
    is_recipient_finance_team: {
      text: 'Finance Team',
      icon: <Warning className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'critical-red',
      bgColor: 'soft-red',
      borderColor: 'soft-red',
    },
  };

  return (
    allInferences[code] || {
      text: code,
      icon: <Warning className="w-2.5 h-2.5 fill-critical-red" />,
      textColor: 'avatar-blue',
      bgColor: 'soft-blue',
      borderColor: 'soft-blue',
    }
  );
}

// getInferences returns the inferences for the given key

export function getInferences(key: string, event: Event): string[] {
  const keyVector = event.vectors?.find((vector) => vector?.code === key);

  // If vector is found and has inferences, return them; otherwise, return an empty array.
  return keyVector?.inferences || [];
}
