import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from 'assets/logo/logo-with-name.png';
import { ConsentStatusTypes } from 'enums/consentStatusTypes';
import { useAuth } from 'contexts/AuthContext';
import { useLazyOnboardOrgQuery } from 'service/onboardingApi';
import Shield from 'assets/illustrations/shield.png';
import { useToast } from 'hooks/toast';
import { SuccessToast } from 'components/Toasts/SuccessToast';

interface AdminConsentResult {
  type: 'ADMIN_CONSENT_RESULT';
  status: ConsentStatusTypes;
  details: string;
}

function Onboarding(): JSX.Element {
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [consentStatus, setConsentStatus] = useState<ConsentStatusTypes>('pending');
  const { adminConsentUrl, setOnboardingStatus, orgId } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [triggerOnboarding, { isLoading: isOnboarding }] = useLazyOnboardOrgQuery();
  const { showToast } = useToast();

  let checkChild: string | number | NodeJS.Timeout | undefined;
  const handleMessage = useCallback(
    (event: MessageEvent) => {
      const redirectUri = new URL(adminConsentUrl).searchParams.get('redirect_uri') || '';
      if (event.origin !== new URL(redirectUri).origin) return;
      const data = event.data as AdminConsentResult;
      if (data.type === 'ADMIN_CONSENT_RESULT') {
        setIsLoading(false);
        setConsentStatus(data.status);
        if (data.status === 'success') {
          setConsentStatus('success');
          clearInterval(checkChild);
          triggerOnboarding({ orgId: orgId });
        } else {
          setConsentStatus(data.status);
          setError(data.details);
        }
      }
    },
    [adminConsentUrl, checkChild, orgId, triggerOnboarding]
  );

  useEffect(() => {
    if (!isOnboarding && consentStatus === 'success') {
      setOnboardingStatus('COMPLETED');
      showToast({
        component: <SuccessToast message="Congrats! Your onboarding was successful" />,
      });
      navigate('/', { replace: true, state: { from: location } });
    }
  }, [location, navigate, setOnboardingStatus, isOnboarding, consentStatus, showToast]);

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [handleMessage]);

  const initiateConsent = async () => {
    setIsLoading(true);
    try {
      const childWindow = window.open(adminConsentUrl, 'Admin Consent', 'width=600,height=600');

      if (childWindow) {
        checkChild = setInterval(() => {
          if (childWindow.closed) {
            clearInterval(checkChild);
            if (consentStatus === 'pending') {
              setIsLoading(false);
              setConsentStatus('cancelled');
              setError(`Failed to initiate consent. Reason: cancelled`);
            }
          }
        }, 500);
      } else {
        setIsLoading(false);
        setConsentStatus('failed');
        setError(`Failed to initiate admin consent`);
      }
    } catch (err) {
      setIsLoading(false);
      setConsentStatus('failed');
      setError(`Failed to initiate admin consent: ${err}`);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 bg-login ">
      <div className="border-[#E1E5E8] bg-white rounded-2xl min-w-96 shadow-[0rem_.25rem_1.5rem_0rem_rgba(0,0,0,0.15)]">
        <div className="text-center p-9 pl-28 pr-28">
          <img className="h-9 inline-block" src={Logo} alt="RavenMail" />
        </div>
        <hr className="border-[#E1E5E8]" />
        <div className="h-80 flex justify-center align-middle">
          <div className="content-center flex justify-center flex-col align-middle items-center">
            {isOnboarding ? (
              <>
                <div className="self-center after:size-8 after:absolute after:bg-white after:left-1 after:rounded-full after:right-1 after:top-1 after:bottom-1 after:m-auto size-12 rounded-full bg-gradient-to-r from-purple-500 via-pink-500 to-sky-500 animate-spin" />
                <div className="text-md font-normal w-72 pt-8 text-center">
                  Setting up your dashboard
                </div>
              </>
            ) : (
              <>
                <img className="w-16 mb-6 inline-block" src={Shield} alt="Privacy" />
                <div className="text-md font-normal w-72 pb-8 text-center">
                  We require consent on behalf of the organisation to perform actions. Please grant
                  RavenMail consent to continue.
                </div>
                <button
                  type="button"
                  onClick={initiateConsent}
                  disabled={isLoading}
                  className="bg-slate-900 text-white text-lg font-normal rounded-md w-80 h-14 disabled:bg-slate-700"
                >
                  {isLoading ? (
                    <div className="flex space-x-2 justify-center items-center h-1">
                      <div className="h-2 w-2 bg-white rounded-full animate-ping [animation-delay:-0.3s]" />
                      <div className="h-2 w-2 bg-white rounded-full animate-ping [animation-delay:-0.15s]" />
                      <div className="h-2 w-2 bg-white rounded-full animate-ping" />
                    </div>
                  ) : (
                    'Grant consent to RavenMail'
                  )}
                </button>
                <div className="h-8">
                  {error && <p className="mt-2 text-center text-sm text-red-600">{error}</p>}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Onboarding;
