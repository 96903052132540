import { IDP } from 'enums/idp';
import Keycloak from 'keycloak-js';

class KeycloakService {
  private keycloak: Keycloak | null = null;

  private readonly baseUrl: string;

  private readonly clientId: string;

  private readonly redirectUri = `${window.location.origin}/login`;

  private readonly logoutUri = `${window.location.origin}/logout`;

  constructor() {
    this.baseUrl = process.env.REACT_APP_KEYCLOAK_URL || '';
    this.clientId = 'ravenclaw-dashboard';
    if (this.baseUrl === '' || this.clientId === '') {
      throw new Error('Keycloak url and client id not supplied');
    }
  }

  async init(realm: string): Promise<boolean> {
    this.keycloak = new Keycloak({
      url: this.baseUrl,
      realm: realm.toLowerCase(),
      clientId: this.clientId,
    });

    try {
      const authenticated = await this.keycloak.init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
        pkceMethod: 'S256',
        redirectUri: this.redirectUri,
        flow: 'implicit',
        silentCheckSsoFallback: false,
        messageReceiveTimeout: 1000,
      });

      if (authenticated) {
        this.setupTokenRefresh();
      }

      return authenticated;
    } catch {
      return false;
    }
  }

  async login(realm: string, idpHint: IDP): Promise<void> {
    await this.init(realm);

    if (!this.keycloak) {
      throw new Error('Keycloak not initialized');
    }

    return this.keycloak.login({
      idpHint: idpHint,
      scope: 'openid email profile',
      redirectUri: this.redirectUri,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      prompt: 'select_account',
    });
  }

  async logout(): Promise<void> {
    if (this.keycloak) {
      await this.keycloak.logout({
        redirectUri: this.logoutUri,
      });
    }
  }

  isAuthenticated(): boolean {
    return this.keycloak?.authenticated ?? false;
  }

  getToken(): string | undefined {
    return this.keycloak?.token;
  }

  clearToken(): void {
    if (this.keycloak) {
      this.keycloak.clearToken();
    }
  }

  private setupTokenRefresh(): void {
    if (!this.keycloak) {
      throw new Error('Keycloak not initialized');
    }

    this.keycloak.onTokenExpired = () => {
      this.keycloak?.updateToken(70).catch(async () => {
        await this.handleUnauthorized();
      });
    };
  }

  getOrgId(): string {
    return this.keycloak?.tokenParsed?.organization_id || '';
  }

  getName(): string {
    return this.keycloak?.tokenParsed?.name || '';
  }

  isMailAdmin(): boolean {
    return this.keycloak?.tokenParsed?.realm_access?.roles?.includes('mailadmin') || false;
  }

  isSuperAdmin(): boolean {
    return this.keycloak?.tokenParsed?.realm_access?.roles?.includes('superadmin') || false;
  }

  getEmail(): string {
    return this.keycloak?.tokenParsed?.email || '';
  }

  async handleUnauthorized(): Promise<void> {
    await this.logout();
    this.clearToken();
  }

  async updateToken(minValidity = 5): Promise<boolean> {
    if (!this.keycloak) {
      throw new Error('Keycloak not initialized');
    }

    try {
      return await this.keycloak.updateToken(minValidity);
    } catch {
      return false;
    }
  }
}

export default new KeycloakService();
