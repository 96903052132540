import * as Select from '@radix-ui/react-select';

import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';
import LegendMarker from 'components/LegendMarker';
import {
  CartesianGrid,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Line,
  ComposedChart,
} from 'recharts';
import { dateUtils } from 'utils/time';

import { useGetEmailVectorFrequencyQuery } from 'service/emailVectorFrequency';

import { VectorFrequencyFilters, VectorType } from 'models/emailVector';
import { useState } from 'react';
import { useOrgId } from 'hooks/useOrgId';

import { FrequencyGraphToolTip } from 'components/FrequencyGraph/FrequencyGraphToolTip';
import { getFrequencyDataByVector, transformToTimeseries } from './data';

interface VectorFrequencyProps {
  vector: VectorType;
  title: string;
}

export function VectorFrequency({ vector, title }: VectorFrequencyProps) {
  // timePeriodSelectOptions is an array of the options in the select dropdown for selecting the time period
  const timePeriodSelectOptions = [
    { value: '7', label: '7 days ago' },
    { value: '14', label: '14 days ago' },
    { value: '30', label: '30 days ago' },
    { value: '60', label: '60 days ago' },
  ];

  const [OrgId] = useOrgId();

  const [filters, setFilters] = useState<VectorFrequencyFilters>({
    orgId: OrgId,
    from: dateUtils.subtract(new Date().toDateString(), 29, 'd').toDate().toISOString(),
    to: new Date().toISOString(),
    frequency: 'daily',
    vector: vector,
  });

  const [previousdaysFilter, setPreviousdaysFilter] = useState({
    from: dateUtils.subtract(new Date().toDateString(), 59, 'd').toDate().toISOString(),
    to: dateUtils.subtract(new Date().toDateString(), 30, 'd').toDate().toISOString(),
  });

  const { data, isLoading } = useGetEmailVectorFrequencyQuery(filters, {
    refetchOnMountOrArgChange: true,
  });

  const { data: previousData, isLoading: isPreviousLoading } = useGetEmailVectorFrequencyQuery(
    { ...filters, ...previousdaysFilter },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  // handleSelect is used to update the filters when the time period is changed
  // it subtracts the time period from the current date and updates the filters
  const handleSelect = (value: string) => {
    // days is the number of days ago that the time period is
    const days = parseInt(value, 10);

    // subtract the time period from the current date and update the filters
    // here we are subtracting 1 day because we want to include the current day in the time period
    setFilters({
      ...filters,
      from: dateUtils
        .subtract(new Date().toDateString(), days - 1, 'd')
        .toDate()
        .toISOString(),
      to: new Date().toISOString(),
    });

    setPreviousdaysFilter({
      from: dateUtils
        .subtract(new Date().toDateString(), 2 * days - 1, 'd')
        .toDate()
        .toISOString(),
      to: dateUtils.subtract(new Date().toDateString(), days, 'd').toDate().toISOString(),
    });
  };

  const mergePreviousAndCurrent = () => {
    if (!isLoading && !isPreviousLoading) {
      // converting the data to timeseries
      const senderCurrentData = transformToTimeseries(
        filters.from || '',
        filters.to || '',
        getFrequencyDataByVector(vector, data)
      );

      const senderPreviousData = transformToTimeseries(
        previousdaysFilter.from || '',
        previousdaysFilter.to || '',
        getFrequencyDataByVector(vector, previousData)
      );

      const allFrequencyData = [];

      // assuming that the length if the current and previous data set is same
      // and data is sorted in ascending order by date
      // we are merging datasets in the following way
      // assume current data date ranges from 2022-01-08 to 2022-01-14
      // and previous data date ranges from 2022-01-01 to 2022-01-07
      // then we will merge the current data with the previous data
      // in the following way :
      // date | count | previousCount
      // 2022-01-08 (1st day in the current data) | count from current data | count from the corresponding day in previous data i.e 2022-01-01 (from the previous data) |
      for (let i = 0; i < senderCurrentData.length; i += 1) {
        const eachFrequency = {
          date: senderCurrentData[i].date,
          count: senderCurrentData[i].count,
          previousCount: senderPreviousData[i].count,
        };

        allFrequencyData.push(eachFrequency);
      }

      return allFrequencyData;
    }

    return [];
  };

  const graphLabels = [
    {
      label: 'Current',
      value: 'count',
    },
    {
      label: 'Previous',
      value: 'previousCount',
    },
  ];

  return (
    <div className="flex-1">
      <div className="bg-white rounded-lg border-light border-primary-border w-full">
        <div className="py-3.5 px-8 border-b-light border-border-primary">
          <div className="flex items-center justify-between text-xs">
            <span>{title}</span>
            <Select.Root defaultValue="30" onValueChange={handleSelect}>
              <Select.Trigger className="border-light border-border-primary p-2 rounded-md text-xs text-light-grey flex items-center gap-4">
                <Select.Value />
                <Select.Icon>
                  <IconArrowDown className="w-4 h-4" />
                </Select.Icon>
              </Select.Trigger>
              <Select.Portal>
                <Select.Content className="bg-white border border-gray-300 rounded-md shadow-lg text-xs text-light-grey p-2">
                  <Select.Viewport>
                    {timePeriodSelectOptions.map((option) => (
                      <Select.Item
                        key={option.value}
                        value={option.value}
                        className="p-2 cursor-pointer hover:bg-gray-100 text-light-grey data-[highlighted]:text-black data-[highlighted]:bg-select-background data-[highlighted]:outline-none rounded-sm"
                      >
                        <Select.ItemText>{option.label}</Select.ItemText>
                      </Select.Item>
                    ))}
                  </Select.Viewport>
                </Select.Content>
              </Select.Portal>
            </Select.Root>
          </div>
        </div>
        <div className="flex items-center w-full gap-8 px-8 py-4 border-b-light border-primary-border">
          <LegendMarker markerColor="light-blue" text="Sender" />
          <LegendMarker markerColor="light-blue" text="Previous 30 days" dotted />
        </div>
        <div className="w-full bg-white py-3.5 px-8 rounded-b-lg">
          <ResponsiveContainer width="100%" height={240}>
            <ComposedChart
              accessibilityLayer
              margin={{
                left: 20,
                right: 20,
              }}
              data={mergePreviousAndCurrent()}
            >
              <defs>
                <linearGradient id="sender" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#85D8FF" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#85D8FF" stopOpacity={0.1} />
                </linearGradient>
                <linearGradient id="previous-30-days" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.1} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="date"
                className="text-xs"
                color="#E1E5E8"
                axisLine={false}
                tickLine={false}
                tick={{
                  fill: '#9FAFB5',
                  fontSize: '10px',
                }}
                tickMargin={12}
                tickFormatter={(value) => {
                  const dateText = value as string;
                  return dateUtils.format(dateText, 'MMM DD').toString();
                }}
                scale="point"
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                orientation="right"
                tick={{
                  fill: '#9FAFB5',
                  fontSize: '10px',
                }}
                tickMargin={16}
                tickCount={3}
                domain={[0, (dataMax: number) => Math.ceil(dataMax * 1.1)]} // Add 10% to the max value to make sure the max value is visible
              />
              <CartesianGrid
                vertical={false}
                color="#E1E5E8"
                strokeWidth={0.5}
                strokeOpacity={0.5}
                horizontalCoordinatesGenerator={({ height }) => {
                  const count = 10; // Number of horizontal lines you want
                  return Array(count)
                    .fill(0)
                    .map((_, index) => height * (index / count));
                }}
              />
              <Tooltip content={<FrequencyGraphToolTip labels={graphLabels} />} cursor={false} />
              <Area
                dataKey="count"
                stroke="#85D8FF"
                dot={false}
                fillOpacity={1}
                fill="url(#sender)"
                strokeWidth={1}
                z={1}
              />
              <Line
                dataKey="previousCount"
                stroke="#85D8FF"
                strokeDasharray="3 3"
                dot={false}
                strokeWidth={1}
                z={2}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}
