import { useState } from 'react';
import * as Tabs from '@radix-ui/react-tabs';

import Statistics from 'components/Statistics';
import EmailAttributes from 'components/EmailAttributes';
import Threats from 'components/Threats';
import { Navbar } from 'components/Layouts/Navbar';

export default function Home() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const handleSelectTab = (tabIdx: number) => {
    setActiveTabIndex(tabIdx);
  };

  return (
    <div className="w-full px-8 py-5">
      <Navbar title="Overview" />
      <div className="mt-6 flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <div className="flex space-x-2">
            <Tabs.Root value={activeTabIndex.toString()} className="bg-gray-100 rounded-lg p-1">
              <Tabs.List className="flex items-center border-light border-border-primary p-1 w-fit rounded-lg bg-white">
                <Tabs.Trigger
                  value="0"
                  className={`px-4 py-2 rounded-md text-sm font-light ${activeTabIndex === 0 ? 'bg-black text-white shadow-sm' : 'bg-transparent text-gray-700'}`}
                  onClick={() => handleSelectTab(0)}
                >
                  Threats
                </Tabs.Trigger>
                <Tabs.Trigger
                  value="1"
                  className={`px-4 py-2 rounded-md text-sm font-light ${activeTabIndex === 1 ? 'bg-black text-white shadow-sm' : 'bg-transparent text-gray-700'}`}
                  onClick={() => handleSelectTab(1)}
                >
                  Email Vectors
                </Tabs.Trigger>
              </Tabs.List>
            </Tabs.Root>
          </div>
          <Statistics />
        </div>
        <div className="w-full h-px bg-gray-200 mt-1 mb-0" />
        {activeTabIndex === 0 ? <Threats /> : <EmailAttributes />}
      </div>
    </div>
  );
}
