import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';

import Loader from 'components/Loader';
import { Domain, DomainSearchFilters } from 'models/domains';
import { VendorRow } from './VendorRow';

interface VendorsTableProps {
  vendors: Domain[];
  loading: boolean;
  filters: DomainSearchFilters;
  setFilters: React.Dispatch<React.SetStateAction<DomainSearchFilters>>;
  total: number;
}

export function VendorsTable({
  vendors,
  loading,
  filters,
  setFilters,
  total,
}: VendorsTableProps): JSX.Element {
  const vendorsTableColumn = ['Name', 'Domain', 'Tags'];

  return (
    <div className="w-full overflow-auto">
      <table className="w-full bg-white overflow-auto rounded-t-lg">
        <thead className="text-[10px] text-light-grey w-full border-b-light border-b-border-primary">
          <tr>
            {vendorsTableColumn.map((header) => {
              return (
                <th key={header} scope="col" className="px-6 py-3 font-normal text-start w-1/3">
                  <div className="inline-flex items-center gap-1">
                    <span>{header}</span>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="w-full">
          {loading ? (
            <tr>
              <td colSpan={vendorsTableColumn.length}>
                <div className="w-full flex items-center justify-center p-4">
                  <Loader />
                </div>
              </td>
            </tr>
          ) : (
            vendors.map((data) => {
              return <VendorRow vendor={data} key={data.id} />;
            })
          )}
        </tbody>
      </table>
      {!loading && (
        <div className="bg-white rounded-b-lg">
          {vendors?.length > 0 && !loading && (
            <div className="flex justify-between px-6 py-4 items-center">
              <span className="text-xs font-light">
                Showing {vendors?.length > 0 ? filters.offset + 1 : '0'} -{' '}
                {vendors.length > 0 ? filters.offset + vendors.length : '0'} of {total}
              </span>
              <div className="flex rounded-full items-center border border-pagination-border cursor-pointer">
                <IconArrowDown
                  className={`w-6 h-6 rotate-90 border-t-light border-t-pagination-border ${filters.offset > 0 ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
                  onClick={() => {
                    if (vendors) {
                      if (filters.offset > 0) {
                        setFilters({
                          ...filters,
                          offset: filters.offset - filters.limit,
                        });
                      }
                    }
                  }}
                />
                <IconArrowDown
                  className={`w-6 h-6 -rotate-90 ${filters.offset + filters.limit < total ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
                  onClick={() => {
                    if (filters.offset + filters.limit < total) {
                      setFilters({
                        ...filters,
                        offset: filters.offset + filters.limit,
                      });
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
