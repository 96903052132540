import { createApi } from '@reduxjs/toolkit/query/react';
import {
  GET_VENDORS,
  GET_SENDERS,
  GET_REGULATORS,
  UPDATE_DOMAIN,
  DOMAIN_OVERVIEW,
  ADD_DOMAIN,
  SUGGESTED_VENDOR,
} from 'constants/apiUrls';

import {
  ExternalUserSearchResult,
  ExternalUserFilter,
  ExternalUserOverview,
} from 'models/externalUsers';
import { updateExternalCount } from 'redux-store/slices/users';
import {
  DomainSearchResults,
  DomainSearchFilters,
  UpdateDomain,
  DomainOverview,
  Domain,
  AddDomainRequest,
  SuggestedDomainResults,
  DomainIDList,
} from 'models/domains';
import { Pagination } from 'models/pagination';

import { baseQuery } from './baseQuery';

export const externalUsersApi = createApi({
  reducerPath: 'externalUsersApi',
  baseQuery: baseQuery,
  tagTypes: ['EXTERNAL_USERS', 'VENDORS', 'REGULATORS', 'SUGGESTED_VENDORS'],
  endpoints: (builder) => ({
    getExternalUsers: builder.query<
      ExternalUserSearchResult,
      ExternalUserFilter & { orgId: string }
    >({
      query: (filter) => {
        const params = new URLSearchParams();

        if (filter.limit) params.append('limit', filter.limit.toString());
        if (filter.offset) params.append('offset', filter.offset.toString());
        if (filter.searchQuery) params.append('query', filter.searchQuery);
        if (filter.tags?.length) {
          params.append('tags', filter.tags.join(','));
        }

        return `${GET_SENDERS(filter.orgId)}?${params.toString()}`;
      },
      providesTags: ['EXTERNAL_USERS'],
      async onQueryStarted({ searchQuery }, { queryFulfilled, dispatch }) {
        const { data } = await queryFulfilled;
        if (searchQuery === '') {
          dispatch(updateExternalCount(data?.total));
        }
      },
    }),
    getVendors: builder.query<DomainSearchResults, DomainSearchFilters & { orgId: string }>({
      query: (filter) => {
        const params = new URLSearchParams();

        if (filter.limit) params.append('limit', filter.limit.toString());
        if (filter.offset) params.append('offset', filter.offset.toString());
        if (filter.searchQuery) params.append('query', filter.searchQuery);

        if (filter.tags?.length) {
          params.append('tags', filter.tags.join(','));
        }

        return `${GET_VENDORS(filter.orgId)}?${params.toString()}`;
      },
      providesTags: ['VENDORS'],
    }),
    getRegulators: builder.query<DomainSearchResults, DomainSearchFilters & { orgId: string }>({
      query: (filter) => {
        const params = new URLSearchParams();

        if (filter.limit) params.append('limit', filter.limit.toString());
        if (filter.offset) params.append('offset', filter.offset.toString());
        if (filter.searchQuery) params.append('query', filter.searchQuery);

        if (filter.tags?.length) {
          params.append('tags', filter.tags.join(','));
        }

        return `${GET_REGULATORS(filter.orgId)}?${params.toString()}`;
      },
      providesTags: ['REGULATORS'],
    }),
    assignDomain: builder.mutation<undefined, UpdateDomain>({
      query: (data) => {
        return {
          url: `${UPDATE_DOMAIN(data.orgId, data.domainId)}/${data.group}`,
          method: 'POST',
        };
      },
      invalidatesTags: ['VENDORS', 'REGULATORS', 'EXTERNAL_USERS'],
    }),
    removeDomain: builder.mutation<undefined, UpdateDomain>({
      query: (data) => {
        return {
          url: `${UPDATE_DOMAIN(data.orgId, data.domainId)}/${data.group}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['VENDORS', 'REGULATORS', 'EXTERNAL_USERS'],
    }),
    getExternalUserOverview: builder.query<ExternalUserOverview, { orgId: string; userId: number }>(
      {
        query: ({ orgId, userId }) => {
          return {
            url: `${GET_SENDERS(orgId)}/${userId}/overview`,
            method: 'GET',
          };
        },
      }
    ),
    getDomainOverview: builder.query<DomainOverview, { orgId: string; domainId: string }>({
      query: ({ orgId, domainId }) => {
        return {
          url: `${DOMAIN_OVERVIEW(orgId, domainId)}`,
          method: 'GET',
        };
      },
    }),
    addDomain: builder.mutation<Domain, AddDomainRequest & { orgId: string }>({
      query: (data) => {
        return {
          url: `${ADD_DOMAIN(data.orgId)}`,
          method: 'POST',
          body: {
            domain: data.domain,
            domainType: data.domainType,
          },
        };
      },
      invalidatesTags: ['VENDORS', 'REGULATORS'],
    }),
    suggestedVendors: builder.query<SuggestedDomainResults, { orgId: string } & Pagination>({
      query: (filter) => {
        const params = new URLSearchParams();

        params.append('limit', filter.limit.toString());
        params.append('offset', filter.offset.toString());

        return `${SUGGESTED_VENDOR(filter.orgId)}?${params.toString()}`;
      },
      providesTags: ['SUGGESTED_VENDORS'],
    }),
    addVendors: builder.mutation<undefined, DomainIDList & { orgId: string }>({
      query: (data) => {
        return {
          url: `${GET_VENDORS(data.orgId)}`,
          method: 'POST',
          body: {
            domainIds: data.domainIds,
          },
        };
      },
      invalidatesTags: ['SUGGESTED_VENDORS', 'VENDORS', 'EXTERNAL_USERS'],
    }),
  }),
});

export const {
  useGetExternalUsersQuery,
  useGetRegulatorsQuery,
  useGetVendorsQuery,
  useAssignDomainMutation,
  useRemoveDomainMutation,
  useGetExternalUserOverviewQuery,
  useGetDomainOverviewQuery,
  useAddDomainMutation,
  useSuggestedVendorsQuery,
  useAddVendorsMutation,
} = externalUsersApi;
