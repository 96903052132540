import { useState } from 'react';

import Card from 'components/Card';
import { CardContent } from 'components/Card/CardContent';
import CardHeader from 'components/Card/CardHeader';
import * as Select from '@radix-ui/react-select';

import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';
import { validateDomain } from 'utils/validation';
import { useAddDomainMutation } from 'service/externalUsers';
import { useOrgId } from 'hooks/useOrgId';
import { DomainType } from 'models/domains';
import { useToast } from 'hooks/toast';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { getErrorMessage } from 'utils/errors';
import { ActionToast } from 'components/Toasts/ActionToast';
import { getInitials } from 'utils/name';
import Loader from 'components/Loader';

interface AddExternalDomainProps {
  onClose: () => void;
  defaultRole?: string;
}

export function AddExternalDomain({ onClose, defaultRole }: AddExternalDomainProps): JSX.Element {
  const { showToast } = useToast();

  const [formValues, setFormValues] = useState({
    domain: {
      value: '',
      error: '',
    },
    type: {
      value: defaultRole,
      error: '',
    },
  });

  const validateForm = () => {
    let isValid = true;

    if (!validateDomain(formValues.domain.value)) {
      setFormValues((prevValues) => ({
        ...prevValues,
        domain: {
          ...prevValues.domain,
          error: 'Please enter a valid domain',
        },
      }));
      isValid = false;
    }

    if (formValues.type.value === '') {
      setFormValues((prevValues) => ({
        ...prevValues,
        type: {
          ...prevValues.type,
          error: 'Please select a domain type',
        },
      }));
      isValid = false;
    }

    return isValid;
  };

  const [OrgId] = useOrgId();

  const [addDomain, { isLoading }] = useAddDomainMutation();

  const handleAddDomain = async () => {
    try {
      await addDomain({
        domain: formValues.domain.value,
        domainType: formValues.type.value as DomainType,
        orgId: OrgId,
      }).unwrap();

      showToast({
        component: (
          <ActionToast
            initials={getInitials(formValues.domain.value, 2)}
            avatarBgColor="warning-yellow"
            description={`${formValues.domain.value} added successfully as a ${formValues.type.value}`}
            showUndo={false}
          />
        ),
      });

      onClose();
    } catch (error) {
      showToast({
        component: <ErrorToast message={getErrorMessage(error)} />,
      });
    }
  };

  const handleSave = () => {
    if (validateForm()) {
      handleAddDomain();
    }
  };

  const handleInputChange = (value: string) => {
    if (validateDomain(value)) {
      setFormValues({
        ...formValues,
        domain: {
          value,
          error: '',
        },
      });
    } else {
      setFormValues({
        ...formValues,
        domain: {
          value,
          error: 'Please enter a valid domain',
        },
      });
    }
  };

  const domainTypeOptions = [
    {
      label: 'Mark as Vendor',
      value: 'vendor',
    },
    {
      label: 'Mark as Regulator',
      value: 'regulator',
    },
  ];

  const handleSelectChange = (value: string) => {
    setFormValues({
      ...formValues,
      type: {
        value,
        error: '',
      },
    });
  };

  return (
    <Card width="100%">
      <CardHeader>
        <span>Add Domain</span>
      </CardHeader>
      <CardContent>
        <div className="w-full flex flex-col gap-2">
          <div className="w-full border-border-primary flex items-center">
            <span className="py-2 px-3 rounded-l-lg border-light border-border-primary bg-border-primary text-black text-xs">
              www.
            </span>
            <input
              placeholder="Enter domain"
              className="py-2 px-3 placeholder:text-light-grey flex-1 outline-none text-xs rounded-r-lg border-light border-border-primary"
              value={formValues.domain.value}
              onChange={(e) => handleInputChange(e.target.value)}
            />
          </div>
          {formValues.domain.error && (
            <span className="text-2xs text-red-500">{formValues.domain.error}</span>
          )}
          <Select.Root onValueChange={handleSelectChange} defaultValue={defaultRole}>
            <Select.Trigger className="border-light border-border-primary text-black placeholder:text-light-grey p-2 rounded-md text-xs flex items-center justify-between">
              <Select.Value placeholder="Choose a type" className="placeholder:text-light-grey" />
              <Select.Icon>
                <IconArrowDown className="w-4 h-4" />
              </Select.Icon>
            </Select.Trigger>
            <Select.Portal>
              <Select.Content
                className="bg-white border border-gray-300 rounded-md shadow-lg text-xs text-light-grey p-2"
                align="end"
                side="bottom"
              >
                <Select.Viewport>
                  {domainTypeOptions.map((option) => (
                    <Select.Item
                      key={option.value}
                      value={option.value}
                      className="p-2 cursor-pointer hover:bg-gray-100 text-light-grey data-[highlighted]:text-black data-[highlighted]:bg-select-background data-[highlighted]:outline-none rounded-sm"
                    >
                      <Select.ItemText>{option.label}</Select.ItemText>
                    </Select.Item>
                  ))}
                </Select.Viewport>
              </Select.Content>
            </Select.Portal>
          </Select.Root>
          {formValues.type.error && (
            <span className="text-2xs text-red-500">{formValues.type.error}</span>
          )}
        </div>
      </CardContent>
      <div className="flex items-center justify-center gap-4 py-5 px-8 border-t-light border-t-border-primary">
        <button
          type="button"
          className="flex-1 rounded-full border-black/20 border-light text-black/20 px-8 py-2 text-xs hover:text-black hover:border-black"
          onClick={() => onClose()}
        >
          Cancel
        </button>
        <button
          type="button"
          className="flex-1 bg-black px-8 py-2 rounded-full text-white text-xs"
          onClick={() => handleSave()}
        >
          {!isLoading ? 'Save' : <Loader customCss="w-4 h-4 border-2" color="white" />}
        </button>
      </div>
    </Card>
  );
}
