import Avatar from 'components/Avatar';
import { getInitials } from 'utils/name';

interface IdentityTileProps {
  name: string;
  bgColor?: string;
  textColor?: string;
}

export function IdentityTile({
  name,
  bgColor = 'avatar-blue',
  textColor = 'white',
}: IdentityTileProps) {
  return (
    <div className="flex items-center gap-2">
      <Avatar
        initials={getInitials(name, 2)}
        bgColor={bgColor}
        textColor={textColor}
        customCssClass="w-6 h-6 p-1 text-[0.5rem]"
      />
      <span className="text-xs">{name}</span>
    </div>
  );
}
