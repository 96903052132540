// eslint-disable-next-line import/no-extraneous-dependencies
import { useState } from 'react';

import { ReactComponent as IconMenu } from 'assets/icons/icon-action-menu.svg';

import { User } from 'models/user';

import ActionMenu from 'components/ActionMenu';
import { Alert } from 'components/Alert';
import { UserAlertDetails } from 'components/MailAccounts/UserAlertDetails';
import { SuccessToast } from 'components/Toasts/SuccessToast';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { ActionToast } from 'components/Settings/AddToast';

import { useForceLogoutMutation, useRemoveVIPUsersMutation } from 'service/users';

import { useToast } from 'hooks/toast';
import { useOrgId } from 'hooks/useOrgId';

import { VIPName } from './VIPName';

export interface VIPRowProps {
  user: User;
}

export function VIPRow({ user: { id, name, email } }: VIPRowProps): JSX.Element {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const { showToast } = useToast();

  const [action, setAction] = useState<'force-logout' | 'vip'>('vip');

  const userActions = [
    {
      title: 'Force password change',
      onClick: async () => {
        setAlertOpen(() => {
          setAction('force-logout');
          setAlertMessage('Are you sure you want to force logout this user?');
          return true;
        });
      },
    },
    {
      title: 'Remove From VIP List',
      onClick: () => {
        setAlertOpen(() => {
          setAction('vip');
          setAlertMessage(`Are you sure you want to remove ${name}(${email}) from VIP list?`);
          return true;
        });
      },
    },
  ];

  const [forceLogout] = useForceLogoutMutation();

  const [removeFromVIP] = useRemoveVIPUsersMutation();

  const [OrgId] = useOrgId();

  const handleForceLogout = async () => {
    try {
      await forceLogout({
        orgId: OrgId,
        employeeId: id,
      });

      showToast({
        component: <SuccessToast message="User logged out successfully" />,
      });
    } catch {
      showToast({
        component: <ErrorToast message="Failed to force logout user" />,
      });
    } finally {
      setAlertOpen(false);
    }
  };

  const handleRemoveFromVIPList = async () => {
    try {
      await removeFromVIP({
        orgId: OrgId,
        userIds: [id],
      });

      showToast({
        component: (
          <ActionToast
            users={[{ id, name, email, vip: true, financeAndAccounting: false }]}
            roleType="VIP"
            showUndo={false}
            action="remove"
          />
        ),
      });
    } catch {
      showToast({
        component: <ErrorToast message="Failed to remove user from VIP list" />,
      });
    } finally {
      setAlertOpen(false);
    }
  };

  const handleConfirm = () => {
    if (action === 'force-logout') {
      handleForceLogout();
    } else {
      handleRemoveFromVIPList();
    }
  };

  return (
    <>
      <tr className="border-b-light border-border-primary align-middle border-spacing-x-2 cursor-pointer">
        <td className="text-start px-6 py-3 text-xs">
          <VIPName name={name} isVIP />
        </td>
        <td className="text-start px-6 py-3 text-xs">
          <span>{email}</span>
        </td>
        <td className="px-6 py-3 flex flex-row-reverse">
          <ActionMenu icon={<IconMenu />} actions={userActions} />
        </td>
      </tr>
      <Alert
        open={alertOpen}
        onCancel={() => setAlertOpen(false)}
        onConfirm={() => handleConfirm()}
        message={alertMessage}
        content={<UserAlertDetails name={name} email={email} />}
      />
    </>
  );
}
