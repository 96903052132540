import { Badge } from 'components/Badge';
import Scale from 'components/Scale';
import { Divider } from 'components/Divider';

import { getInferenceDetailsByCode } from './data';

interface EmailAnalysisItemProps {
  title: string;
  value: { text: string; highlight: boolean }[];
  inferences?: string[];
  result?: string;
  severityScore?: number;
  showHr?: boolean;
}

export function EmailAnalysisItem({
  title,
  value = [],
  inferences,
  result,
  severityScore,
  showHr,
}: EmailAnalysisItemProps) {
  const getResultDetails = () => {
    const resultToColor: Record<string, { text: string; color: string }> = {
      critical: {
        text: 'Critical',
        color: 'critical-red',
      },
      low: {
        text: 'Low Risk',
        color: 'success-green',
      },
      high: {
        text: 'High',
        color: 'warning-yellow',
      },
      medium: {
        text: 'Medium',
        color: 'warning-yellow',
      },
    };

    return resultToColor[result || 'low'];
  };

  const resultDetails = getResultDetails();
  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between items-center gap-4">
          <div className="flex flex-col gap-1">
            <span className="text-2xs text-light-grey">{title}</span>
            {value.map((val) => (
              <span
                className={`text-xs break-all block ${val.highlight ? 'text-red-500' : ''}`}
                key={val.text}
              >
                {val.text}
              </span>
            ))}
          </div>
          {severityScore ? <Scale value={severityScore} indicatorColor="critical-red" /> : null}
        </div>
        {result || inferences?.length ? (
          <div className="flex justify-between items-center gap-4">
            {inferences ? (
              <div className="flex flex-wrap gap-1">
                {inferences?.map((inference) => {
                  const inferenceDetails = getInferenceDetailsByCode(inference);
                  return (
                    <Badge
                      key={inference}
                      text={inferenceDetails.text}
                      bgColor={inferenceDetails.bgColor}
                      borderColor={inferenceDetails.bgColor}
                      textColor={inferenceDetails.textColor}
                      borderRadius="rounded"
                      customCssClass="mono"
                    />
                  );
                })}
              </div>
            ) : null}
            {result && (
              <span className={`text-${resultDetails.color} text-xs`}>{resultDetails.text}</span>
            )}
          </div>
        ) : null}
      </div>
      {showHr && <Divider />}
    </>
  );
}
