import { useEffect, useState } from 'react';

import * as Tabs from '@radix-ui/react-tabs';

import { Badge } from 'components/Badge';
import { useOrgId } from 'hooks/useOrgId';

import { ALL_ACCOUNTS, GROUP_USERS, VIP_USERS } from 'constants/tabName';
import { useGetUsersQuery, useGetVIPUsersQuery } from 'service/users';
import { kFormatter } from 'utils/numberFormatter';
import { useQueryParam } from 'hooks/useQueryParam';

import { ReactComponent as IconVIP } from 'assets/icons/vip.svg';

import { VIPTable } from 'components/Settings/VIPTable';
import { AddVIPForm } from 'components/Settings/AddVIPForm';
import { ActionHeader } from 'components/Headers';

import { AllUsersTable } from './AllUsersTable';
import { AddUserRoles } from './AddInternalUserRoles';

export function InternalUsers() {
  const [activeTab, setActiveTab] = useState(ALL_ACCOUNTS);

  const [searchQuery] = useQueryParam({ key: 'q' });

  const tabs = [
    {
      id: ALL_ACCOUNTS,
      name: 'All Accounts',
    },
    {
      id: VIP_USERS,
      name: 'VIP Users',
    },
  ];

  const [OrgID] = useOrgId();

  const [tableFilters, setTableFilters] = useState({
    limit: 10,
    offset: 0,
    searchQuery: '',
  });

  useEffect(() => {
    setTableFilters((prevFilters) => ({
      ...prevFilters,
      searchQuery: searchQuery || '',
    }));
  }, [searchQuery]);

  const { data: userData, isLoading } = useGetUsersQuery(
    {
      orgId: OrgID,
      ...tableFilters,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: vips, isLoading: vipLoading } = useGetVIPUsersQuery({
    orgId: OrgID,
    ...tableFilters,
  });

  function getCountForTab(tab: string) {
    switch (tab) {
      case ALL_ACCOUNTS:
        return kFormatter(userData?.total || 0);
      case VIP_USERS:
        return kFormatter(vips?.total || 0);
      case GROUP_USERS:
        return '0';
      default:
        return '0';
    }
  }

  const handleSetTab = (id: string) => {
    setActiveTab(id);
    setTableFilters((prevFilters) => ({
      ...prevFilters,
      offset: 0,
    }));
  };

  const [showVIPForm, setShowRolesForm] = useState(false);

  const handleShowRolesForm = () => {
    setShowRolesForm(true);
  };

  const handleCloseRolesForm = () => {
    setShowRolesForm(false);
  };

  return (
    <Tabs.Root value={activeTab}>
      <Tabs.List className="flex items-center gap-20 border-b border-border-primary">
        {tabs.map((tab) => (
          <Tabs.Trigger
            key={tab.id}
            value={tab.id}
            className="text-light-grey  data-[state=active]:text-black text-sm flex items-center justify-start"
            onClick={() => handleSetTab(tab.id)}
          >
            <div
              className={`flex items-center gap-2 py-3 px-1 ${activeTab === tab.id ? 'border-black border-b-2' : ''}`}
            >
              <span>{tab.name}</span>
              <Badge
                text={getCountForTab(tab.id)}
                bgColor={activeTab === tab.id ? 'black' : 'primary-background'}
                borderColor={activeTab === tab.id ? 'white' : 'light-grey'}
                textColor={activeTab === tab.id ? 'white' : 'light-grey'}
              />
            </div>
          </Tabs.Trigger>
        ))}
      </Tabs.List>
      <div className="my-8">
        <Tabs.Content
          id={ALL_ACCOUNTS}
          key={ALL_ACCOUNTS}
          value={ALL_ACCOUNTS}
          className="flex flex-col gap-4"
        >
          <ActionHeader
            title="User Roles Management"
            description="Assign roles such as VIP, Finance & Accounting to users to improve your organisation’s security posture"
            logoSvg={<IconVIP />}
            actionComponent={<AddUserRoles onClose={handleCloseRolesForm} defaultRole="vip" />}
            actionComponentOpen={showVIPForm}
            onActionClick={handleShowRolesForm}
            onClose={handleCloseRolesForm}
            actionDescription="Assign Roles"
            width="w-1/4"
          />

          <AllUsersTable
            users={userData?.hits || []}
            total={userData?.total || 0}
            isLoading={isLoading}
            filters={tableFilters}
            setFilters={setTableFilters}
          />
        </Tabs.Content>
        <Tabs.Content
          id={VIP_USERS}
          key={VIP_USERS}
          value={VIP_USERS}
          className="flex flex-col gap-4"
        >
          <ActionHeader
            title="VIP Management"
            description="Add & assign VIP roles to users to improve your organisation’s security posture"
            logoSvg={<IconVIP />}
            actionComponent={<AddVIPForm onClose={handleCloseRolesForm} />}
            actionComponentOpen={showVIPForm}
            onActionClick={handleShowRolesForm}
            onClose={handleCloseRolesForm}
            actionDescription="Add VIP"
            width="w-1/4"
          />
          <VIPTable
            vips={vips?.hits || []}
            isLoading={vipLoading}
            filters={tableFilters}
            setFilters={setTableFilters}
            total={vips?.total || 0}
          />
        </Tabs.Content>
      </div>
    </Tabs.Root>
  );
}
