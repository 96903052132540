import { Contact } from 'models/domains';
import { ContactItem } from './ContactItem';

interface ContactsProps {
  title: string;
  contacts: Contact[];
}

export function Contacts({ title, contacts }: ContactsProps) {
  return (
    <div>
      <div className="border-b border-border-primary mt-8">
        <div className="px-1 py-3 w-fit text-sm">{title}</div>
      </div>
      <div className="mt-6">
        {contacts.length > 0 ? (
          <div className="flex flex-col gap-4">
            {contacts?.map((contact) => (
              <ContactItem
                name={contact.name}
                email={contact.email}
                avatarBgColor="warning-yellow"
              />
            ))}
          </div>
        ) : (
          <span className="text-sm text-light-grey">No contacts found</span>
        )}
      </div>
    </div>
  );
}
