import { Badge } from 'components/Badge';
import Card from 'components/Card';
import { CardContent } from 'components/Card/CardContent';
import CardHeader from 'components/Card/CardHeader';

import { ReactComponent as WarningSignalIcon } from 'assets/icons/icon-warning-signal.svg';
import { ReactComponent as DangerSignalIcon } from 'assets/icons/icon-danger-signal.svg';
import { ReactComponent as UserIcon } from 'assets/icons/icon-user-black.svg';
import { ReactComponent as PlaneIcon } from 'assets/icons/icon-plane.svg';
import { ReactComponent as WorldIcon } from 'assets/icons/icon-internet-error.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/icon-location-error.svg';
import { ReactComponent as Dollar } from 'assets/icons/dollar.svg';
import { ReactComponent as Spy } from 'assets/icons/icon-spy-black.svg';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';

import { Metric } from 'models/metric';
import { kFormatter } from 'utils/numberFormatter';

export type ComingSoonItem = {
  icon: string;
  title: string;
};

interface EmailVectorsProp {
  title?: string;
  vectors: Metric[];
  total: number;
  comingSoon?: ComingSoonItem[];
}

interface MetricDetails {
  name: string;
  icon: React.ReactNode;
}

function getMetricDetails(vector: Metric) {
  const vectorsDetails: Record<string, MetricDetails> = {
    sender: {
      name: 'Sender',
      icon: <PlaneIcon className="w-3 h-3" />,
    },
    recipients: {
      name: 'Recipients',
      icon: <UserIcon className="w-3 h-3" />,
    },
    attachments: {
      name: 'Attachments',
      icon: <UserIcon className="w-3 h-3" />,
    },
    links: {
      name: 'URL',
      icon: <UserIcon className="w-3 h-3" />,
    },
    domain_mismatch: {
      name: 'Domain Mismatch',
      icon: <WorldIcon className="w-3 h-3 fill-light-grey" />,
    },
    financial_request_intent: {
      name: 'Financial Request Intent',
      icon: <Dollar className="w-3 h-3 fill-light-grey" />,
    },
    display_name_vip: {
      name: 'Display Name VIP',
      icon: <Spy className="w-3 h-3 fill-light-grey" />,
    },
    suspicious_domain: {
      name: 'Suspicious Domain',
      icon: <WorldIcon className="w-3 h-3 fill-light-grey" />,
    },
    location_anomaly: {
      name: 'Location Anomaly',
      icon: <LocationIcon className="w-3 h-3 fill-light-grey" />,
    },
    first_time_sender: {
      name: 'First Time Sender',
      icon: <Spy className="w-3 h-3 fill-light-grey" />,
    },
    frequent_sender: {
      name: 'Frequent Sender',
      icon: <UserIcon className="w-3 h-3" />,
    },
    request_for_action_intent: {
      name: 'Action Required',
      icon: <UserIcon className="w-3 h-3" />,
    },
    sense_of_urgency_tone: {
      name: 'Urgency Detected',
      icon: <UserIcon className="w-3 h-3" />,
    },
    regulator_domain_lookalike: {
      name: 'Regulator Domain Lookalike',
      icon: <UserIcon className="w-3 h-3" />,
    },
    vendor_domain_lookalike: {
      name: 'Vendor Domain Lookalike',
      icon: <UserIcon className="w-3 h-3" />,
    },
    is_recipient_finance_team: {
      name: 'Finance Team',
      icon: <UserIcon className="w-3 h-3" />,
    },
    recurring_invoice: {
      name: 'Recurring Invoice',
      icon: <UserIcon className="w-3 h-3" />,
    },
    malicious_url: {
      name: 'Malicious URL',
      icon: <UserIcon className="w-3 h-3" />,
    },
    malicious_attachment: {
      name: 'Malicious Attachment',
      icon: <UserIcon className="w-3 h-3" />,
    },
  };

  return (
    vectorsDetails[vector.code] || {
      name: vector.code,
      icon: <UserIcon className="w-3 h-3" />,
    }
  );
}

export function EmailVectors({ title, vectors = [], total, comingSoon = [] }: EmailVectorsProp) {
  function getSignal(signal: string) {
    if (signal === 'danger') {
      return <DangerSignalIcon className="w-3.5 h-3.5" />;
    }
    return <WarningSignalIcon className="w-3.5 h-3.5" />;
  }

  return (
    <div className="w-1/4">
      <Card width="100%">
        <CardHeader>
          <div className="flex items-center justify-between">
            <span className="text-xs">{title}</span>
            <Badge text={kFormatter(total)} bgColor="black" textColor="white" borderColor="black" />
          </div>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col gap-8">
            {vectors?.map((vector) => {
              const vectorDetails = getMetricDetails(vector);
              return (
                <div key={vector.code} className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Warning className="w-3 h-3 fill-light-grey" />
                    {/* removing the icon for now until we find a better way to do
                    it */}
                    <span className="text-black text-xs">{vectorDetails.name}</span>
                  </div>
                  <div className="flex items-center gap-4">
                    {getSignal('warning')}
                    <span className="text-xs">{vector.count}</span>
                  </div>
                </div>
              );
            })}
          </div>
          {comingSoon?.length > 0 && <div className="border-border-primary border-b-light my-6" />}
          <div className="flex flex-col gap-8">
            {comingSoon.map((comingSoonItem) => (
              <div key={comingSoonItem.title} className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <img src={comingSoonItem.icon} className="w-3.5 h-3.5" alt="metric-icon" />
                  <span className="text-black text-xs">{comingSoonItem.title}</span>
                </div>
                <div className="flex items-center gap-4">
                  <span className="text-xs text-light-grey">Coming Soon</span>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
