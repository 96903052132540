import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ONBOARD_ORGANIZATION,
  ONBOARDING_STATUS,
  VALIDATE_ORGANIZATION_CODE,
} from 'constants/apiUrls';
import { IDP } from 'enums/idp';
import { OnboardingStatusTypes } from 'enums/onboardingStatusTypes';
import { baseQuery } from './baseQuery';

// Define the response type for the token exchange
interface OnboardingStatusResponse {
  onboardingStatus: OnboardingStatusTypes;
  name: string;
  remoteId: string;
  adminConsentUrl: string;
}

export interface OrgCodeValidationResponse {
  idpHint?: IDP;
}

// Create the API slice
export const onboardingApi = createApi({
  reducerPath: 'onboardingApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    onboardingStatus: builder.query<OnboardingStatusResponse, { orgId: string }>({
      query: ({ orgId }) => {
        return {
          url: `${ONBOARDING_STATUS(orgId)}`,
          method: 'GET',
        };
      },
    }),
    onboardOrg: builder.query<undefined, { orgId: string }>({
      query: ({ orgId }) => {
        return {
          url: `${ONBOARD_ORGANIZATION(orgId)}`,
          method: 'POST',
          body: {},
        };
      },
    }),
    validateOrgCode: builder.query<OrgCodeValidationResponse, { code: string }>({
      query: ({ code }) => {
        return {
          url: `${VALIDATE_ORGANIZATION_CODE(code)}`,
          method: 'GET',
        };
      },
    }),
  }),
});

// Export the generated hook
export const { useLazyOnboardingStatusQuery, useLazyOnboardOrgQuery, useLazyValidateOrgCodeQuery } =
  onboardingApi;
