import Avatar from 'components/Avatar';
import { getInitials } from 'utils/name';

import { AddUser } from './UserListItem';

interface ActionToastProps {
  users: AddUser[];
  onUndo?: () => void;
  showUndo?: boolean;
  roleType: string;
  action?: 'add' | 'remove';
}

export function ActionToast({
  users,
  onUndo,
  showUndo = true,
  roleType,
  action = 'add',
}: ActionToastProps) {
  const getDescription = () => {
    if (users.length === 1) {
      return `"${users[0].name}" has been ${action === 'add' ? 'added to' : 'removed from'} the ${roleType} list`;
    }
    return `${users.length} users have been ${action === 'add' ? 'added to' : 'removed from'} the ${roleType} list`;
  };

  return (
    <div className="bg-white w-full rounded-lg border-light border-border-primary p-4 shadow-md flex items-center justify-between">
      <div className="flex items-center gap-2">
        {users.length === 1 ? (
          <Avatar
            initials={getInitials(users[0].name, 2)}
            bgColor="avatar-blue"
            textColor="white"
            customCssClass="w-6 h-6 p-1 text-[0.5rem]"
          />
        ) : null}
        <span className="text-sm">{getDescription()}</span>
      </div>
      {showUndo && onUndo && (
        <button
          type="button"
          className="bg-light-grey text-white px-3 py-1 rounded-lg text-xs"
          onClick={() => onUndo()}
        >
          Undo
        </button>
      )}
    </div>
  );
}
