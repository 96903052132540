// import { useAuth } from 'contexts/AuthContext';
import { useAuth } from 'contexts/AuthContext';
import { useRealm } from 'hooks/useRealm';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useLocation, useNavigate } from 'react-router-dom';
import KeycloakService from 'service/keycloak';
import Logo from 'assets/logo/logo-with-name.png';
import * as Form from '@radix-ui/react-form';
import { OrgCodeValidationResponse, useLazyValidateOrgCodeQuery } from 'service/onboardingApi';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

function Login(): JSX.Element {
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [realm, setRealm] = useRealm();
  const navigate = useNavigate();
  const { authenticated } = useAuth();
  const [validateOnboarding] = useLazyValidateOrgCodeQuery();

  useEffect(() => {
    if (authenticated) {
      navigate('/', { replace: true });
    }
  }, [authenticated, navigate]);

  const handleLogin = async () => {
    setError('');
    setIsLoading(true);
    let result: OrgCodeValidationResponse;
    try {
      // call backend to validate the org code
      result = await validateOnboarding({ code: realm }).unwrap();
    } catch (err) {
      const apiError = err as FetchBaseQueryError;
      const data = apiError.data as string;
      setError(data || '');
      setIsLoading(false);
      return;
    }

    try {
      await KeycloakService.login(realm, result.idpHint || 'microsoft');
      if (authenticated) {
        navigate('/', { replace: true });
      }
    } catch {
      setError('Failed to initiate login. Please try again.');
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (realm !== '' && !isLoading) {
      await handleLogin();
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRealm(event.target.value);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 bg-login">
      <div className="border-[#E1E5E8] bg-white rounded-2xl min-w-96 shadow-[0rem_.25rem_1.5rem_0rem_rgba(0,0,0,0.15)]">
        <div className="text-center p-9 divide-y">
          <img className="h-9 inline-block" src={Logo} alt="RavenMail" />
        </div>
        <hr className="border-[#E1E5E8]" />
        <Form.Root className="p-16 text-center" onSubmit={handleSubmit}>
          <Form.Field name="organization" className="flex flex-col justify-center">
            <Form.Label className="font-medium">
              Enter your Organization Code to continue
            </Form.Label>
            <Form.Control asChild>
              <input
                type="text"
                id="org_name"
                className="w-80 h-12 m-auto mt-8 mb-16 bg-slate-100 text-center rounded-lg font-light placeholder-gray-400 border border-slate-200"
                placeholder="MyOrg"
                onChange={handleInputChange}
                value={realm}
                required
              />
            </Form.Control>
            <p className="text-center text-sm text-red-600 min-h-8">{error}</p>
            <Form.Submit asChild>
              <button
                type="button"
                onClick={handleLogin}
                disabled={isLoading || realm.length === 0}
                className="bg-slate-900 text-white text-lg rounded-md w-80 h-14 disabled:bg-slate-700"
              >
                {isLoading ? (
                  <div className="flex space-x-2 justify-center items-center h-1">
                    <div className="h-2 w-2 bg-white rounded-full animate-ping [animation-delay:-0.3s]" />
                    <div className="h-2 w-2 bg-white rounded-full animate-ping [animation-delay:-0.15s]" />
                    <div className="h-2 w-2 bg-white rounded-full animate-ping" />
                  </div>
                ) : (
                  'Continue'
                )}
              </button>
            </Form.Submit>
          </Form.Field>
        </Form.Root>
      </div>
    </div>
  );
}

export default Login;
