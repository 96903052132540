import dayjs, { ManipulateType } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// Extend dayjs with plugins
dayjs.extend(customParseFormat);

export const dateUtils = {
  /**
   * Parse a date string to a dayjs object
   * @param dateString The date string to parse
   * @param format The format of the date string (optional)
   * @returns A dayjs object
   */
  format: (dateString: string, format?: string) => {
    const date = dayjs(dateString);

    if (format) {
      return date.format(format);
    }

    return date;
  },
  subtract: (dateString: string, duration: number, unit: ManipulateType = 'd') => {
    const date = dayjs(dateString);
    return date.subtract(duration, unit);
  },
  add: (dateString: string, duration: number, unit: ManipulateType = 'd') => {
    const date = dayjs(dateString);
    return date.add(duration, unit);
  },
};
