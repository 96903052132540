import { useState, useEffect } from 'react';

import { DomainDetails } from 'models/domains';
import * as Dialog from '@radix-ui/react-dialog';
import Loader from 'components/Loader';
import * as Checkbox from '@radix-ui/react-checkbox';
import { ReactComponent as IconCheck } from 'assets/icons/check.svg';
import { ReactComponent as IconStar } from 'assets/icons/icon-star-vendor.svg';
import { ReactComponent as IconVendor } from 'assets/icons/vendor.svg';
import Avatar from 'components/Avatar';
import { getInitials } from 'utils/name';
import { useOrgId } from 'hooks/useOrgId';
import { useToast } from 'hooks/toast';
import { WarningToast } from 'components/Toasts/WarningToast';
import { useAddVendorsMutation } from 'service/externalUsers';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { ActionToast } from 'components/Toasts/ActionToast';

interface DomainDetailsWithCheck extends DomainDetails {
  checked: boolean;
}

interface SuggestedDomainProps {
  open: boolean;
  suggestedDomains: DomainDetailsWithCheck[];
  loading: boolean;
  onFetchMore: () => void;
  onClose: () => void;
  total: number;
  next: boolean; // for pagination whether to fetch more
}

export function SuggestedDomains({
  open,
  suggestedDomains,
  loading,
  onFetchMore,
  onClose,
  total,
  next,
}: SuggestedDomainProps) {
  const { showToast } = useToast();

  const [domainList, setDomainList] = useState<DomainDetailsWithCheck[]>([]);

  useEffect(() => {
    // checking if the first suggested domain is already in the list
    // if not, add it
    if (suggestedDomains) {
      setDomainList((prev) => {
        if (suggestedDomains.length && !prev.find((item) => item.id === suggestedDomains[0].id)) {
          return [...prev, ...suggestedDomains];
        }
        return prev;
      });
    }
  }, [suggestedDomains]);

  const handleCheckAll = (state: Checkbox.CheckedState) => {
    if (state) {
      setDomainList((prev) => {
        return prev.map((item) => ({ ...item, checked: true }));
      });
    } else {
      setDomainList((prev) => {
        return prev.map((item) => ({ ...item, checked: false }));
      });
    }
  };

  const [OrgId] = useOrgId();

  const [addVendors] = useAddVendorsMutation();

  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleConfirm = async () => {
    const domainIds = domainList.filter((item) => item.checked).map((item) => item.id);

    if (domainIds.length === 0) {
      showToast({
        component: <WarningToast message="Please select at least one suggested domain" />,
      });
      return;
    }

    setConfirmLoading(true);
    try {
      await addVendors({
        orgId: OrgId,
        domainIds,
      }).unwrap();

      showToast({
        component: (
          <ActionToast
            description={`Added ${domainIds.length} ${domainIds.length > 1 ? 'vendors' : 'vendor'} successfully`}
            showAvatar={false}
            showUndo={false}
          />
        ),
      });

      setDomainList([]);

      onClose();
    } catch {
      showToast({
        component: <ErrorToast message="Error while adding vendors. Please try again later" />,
      });
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleCheck = (state: Checkbox.CheckedState, id: string) => {
    if (state) {
      setDomainList((prev) => {
        return prev.map((item) => {
          if (item.id === id) {
            return { ...item, checked: true };
          }
          return item;
        });
      });
    } else {
      setDomainList((prev) => {
        return prev.map((item) => {
          if (item.id === id) {
            return { ...item, checked: false };
          }
          return item;
        });
      });
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50" onClick={onClose} />
        <Dialog.Content
          className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg w-1/2"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="w-full max-h-overview-modal bg-white rounded-lg">
            <div className="border-b-light border-b-border-primary px-8 py-6">
              <span className="text-base">External Role Mapping</span>
            </div>
            <div className="border-b border-b-border-primary px-8 py-6 text-white">
              <div className="px-5 py-2 bg-warning-yellow rounded-lg flex items-center gap-2">
                <IconStar />
                <span>{`${total} Vendors accounts found`}</span>
              </div>
            </div>
            <div className="px-4 overflow-auto h-72">
              <table className="w-full">
                <thead className="text-xs text-light-grey w-full border-b-light border-b-border-primary sticky top-0 z-10 bg-white">
                  <tr>
                    <th className="p-4 text-start font-normal border-b-light border-b-border-primary">
                      <div className="flex items-center gap-2">
                        <Checkbox.Root
                          id="select-all"
                          className="w-3.5 h-3.5 border border-light-grey data-[state=checked]:border-black data-[state=checked]:bg-black rounded text-center flex justify-center items-center p-1"
                          checked={domainList.every((item) => item.checked)}
                          onCheckedChange={(check) => handleCheckAll(check)}
                        >
                          <Checkbox.CheckboxIndicator>
                            <IconCheck />
                          </Checkbox.CheckboxIndicator>
                        </Checkbox.Root>
                        <span>Name</span>
                      </div>
                    </th>
                    <th className="p-4 text-start font-normal border-b-light border-b-border-primary">
                      Domain
                    </th>
                    <th className="p-4 text-start font-normal border-b-light border-b-border-primary">
                      Contacts
                    </th>
                    <th className="p-4 text-start font-normal border-b-light border-b-border-primary">
                      Role
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {domainList.map((domain) => (
                    <tr
                      key={domain.id}
                      className="align-middle cursor-pointer w-full"
                      onClick={() => handleCheck(!domain.checked, domain.id)}
                    >
                      <td className="my-1 p-4 text-xs rounded-l-lg border-b-light border-b-border-primary">
                        <div className="flex items-center gap-2">
                          <Checkbox.Root
                            id={domain.id}
                            className="w-3.5 h-3.5 border border-light-grey data-[state=checked]:bg-light-grey rounded text-center flex justify-center items-center p-1"
                            checked={domain.checked}
                            onCheckedChange={(checked) => handleCheck(checked, domain.id)}
                          >
                            <Checkbox.CheckboxIndicator>
                              <IconCheck />
                            </Checkbox.CheckboxIndicator>
                          </Checkbox.Root>
                          <Avatar
                            initials={getInitials(domain.domain, 1)}
                            bgColor="avatar-blue"
                            textColor="white"
                            customCssClass="w-6 h-6 text-xs"
                          />
                          <span>{domain.domain}</span>
                        </div>
                      </td>
                      <td className="my-1 p-4 text-xs border-b-light border-b-border-primary">
                        {domain.domain}
                      </td>
                      <td className="my-1 p-4 text-xs border-b-light border-b-border-primary">
                        {domain.noOfContacts}
                      </td>
                      <td className="my-1 p-4 text-xs rounded-r-lg border-b-light border-b-border-primary">
                        <button
                          className="border-light border-border-primary p-2 rounded-lg flex justify-center gap-2"
                          type="button"
                        >
                          <IconVendor />
                          <span>Vendor</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {!loading && next ? (
                <button
                  className="flex items-center justify-center w-full p-2 text-blue-400 text-2xs"
                  type="submit"
                  onClick={() => onFetchMore()}
                >
                  See More
                </button>
              ) : null}
              {loading ? (
                <div className="flex justify-center items-center p-2">
                  <Loader customCss="w-6 h-6 border-2" />
                </div>
              ) : null}
            </div>
            <div className="flex justify-evenly items-center p-8 gap-4">
              <div className="text-sm">
                <span className="px-2.5 py-1 bg-select-background text rounded-lg">
                  {domainList.filter((item) => item.checked).length}
                </span>{' '}
                accounts selected
              </div>
              <button
                type="button"
                className="bg-white border-light-grey border-light text-light-grey rounded-full p-3.5 flex-1 text-xs"
                onClick={() => {
                  setDomainList([]);
                  onClose();
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="bg-black text-white rounded-full p-3.5 flex-1 text-xs"
                onClick={() => {
                  if (!loading) handleConfirm();
                }}
              >
                {confirmLoading ? <Loader color="white" customCss="w-4 h-4 border-2" /> : 'Confirm'}
              </button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
