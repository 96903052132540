import * as Select from '@radix-ui/react-select';

import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';
import LegendMarker from 'components/LegendMarker';
import {
  CartesianGrid,
  Line,
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { dateUtils } from 'utils/time';

import { useOrgId } from 'hooks/useOrgId';
import { useState } from 'react';
import { useGetThreatFrequencyQuery } from 'service/threatFrequency';
import { FrequencyGraphToolTip } from 'components/FrequencyGraph/FrequencyGraphToolTip';
import {
  ThreatFrequencyResponse,
  ThreatFrequencyFilters,
  ThreatType,
} from 'models/threatInferences';
import { TransformToTimeseries } from 'components/FrequencyGraph/transformations';

interface ThreatFrequencyProps {
  tab: 'Impersonation' | 'Malware';
}

export function ThreatsFrequency({ tab }: ThreatFrequencyProps) {
  const timePeriodSelectOptions = [
    { value: '7', label: '7 days ago' },
    { value: '14', label: '14 days ago' },
    { value: '30', label: '30 days ago' },
    { value: '60', label: '60 days ago' },
  ];

  const [OrgId] = useOrgId();

  const [filters, setFilters] = useState<ThreatFrequencyFilters>({
    orgId: OrgId,
    from: dateUtils.subtract(new Date().toDateString(), 29, 'd').toDate().toISOString(),
    to: new Date().toISOString(),
    frequency: 'daily',
    threat:
      tab === 'Impersonation'
        ? `${ThreatType.VIPImpersonation},${ThreatType.VENDORImpersonation},${ThreatType.REGULATORImpersonation}`
        : ThreatType.MALWARE,
  });

  const { data, isLoading } = useGetThreatFrequencyQuery(filters, {
    refetchOnMountOrArgChange: true,
  });

  function getImpersonationDataByThreat(
    threatName: ThreatType,
    threatData: ThreatFrequencyResponse | undefined
  ) {
    if (!threatData) return [];
    return (
      threatData?.threats?.find((threat) => threat.code?.toUpperCase() === threatName)?.frequency ||
      []
    );
  }

  // handleSelect is used to update the filters when the time period is changed
  // it subtracts the time period from the current date and updates the filters
  const handleSelect = (value: string) => {
    const days = parseInt(value, 10);
    setFilters({
      ...filters,
      from: dateUtils
        .subtract(new Date().toDateString(), days - 1, 'd')
        .toDate()
        .toISOString(),
      to: new Date().toISOString(),
    });
  };

  const getTransformedDataByThreat = (threat: ThreatType) => {
    return TransformToTimeseries(
      filters.from || '',
      filters.to || '',
      (data && getImpersonationDataByThreat(threat, data)) || []
    );
  };

  const getImpersonationData = () => {
    if (isLoading) return [];

    if (tab === 'Impersonation') {
      const vipImpersonationData = getTransformedDataByThreat(ThreatType.VIPImpersonation);
      const vendorImpersonationData = getTransformedDataByThreat(ThreatType.VENDORImpersonation);
      const regulatorImpersonationData = getTransformedDataByThreat(
        ThreatType.REGULATORImpersonation
      );

      const allFrequencyData = [];

      for (let i = 0; i < vipImpersonationData.length; i += 1) {
        const eachFrequency = {
          date: vipImpersonationData[i].date,
          vipImpersonation: vipImpersonationData[i].count,
          vendorImpersonation: vendorImpersonationData[i].count,
          regulatorImpersonation: regulatorImpersonationData[i].count,
        };

        allFrequencyData.push(eachFrequency);
      }

      return allFrequencyData;
    }
    return getTransformedDataByThreat(ThreatType.MALWARE);
  };

  const graphLabels = {
    Impersonation: [
      {
        label: 'VIP Impersonation',
        value: 'vipImpersonation',
        markerColor: 'light-blue',
      },
      {
        label: 'Vendor Impersonation',
        value: 'vendorImpersonation',
        markerColor: 'light-green',
      },
      {
        label: 'Regulator Impersonation',
        value: 'regulatorImpersonation',
        markerColor: 'light-pink',
      },
    ],
    Malware: [
      {
        label: 'Malware',
        value: 'count',
        markerColor: 'light-blue',
      },
    ],
  };

  return (
    <div className="flex-1 flex">
      <div className="bg-white rounded-lg border-light border-primary-border w-full">
        <div className="py-3.5 px-8 border-b-light border-border-primary">
          <div className="flex items-center justify-between text-xs">
            <span>Impersonation Frequency</span>
            <Select.Root defaultValue="30" onValueChange={handleSelect}>
              <Select.Trigger className="border-light border-border-primary p-2 rounded-md text-xs text-light-grey flex items-center gap-4">
                <Select.Value />
                <Select.Icon>
                  <IconArrowDown className="w-4 h-4" />
                </Select.Icon>
              </Select.Trigger>
              <Select.Portal>
                <Select.Content className="bg-white border border-gray-300 rounded-md shadow-lg text-xs text-light-grey p-2">
                  <Select.Viewport>
                    {timePeriodSelectOptions.map((option) => (
                      <Select.Item
                        key={option.value}
                        value={option.value}
                        className="p-2 cursor-pointer hover:bg-gray-100 text-light-grey data-[highlighted]:text-black data-[highlighted]:bg-select-background data-[highlighted]:outline-none rounded-sm"
                      >
                        <Select.ItemText>{option.label}</Select.ItemText>
                      </Select.Item>
                    ))}
                  </Select.Viewport>
                </Select.Content>
              </Select.Portal>
            </Select.Root>
          </div>
        </div>
        <div className="flex items-center w-full gap-8 px-8 py-4 border-b-light border-primary-border">
          {graphLabels[tab].map((eachLegend) => (
            <LegendMarker text={eachLegend.label} markerColor={eachLegend.markerColor} />
          ))}
        </div>
        <div className="w-full bg-white py-3.5 px-8 rounded-b-lg">
          <ResponsiveContainer width="100%" height={240}>
            <LineChart
              accessibilityLayer
              margin={{
                left: 20,
                right: 20,
              }}
              data={getImpersonationData()}
            >
              <XAxis
                dataKey="date"
                className="text-xs"
                color="#E1E5E8"
                axisLine={false}
                tickLine={false}
                tick={{
                  fill: '#9FAFB5',
                  fontSize: '10px',
                }}
                tickMargin={12}
                tickFormatter={(value) => {
                  const dateText = value as string;
                  return dateUtils.format(dateText, 'MMM DD').toString();
                }}
                scale="point"
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                orientation="right"
                tick={{
                  fill: '#9FAFB5',
                  fontSize: '10px',
                }}
                tickMargin={16}
                tickCount={3}
                domain={[0, (dataMax: number) => Math.ceil(dataMax * 1.2)]}
              />
              <CartesianGrid
                vertical={false}
                color="#E1E5E8"
                strokeWidth={0.5}
                strokeOpacity={0.5}
                horizontalCoordinatesGenerator={({ height }) => {
                  const count = 10;
                  return Array(count)
                    .fill(0)
                    .map((_, index) => height * (index / count));
                }}
              />
              <Tooltip
                content={<FrequencyGraphToolTip labels={graphLabels[tab]} />}
                cursor={false}
              />
              <Line dataKey="vipImpersonation" stroke="#85D8FF" dot={false} strokeWidth={1} />
              <Line dataKey="vendorImpersonation" stroke="#2CD77A" dot={false} strokeWidth={1} />
              <Line dataKey="regulatorImpersonation" stroke="#FFB6C1" dot={false} strokeWidth={1} />
              <Line dataKey="count" stroke="#85D8FF" dot={false} strokeWidth={1} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}
