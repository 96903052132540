import { ReactComponent as IconArrowUp } from 'assets/icons/arrow-pointer-up.svg';

export type Order = 'asc' | 'desc' | null;

interface SortComponentProps {
  sortOrder: Order;
}

function Sort({ sortOrder }: SortComponentProps) {
  return (
    <div className="flex flex-col items-center justify-center gap-0.5">
      <IconArrowUp
        className={`transform transition-transform ${sortOrder === 'asc' ? 'fill-black' : 'fill-light-grey'} w-3 cursor-pointer`}
      />
      <IconArrowUp
        className={`transform transition-transform ${sortOrder === 'desc' ? 'fill-black' : 'fill-light-grey'} w-3 transform -scale-y-100 cursor-pointer`}
      />
    </div>
  );
}

export default Sort;
