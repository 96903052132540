import Avatar from 'components/Avatar';

interface ActionToastProps {
  avatarBgColor?: string;
  description: string;
  onUndo?: () => void;
  showUndo?: boolean;
  initials?: string;
  showAvatar?: boolean;
}

export function ActionToast({
  avatarBgColor = 'avatar-blue',
  description,
  onUndo,
  showUndo = true,
  initials = 'U',
  showAvatar = true,
}: ActionToastProps) {
  return (
    <div className="bg-white w-full rounded-lg border-light border-border-primary p-4 shadow-md flex items-center justify-between">
      <div className="flex items-center gap-2">
        {showAvatar && (
          <Avatar
            initials={initials}
            bgColor={avatarBgColor}
            textColor="white"
            customCssClass="w-6 h-6 p-1 text-[0.5rem]"
          />
        )}
        <span className="text-sm">{description}</span>
      </div>
      {showUndo && onUndo && (
        <button
          type="button"
          className="bg-light-grey text-white px-3 py-1 rounded-lg text-xs"
          onClick={() => onUndo()}
        >
          Undo
        </button>
      )}
    </div>
  );
}
