import { ReactComponent as IconEnvelope } from 'assets/icons/envelope.svg';
import { ReactComponent as IconChecked } from 'assets/icons/grey-checked.svg';

export function UndoRemediationAlert() {
  return (
    <div className="flex items-center justify-between gap-6 w-full p-6">
      <div className="flex items-center gap-3">
        <div className="w-6 h-6 bg-primary-background flex justify-center items-center rounded">
          <IconEnvelope />
        </div>
        <span className="text-xs">Push back Mail to Inbox</span>
      </div>
      <IconChecked className="w-4 h-4" />
    </div>
  );
}
