interface LoaderProps {
  customCss?: string;
  color?: string;
}

export default function Loader({ customCss = 'w-6 h-6 border-2', color = 'black' }: LoaderProps) {
  return (
    <div className="flex items-center justify-center h-full w-full">
      <div
        className={`${customCss} border-${color} rounded-full border-t-transparent animate-spin`}
      />
    </div>
  );
}
