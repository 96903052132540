import { configureStore } from '@reduxjs/toolkit';
import { emailVectorFrequencyApi } from 'service/emailVectorFrequency';
import { eventsApi } from 'service/eventsApi';
import { statisticsApi } from 'service/statisticsApi';
import { topDetectionsApi } from 'service/topDetections';
import { threatInferencesApi } from 'service/threatInferences';
import { threatFrequencyApi } from 'service/threatFrequency';
import { usersApi } from 'service/users';
import { vectorsApi } from 'service/vectors';
import { profileOverviewApi } from 'service/profileOverviewApi';
import { onboardingApi } from 'service/onboardingApi';
import { externalUsersApi } from 'service/externalUsers';
import { malwareDetectionsApi } from 'service/malwareDetections';

import userReducer from './slices/users';

export const store = configureStore({
  reducer: {
    [onboardingApi.reducerPath]: onboardingApi.reducer,
    [eventsApi.reducerPath]: eventsApi.reducer,
    [statisticsApi.reducerPath]: statisticsApi.reducer,
    [topDetectionsApi.reducerPath]: topDetectionsApi.reducer,
    [vectorsApi.reducerPath]: vectorsApi.reducer,
    [emailVectorFrequencyApi.reducerPath]: emailVectorFrequencyApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [profileOverviewApi.reducerPath]: profileOverviewApi.reducer,
    [threatInferencesApi.reducerPath]: threatInferencesApi.reducer,
    [threatFrequencyApi.reducerPath]: threatFrequencyApi.reducer,
    [externalUsersApi.reducerPath]: externalUsersApi.reducer,
    [malwareDetectionsApi.reducerPath]: malwareDetectionsApi.reducer,
    users: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      onboardingApi.middleware,
      eventsApi.middleware,
      statisticsApi.middleware,
      topDetectionsApi.middleware,
      vectorsApi.middleware,
      emailVectorFrequencyApi.middleware,
      usersApi.middleware,
      profileOverviewApi.middleware,
      threatInferencesApi.middleware,
      threatFrequencyApi.middleware,
      externalUsersApi.middleware,
      malwareDetectionsApi.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
