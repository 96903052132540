import { EventStatus } from 'enums/eventStatusTypes';

// getBadgeColor returns the badge color based on the severity of the event
// range of severity: 0 - 100
// >= 85 : critical-red
// < 85 :
export function getBadgeColor(severity: number) {
  if (severity >= 85) {
    return 'critical-red';
  }
  return 'warning-yellow';
}

export function getStatusColors(status: EventStatus) {
  if (status === 'Pending') {
    return 'critical-red';
  }
  return 'success-green';
}
