export interface User {
  id: string;
  name: string;
  email: string;
  vip: boolean;
  financeAndAccounting: boolean;
  admin?: boolean;
}

export interface UserFromAPI {
  id: string;
  displayName: string;
  email: string;
  vip: boolean;
  financeAndAccounting: boolean;
  admin?: boolean;
}

export interface GetUsersFilter {
  limit: number;
  offset: number;
  searchQuery: string;
}

export interface GetUsers {
  hits: User[];
  total: number;
  limit: number;
  offset: number;
}

export interface GetUsersAPI {
  hits: UserFromAPI[];
  total: number;
  limit: number;
  offset: number;
}

export interface VIPResponse {
  status: string;
  message: string;
}

export interface FinanceAccResponse {
  status: string;
  message: string;
}

export const mapUser = (user: UserFromAPI): User => ({
  id: user.id,
  name: user.displayName,
  email: user.email,
  vip: user.vip,
  financeAndAccounting: user.financeAndAccounting,
  admin: user.admin,
});

export interface ExternalUser {
  id: number;
  name: string;
  email: string;
  isVendor: boolean;
  isRegulator: boolean;
}

export interface ExternalUserFilter {
  limit: number;
  offset: number;
  searchQuery: string;
  group?: string;
}
