import Avatar from 'components/Avatar';
import { getInitials } from 'utils/name';
import { ReactComponent as IconStar } from 'assets/icons/star.svg';
import { useState } from 'react';
import { User } from 'models/user';

export interface AddUser extends User {
  added?: boolean;
}

interface UserListItemProps extends AddUser {
  onAdd: () => void;
}

export function UserListItem({
  name,
  vip,
  added,
  onAdd,
}: AddUser & UserListItemProps): JSX.Element {
  const [showAddButton, setShowAddButton] = useState(false);

  const showActionButton = () => {
    if (added) {
      return (
        <button
          type="button"
          className="bg-light-grey text-white px-3 py-1 rounded-lg text-xs"
          onClick={() => onAdd()}
        >
          Added
        </button>
      );
    }

    if (showAddButton) {
      return (
        <button
          type="button"
          className="bg-avatar-blue text-white px-3 py-1 rounded-lg text-xs"
          onMouseLeave={() => setShowAddButton(false)}
          onClick={() => onAdd()}
        >
          Add
        </button>
      );
    }

    return (
      <button
        type="button"
        className="w-6 h-6 justify-center items-center text-sm border border-border-primary text-light-grey rounded-lg"
        onMouseEnter={() => setShowAddButton(true)}
      >
        +
      </button>
    );
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        <Avatar
          initials={getInitials(name, 2)}
          bgColor="avatar-blue"
          textColor="white"
          customCssClass="w-6 h-6 p-1 text-[0.5rem]"
        />
        <span className="text-xs">{name}</span>
        {vip && <IconStar className="w-3 h-3" />}
      </div>
      <div>{showActionButton()}</div>
    </div>
  );
}
