import { useState } from 'react';

import { NoDataFound } from 'components/Fallback/NoDataFound';
import Loader from 'components/Loader';
import Sort from 'components/Sort';
import { RemediatedEventsFilter, RemediationItem } from 'models/events';
import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';

import { RemediationRow } from './RemediationRow';

interface RemediationHeaderProps {
  name: string;
  isSortable?: boolean;
  isFilterable?: boolean;
  width?: string;
}

interface RemediationTableProps {
  data: RemediationItem[];
  total: number;
  filters: RemediatedEventsFilter;
  setFilters: React.Dispatch<React.SetStateAction<RemediatedEventsFilter>>;
  loading?: boolean;
}

export function RemediationTable({
  total,
  filters,
  setFilters,
  data,
  loading,
}: RemediationTableProps) {
  const remediationHeaderColumns: RemediationHeaderProps[] = [
    {
      name: 'Subject & Content',
      isSortable: false,
      width: 'w-1/12',
    },
    {
      name: 'Sender',
      isSortable: false,
      width: 'w-1/12',
    },
    {
      name: 'Recipients',
      isSortable: false,
      width: 'w-2/12',
    },
    {
      name: 'Threat',
      isFilterable: true,
      width: 'w-2/12',
    },
    {
      name: 'Quarantined By',
      isSortable: false,
      width: 'w-2/12',
    },
    {
      name: 'Email Date',
      isSortable: true,
      width: 'w-1/12',
    },
    {
      name: 'Quarantined Date',
      isSortable: true,
      width: 'w-1/12',
    },
    {
      name: 'Action Taken',
      isSortable: false,
      width: 'w-2/12',
    },
    {
      name: 'Actions',
      isSortable: false,
      width: 'w-1/12',
    },
  ];

  const [sortState, setSortState] = useState<Record<string, 'asc' | 'desc' | null>>({
    'Email Date': null,
    'Quarantined Date': 'desc',
  });

  const handleSort = (name: string) => {
    setSortState((prevSortState) => {
      const newSortState = { ...prevSortState };

      const isQuarantineDate = name === 'Quarantined Date';

      if (isQuarantineDate) {
        newSortState['Email Date'] = null;
      } else {
        newSortState['Quarantined Date'] = null;
      }

      if (prevSortState[name] === 'desc') {
        newSortState[name] = 'asc';
      } else {
        newSortState[name] = 'desc';
      }

      setFilters({
        ...filters,
        sortBy: isQuarantineDate ? 'action_at' : 'time',
        sortOrder: newSortState[name] as 'asc' | 'desc',
      });
      return newSortState;
    });
  };

  const renderContent = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan={remediationHeaderColumns.length}>
            <div className="w-full flex items-center justify-center p-4">
              <Loader />
            </div>
          </td>
        </tr>
      );
    }

    if (data.length === 0) {
      return (
        <tr>
          <td colSpan={remediationHeaderColumns.length}>
            <NoDataFound description="No remediation action found" />
          </td>
        </tr>
      );
    }

    return (
      <>
        {data.map((remediation) => {
          return (
            <RemediationRow
              key={remediation.id}
              event={remediation.event}
              quarantinedBy={remediation.actionBy}
              quarantinedDate={remediation.createdAt}
              action={remediation.action}
            />
          );
        })}
      </>
    );
  };

  return (
    <div className="w-full overflow-auto no-scrollbar">
      <table className="w-full table-fixed bg-white overflow-auto no-scrollbar rounded-b-lg">
        <colgroup>
          {remediationHeaderColumns.map((header) => (
            <col key={header.name} className={header.width} />
          ))}
        </colgroup>
        <thead className="text-2xs text-light-grey w-full border-b-light border-b-border-primary">
          <tr>
            {remediationHeaderColumns.map((header) => {
              return (
                <th
                  key={header.name}
                  scope="col"
                  className={`${header.width} px-6 py-3 font-normal ${header.name === 'Actions' ? 'text-center' : 'text-start'} ${header.isSortable ? 'cursor-pointer' : ''}`}
                  onClick={() => {
                    if (header.isSortable) {
                      handleSort(header.name);
                    }
                  }}
                >
                  <div className="inline-flex items-center gap-1">
                    <span>{header.name}</span>
                    {header.isSortable && <Sort sortOrder={sortState[header.name]} />}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="w-full">{renderContent()}</tbody>
      </table>
      <div className="bg-white rounded-b-lg">
        {data?.length > 0 && !loading && (
          <div className="flex justify-between px-6 py-4 items-center">
            <span className="text-xs font-light">
              Showing {data?.length > 0 ? filters.offset + 1 : '0'} -{' '}
              {data.length > 0 ? filters.offset + data.length : '0'} of {total}
            </span>
            <div className="flex rounded-full items-center border border-pagination-border cursor-pointer">
              <IconArrowDown
                className={`w-6 h-6 rotate-90 border-t-light border-t-pagination-border ${filters.offset > 0 ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
                onClick={() => {
                  if (data) {
                    if (filters.offset > 0) {
                      setFilters({
                        ...filters,
                        offset: filters.offset - filters.limit,
                      });
                    }
                  }
                }}
              />
              <IconArrowDown
                className={`w-6 h-6 -rotate-90 ${filters.offset + filters.limit < total ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
                onClick={() => {
                  if (filters.offset + filters.limit < total) {
                    setFilters({
                      ...filters,
                      offset: filters.offset + filters.limit,
                    });
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
