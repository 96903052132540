import Avatar from 'components/Avatar';
import Loader from 'components/Loader';
import { SidePanel } from 'components/Sidepanel';
import GridTile from 'components/Tile/GridTile';
import { useOrgId } from 'hooks/useOrgId';
import { Domain } from 'models/domains';
import { useGetDomainOverviewQuery } from 'service/externalUsers';
import { getInitials } from 'utils/name';
import { dateUtils } from 'utils/time';

import { IncidentsList } from './PreviousIncidents';
import { Contacts } from './Contacts';

interface DomainOverviewProps {
  open: boolean;
  onClose: () => void;
  domain: Domain;
  name: string;
}

export function DomainOverview({ domain, open, onClose, name }: DomainOverviewProps) {
  const [OrgId] = useOrgId();

  const { data, isLoading } = useGetDomainOverviewQuery(
    {
      orgId: OrgId,
      domainId: domain.id,
    },
    {
      skip: !open,
    }
  );

  return (
    <SidePanel open={open} onClose={onClose}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="p-10">
            <div className="flex items-center gap-2">
              <Avatar
                initials={getInitials(domain?.name, 2)}
                bgColor="warning-yellow"
                textColor="white"
                customCssClass="w-8 h-8 text-xs"
              />
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <span className="text-base">{name}</span>
                </div>
                <span className="text-xs text-light-grey">{domain?.name}</span>
              </div>
            </div>
          </div>
          <hr className="mb-4" />
          <div className="px-10">
            <GridTile
              details={[
                {
                  title: 'Domain',
                  value: data?.name || '-',
                },
                {
                  title: 'Domain Reputation Score',
                  value: '-',
                },
                {
                  title: 'First seen',
                  value: data?.firstSeen
                    ? dateUtils.format(data.firstSeen, 'DD MMM YYYY').toString()
                    : '-',
                },
                {
                  title: 'Contacts',
                  value: data?.contacts?.length.toString() || '-',
                },
              ]}
            />
            <hr className="my-6" />
            <Contacts title="Contacts" contacts={data?.contacts || []} />
            <IncidentsList
              title="Event History"
              incidents={data?.mailIncidents || []}
              showHighlight={false}
            />
          </div>
        </>
      )}
    </SidePanel>
  );
}
