import { useEffect, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';

interface SidePanelProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export function SidePanel({ open, onClose, children }: SidePanelProps): JSX.Element {
  const [isVisible, setIsVisible] = useState(false);
  const [animationClass, setAnimationClass] = useState('translate-x-full');

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (open) {
      setIsVisible(true);
      // Delay applying the animation class to ensure the initial state is rendered
      timer = setTimeout(() => setAnimationClass('translate-x-0'), 10);
    } else {
      setAnimationClass('translate-x-full');
      timer = setTimeout(() => setIsVisible(false), 500);
    }
    return () => clearTimeout(timer);
  }, [open]);

  return (
    <Dialog.Root open={isVisible} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay
          className={`fixed inset-0 bg-black transition-opacity duration-500 ease-in-out ${
            open ? 'opacity-50' : 'opacity-0'
          }`}
          onClick={onClose}
        />
        <Dialog.Content onClick={(e) => e.stopPropagation()}>
          <div
            className={`fixed top-0 right-0 h-screen w-1/3 bg-white transition-transform duration-500 ease-in-out ${animationClass} overflow-y-scroll no-scrollbar pb-8`}
          >
            {children}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
