import * as Checkbox from '@radix-ui/react-checkbox';
import { ReactComponent as IconCheck } from 'assets/icons/checkbox-icon.svg';
import { ReactComponent as IconOutArrow } from 'assets/icons/out-arrow.svg';
import { ReactComponent as IconQuarantine } from 'assets/icons/push-to-quarantine.svg';

interface EventRemediationProps {
  checkedStateOfActions: {
    logout: boolean;
    quarantine: boolean;
  };
  handleToggle: (action: 'logout' | 'quarantine') => void;
  handleRemediateCheck: (remediateAction: 'junk' | 'quarantine') => void;
  remediationAction: 'junk' | 'quarantine' | '';
}

export function EventRemediation({
  checkedStateOfActions,
  handleToggle,
  handleRemediateCheck,
  remediationAction,
}: EventRemediationProps): JSX.Element {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between gap-4">
        <div className="flex items-center gap-3">
          <IconOutArrow />
          <span className="text-xs">Logout Recepients & Reset Password</span>
        </div>
        <Checkbox.Root
          className="w-3.5 h-3.5 border border-light-grey rounded text-center flex justify-center items-center p-1"
          checked={checkedStateOfActions.logout}
          onClick={() => handleToggle('logout')}
        >
          <Checkbox.Indicator>
            <IconCheck />
          </Checkbox.Indicator>
        </Checkbox.Root>
      </div>
      <div className="flex items-center justify-between gap-4 relative">
        <div className="flex items-center gap-3">
          <IconQuarantine />
          <span className="text-xs">Push Mail to Junk</span>
        </div>
        <Checkbox.Root
          className="w-3.5 h-3.5 border border-light-grey rounded text-center flex justify-center items-center p-1 disabled:cursor-not-allowed"
          checked={checkedStateOfActions.quarantine && remediationAction === 'junk'}
          onClick={() => handleRemediateCheck('junk')}
          disabled={remediationAction === 'quarantine'}
        >
          <Checkbox.Indicator>
            <IconCheck />
          </Checkbox.Indicator>
        </Checkbox.Root>
      </div>
      <div className="flex items-center justify-between gap-4">
        <div className="flex items-center gap-3">
          <IconQuarantine />
          <span className="text-xs">Push Mail to Quarantine</span>
        </div>
        <Checkbox.Root
          className="w-3.5 h-3.5 border border-light-grey rounded text-center flex justify-center items-center p-1 disabled:cursor-not-allowed"
          checked={checkedStateOfActions.quarantine && remediationAction === 'quarantine'}
          onClick={() => handleRemediateCheck('quarantine')}
          disabled={remediationAction === 'junk'}
        >
          <Checkbox.Indicator>
            <IconCheck />
          </Checkbox.Indicator>
        </Checkbox.Root>
      </div>
    </div>
  );
}
