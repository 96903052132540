import { ReactNode } from 'react';

interface TooltipProps {
  children: ReactNode;
  text: string;
}

/*
Tooltip is a component that renders a tooltip with an children and centrally aligned text
It takes in a children and text prop

format of each object is {children: ReactNode, text: string}

children: used to display the icon of the tooltip
text: used to display the text of the tooltip
*/
function Tooltip({ children, text }: TooltipProps) {
  return (
    <div className="group relative inline-block">
      {children}
      <span
        className="absolute left-full top-1/2 transform -translate-y-1/2 ml-2 w-auto p-2 min-w-max rounded-md shadow-md
        text-white bg-sidebar-background text-sm font-medium
        transition-all duration-100 scale-0 origin-left group-hover:scale-100"
      >
        {text}
      </span>
    </div>
  );
}

export default Tooltip;
