import { Event } from 'models/events';
import { getStatusColors } from 'utils/statusColor';

export function EventStatusBadge({ event }: { event: Event }) {
  return (
    <div className="flex gap-1 items-center">
      <span className={`w-2 h-2 rounded-full bg-${getStatusColors(event.status)}`} />
      <span className={`text-${getStatusColors(event.status)}`}>{event.status}</span>
    </div>
  );
}
