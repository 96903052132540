import { ReactComponent as IconAlert } from 'assets/icons/alert-icon.svg';

interface AlertBarProps {
  title: string;
  description: string;
  onAction: () => void;
  actionText: string;
}

export function AlertBar({ title, description, onAction, actionText }: AlertBarProps) {
  return (
    <div className="p-4 bg-success-alert rounded-lg w-full flex items-center justify-between text-white mb-5">
      <div className="flex items-center gap-2">
        <IconAlert className="w-3.5 h-3.5" />
        <span className="text-sm font-medium">{title}</span>
        <span className="text-xs font-normal">{description}</span>
      </div>
      <button
        type="submit"
        className="bg-white rounded-full py-1 px-3 text-sm text-success-alert"
        onClick={() => onAction()}
      >
        {actionText}
      </button>
    </div>
  );
}
