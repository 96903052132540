import { createApi } from '@reduxjs/toolkit/query/react';

import { MalwareDetectionResults } from 'models/malwareDetections';

import { GET_MALWARE_DETECTIONS } from 'constants/apiUrls';

import { baseQuery } from './baseQuery';

export const malwareDetectionsApi = createApi({
  reducerPath: 'malwareDetectionsAPI',
  baseQuery: baseQuery,
  tagTypes: ['malware-detections'],
  endpoints: (builder) => ({
    getMalwareDetections: builder.query<MalwareDetectionResults, { orgId: string }>({
      query: ({ orgId }) => {
        return GET_MALWARE_DETECTIONS(orgId);
      },
      providesTags: ['malware-detections'],
    }),
  }),
});

export const { useGetMalwareDetectionsQuery } = malwareDetectionsApi;
