interface SettingsTabProp {
  title: string;
  count: number;
  isSelected?: boolean;
  onClick: () => void;
}

export function SettingsTab({ title, count, isSelected, onClick }: SettingsTabProp): JSX.Element {
  return (
    <button
      className={`py-3 px-4 flex justify-between items-center ${isSelected ? 'bg-black text-white' : 'bg-white text-light-grey'} rounded-md flex-1 text-xs min-w-40`}
      onClick={() => onClick()}
      type="button"
    >
      <span>{title}</span>
      <span className={`${isSelected ? 'text-white' : 'text-black'}`}>{count}</span>
    </button>
  );
}
