import { createApi } from '@reduxjs/toolkit/query/react';
import { Profile } from 'models/profileOverview';

import { PROFILE_OVERVIEW_URL } from 'constants/apiUrls';

import { setUserLoading } from 'redux-store/slices/users';

import { baseQuery } from './baseQuery';

export const profileOverviewApi = createApi({
  reducerPath: 'profileOverviewApi',
  baseQuery: baseQuery,
  tagTypes: ['Profile'],
  endpoints: (builder) => ({
    getProfileOverview: builder.query<Profile, { orgId: string; userId: string }>({
      query: ({ orgId, userId }) => `${PROFILE_OVERVIEW_URL(orgId, userId)}`,
      providesTags: ['Profile'],
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded, cacheEntryRemoved }) {
        await cacheDataLoaded;

        dispatch(setUserLoading(false));

        await cacheEntryRemoved;
      },
    }),
  }),
});

export const { useGetProfileOverviewQuery } = profileOverviewApi;
