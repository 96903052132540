// eslint-disable-next-line import/no-extraneous-dependencies
import * as Dialog from '@radix-ui/react-dialog';

interface ActionHeaderProp {
  title: string;
  description: string;
  logoSvg?: React.ReactNode;
  logoBg?: string;
  actionComponent: React.ReactNode;
  actionDescription?: string;
  width?: string;
  actionComponentOpen: boolean;
  onActionClick: () => void;
  onClose: () => void;
}

export function ActionHeader({
  title,
  description,
  logoSvg,
  logoBg,
  actionComponent,
  actionDescription,
  width = 'w-1/3',
  actionComponentOpen,
  onActionClick,
  onClose,
}: ActionHeaderProp): JSX.Element {
  return (
    <div className="w-full flex items-center justify-between bg-white border-light border-light-grey rounded-lg py-4 px-6">
      <div className="flex items-center gap-6">
        <div className={`${logoBg}  w-8 h-8 rounded-full flex items-center justify-center`}>
          {logoSvg}
        </div>
        <div className="flex flex-col justify-between gap-1">
          <span className="text-sm"> {title} </span>
          <span className="text-xs text-light-grey"> {description} </span>
        </div>
      </div>
      <Dialog.Root open={actionComponentOpen} onOpenChange={onActionClick}>
        <Dialog.Trigger>
          <button
            type="button"
            className="bg-black py-3 px-6 flex items-center justify-center gap-2 text-white text-xs rounded-lg"
            onClick={() => onActionClick()}
          >
            <span>{actionDescription}</span>
            <span className="text-base">+</span>
          </button>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay
            className="bg-black/50 opacity-30 fixed inset-0"
            onClick={() => onClose()}
          />
          <Dialog.Content
            className={`bg-white rounded-lg fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 animate-fade-in ${width}`}
            onClick={(e) => e.stopPropagation()}
          >
            {actionComponent}
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
}
