import * as Progress from '@radix-ui/react-progress';

interface ScaleProps {
  value: number;
  indicatorColor: string;
}

export default function Scale({ value, indicatorColor }: ScaleProps) {
  return (
    <Progress.Root className="w-20 h-1 rounded-full bg-select-background flex flex-col gap-2">
      <Progress.Indicator
        className={`h-full rounded-full bg-brand-600 transition-all bg-${indicatorColor}`}
        style={{ transform: `translateX(-${100 - value}%)` }}
      />
    </Progress.Root>
  );
}
