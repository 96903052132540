import { ReactComponent as IconAlert } from 'assets/icons/alert.svg';

interface EventAlertProps {
  count: number;
}

export function EventAlert({ count }: EventAlertProps) {
  if (!count) {
    return null;
  }

  return (
    <div className="w-fit flex gap-2 px-1 py-0.5 bg-soft-red rounded-md justify-center items-center h-4">
      <IconAlert className="fill-critical-red h-2.5 w-2.5" />
      <span className="text-2xs text-critical-red">{count}</span>
    </div>
  );
}
