import { toTitleCase } from './name';

export function splitURL(url: string): string {
  const replacedURL = url.replace('http://', '');

  const allSegment = replacedURL.split('.');

  if (allSegment.length > 0) {
    return toTitleCase(allSegment[0]);
  }

  return '-';
}
