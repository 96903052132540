import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import KeycloakService from 'service/keycloak';

function Logout(): JSX.Element {
  const navigate = useNavigate();
  useEffect(() => {
    KeycloakService.clearToken();
    navigate('/login', { replace: true });
  }, [navigate]);
  return <div>Logging you out</div>;
}

export default Logout;
