import { useEffect, useState } from 'react';

export const useOrgId = () => {
  const [orgId, setOrgId] = useState<string>(() => {
    return localStorage.getItem('orgId') || '';
  });

  // Update localStorage whenever OrgId changes
  useEffect(() => {
    localStorage.setItem('orgId', orgId);
  }, [orgId]);

  return [orgId, setOrgId] as const;
};
