import { ReactComponent as IconNoEvents } from 'assets/icons/icon-no-events.svg';

interface NoDataFoundProps {
  description: string;
}
export function NoDataFound({ description = 'No data found' }: NoDataFoundProps): JSX.Element {
  return (
    <div className="px-6 py-3 flex items-center my-8 gap-4 bg-white rounded-b-lg">
      <IconNoEvents className="w-6 h-6" />
      <span className="text-sm text-light-grey">{description}</span>
    </div>
  );
}
