import { useState } from 'react';

import * as Dialog from '@radix-ui/react-dialog';
import * as Checkbox from '@radix-ui/react-checkbox';

import { Event } from 'models/events';

import { ReactComponent as IconCheck } from 'assets/icons/checkbox-icon.svg';

import { useRemediateMessageMutation } from 'service/eventsApi';
import { useForceLogoutMutation } from 'service/users';
import { useOrgId } from 'hooks/useOrgId';

import Loader from 'components/Loader';
import { useToast } from 'hooks/toast';
import { ActionToast } from 'components/Toasts/ActionToast';
import { getInitials } from 'utils/name';
import { WarningToast } from 'components/Toasts/WarningToast';
import { MOVE_TO_JUNK, QUARANTINE_MESSAGE } from 'constants/remediationActions';

import { getInferences, getThreatDetailsByCode } from './data';
import { EmailAnalysisItem } from './EmailAnalysisItem';

interface EventOverviewProps {
  event: Event;
  open: boolean;
  onClose: () => void;
  generateActionDescription: (
    checkedStatus: Record<'logout' | 'quarantine', boolean>,
    actionResult: Record<'logout' | 'quarantine', boolean>
  ) => string;
}

export function EventOverview({
  open,
  onClose,
  event,
  generateActionDescription,
}: EventOverviewProps): JSX.Element {
  const { showToast } = useToast();

  const [checkedStateOfActions, setCheckedStateOfActions] = useState({
    logout: false,
    quarantine: false,
  });

  const [loading, setLoading] = useState(false);

  const [remediationAction, setRemediationAction] = useState<'junk' | 'quarantine' | ''>('');

  const handleToggle = (action: 'logout' | 'quarantine') => {
    setCheckedStateOfActions((prevState) => ({
      ...prevState,
      [action]: !prevState[action],
    }));
  };

  const handleRemediateCheck = (remediateAction: 'junk' | 'quarantine') => {
    setRemediationAction(checkedStateOfActions.quarantine === true ? '' : remediateAction);
    handleToggle('quarantine');
  };

  const [remediateMsg] = useRemediateMessageMutation();

  const [forceLogoutUser] = useForceLogoutMutation();

  const [OrgId] = useOrgId();

  // handleRemediate completes 2 actions:
  // 1. Quarantine the mail
  // 2. Logout the user
  // depending on whether the user has checked the checkboxes or not
  const handleRemediate = async () => {
    if (!(checkedStateOfActions.logout || checkedStateOfActions.quarantine)) {
      showToast({
        component: <WarningToast message="Please select at least one action" />,
      });
      return;
    }

    setLoading(true);

    const actionResult = {
      logout: false,
      quarantine: false,
    };

    try {
      if (checkedStateOfActions.logout) {
        await forceLogoutUser({
          orgId: OrgId,
          employeeId: event.employeeId,
        }).unwrap();

        actionResult.logout = true;
      }

      if (checkedStateOfActions.quarantine) {
        await remediateMsg({
          orgId: OrgId,
          messageId: event.messageId,
          folder: remediationAction === 'junk' ? MOVE_TO_JUNK : QUARANTINE_MESSAGE,
        }).unwrap();

        actionResult.quarantine = true;
      }

      showToast({
        component: (
          <ActionToast
            description={generateActionDescription(checkedStateOfActions, actionResult)}
            showUndo={false}
            initials={getInitials(event.subject, 1)}
          />
        ),
      });
    } catch {
      showToast({
        component: (
          <ActionToast
            description={generateActionDescription(checkedStateOfActions, actionResult)}
            showUndo={false}
            initials={getInitials(event.subject, 1)}
          />
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50" onClick={onClose} />
        <Dialog.Content
          className="fixed top-1/2 left-1/2 flex justify-center transform -translate-x-1/2 -translate-y-1/2 w-3/4"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="w-full max-h-overview-modal bg-white rounded-lg flex justify-center">
            <div className="w-2/3 flex flex-col h-full overflow-y-auto no-scrollbar">
              <div className="p-8 flex justify-between items-center gap-16 border-b-light border-border-primary">
                {/* <span className="w-12 h-12 text-white bg-critical-red rounded-lg flex justify-center items-center text-2xl">
                  91
                </span> */}
                <span>
                  {event?.threats?.length
                    ? getThreatDetailsByCode(event.threats[0].code).text
                    : 'No Threat'}
                </span>
              </div>
              <div className="flex-1">
                <div className="p-8">
                  <EmailAnalysisItem
                    title="Email Subject"
                    value={[{ text: event.subject, highlight: false }]}
                    showHr
                  />
                  <EmailAnalysisItem
                    title="Sender Email"
                    value={[{ text: event.sender.email, highlight: false }]}
                    inferences={getInferences('sender', event)}
                    showHr
                  />
                  <EmailAnalysisItem
                    title="Recepient Email"
                    value={
                      event?.recipients?.map((recipient) => ({
                        text: recipient.email,
                        highlight: false,
                      })) || [{ text: 'No Recipients', highlight: false }]
                    }
                    inferences={getInferences('recipients', event)}
                    showHr
                  />
                  <EmailAnalysisItem
                    title="Attachment File Name"
                    value={
                      event?.attachments?.map((attachment) => ({
                        text: attachment.name,
                        highlight: attachment.suspicious,
                      })) || [{ text: 'No Attachments', highlight: false }]
                    }
                    inferences={getInferences('attachments', event)}
                    showHr
                  />
                  <EmailAnalysisItem
                    title="URL Links"
                    inferences={getInferences('links', event)}
                    value={
                      event?.links?.map((link) => ({
                        text: `${link.root}${link.path}`,
                        highlight: link.suspicious,
                      })) || [{ text: 'No URLs', highlight: false }]
                    }
                  />
                </div>
              </div>
            </div>
            <div className="w-1/3 border-l-light border-border-primary p-8 flex flex-col justify-between gap-8">
              <div className="flex flex-col gap-8">
                <span className="text-lg text-light-grey">Suggested Remediation Actions</span>
                <div className="w-full flex justify-between gap-4">
                  <button
                    className="flex flex-col gap-1"
                    onClick={() => handleToggle('logout')}
                    type="button"
                  >
                    <span className="text-xs">Log Out Recepients & Reset Password</span>
                    <span className="text-2xs text-light-grey text-start">
                      Recipient will be signed out of all active sessions & their password will be
                      reset.
                    </span>
                  </button>
                  <Checkbox.Root
                    className="w-3.5 h-3.5 border border-light-grey rounded text-center flex justify-center items-center p-1"
                    checked={checkedStateOfActions.logout}
                    onClick={() => handleToggle('logout')}
                  >
                    <Checkbox.Indicator>
                      <IconCheck />
                    </Checkbox.Indicator>
                  </Checkbox.Root>
                </div>
                {event.status === 'Pending' ? (
                  <>
                    <div className="w-full flex justify-between gap-4 relative">
                      {remediationAction === 'quarantine' && (
                        <div className="absolute inset-0 bg-white/50 z-10 cursor-not-allowed" />
                      )}
                      <button
                        className="flex flex-col gap-1"
                        onClick={() => handleRemediateCheck('junk')}
                        type="button"
                      >
                        <span className="text-xs">Push Mail to Junk</span>
                        <span className="text-2xs text-light-grey text-start">
                          This email will be moved to the Junk folder
                        </span>
                      </button>
                      <Checkbox.Root
                        className="w-3.5 h-3.5 border border-light-grey rounded text-center flex justify-center items-center p-1"
                        checked={checkedStateOfActions.quarantine && remediationAction === 'junk'}
                        onClick={() => handleRemediateCheck('junk')}
                      >
                        <Checkbox.Indicator>
                          <IconCheck />
                        </Checkbox.Indicator>
                      </Checkbox.Root>
                    </div>
                    <div className="w-full flex justify-between gap-4 relative">
                      {remediationAction === 'junk' && (
                        <div className="absolute inset-0 bg-white/50 z-10 cursor-not-allowed" />
                      )}

                      <button
                        className="flex flex-col gap-1"
                        onClick={() => handleRemediateCheck('quarantine')}
                        type="button"
                      >
                        <span className="text-xs">Push Mail to Quarantine</span>
                        <span className="text-2xs text-light-grey text-start">
                          This email will be moved to a quarantine folder where it can be briefly
                          accessed in for 7 days
                        </span>
                      </button>
                      <Checkbox.Root
                        className="w-3.5 h-3.5 border border-light-grey rounded text-center flex justify-center items-center p-1"
                        checked={
                          checkedStateOfActions.quarantine && remediationAction === 'quarantine'
                        }
                        onClick={() => handleRemediateCheck('quarantine')}
                      >
                        <Checkbox.Indicator>
                          <IconCheck />
                        </Checkbox.Indicator>
                      </Checkbox.Root>
                    </div>
                  </>
                ) : null}
              </div>
              <button
                className="w-full bg-critical-red rounded-full text-white p-4 text-center text-base"
                type="button"
                onClick={() => handleRemediate()}
              >
                {loading ? <Loader color="white" /> : 'Remediate Now'}
              </button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
