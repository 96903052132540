import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import Logo from 'assets/logo/logo-with-name.png';

interface ProtectedRouteProps extends PropsWithChildren {
  children: ReactNode;
}

function LoadingSpinner() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 bg-login ">
      <div className="border-[#E1E5E8] bg-white rounded-2xl min-w-96 shadow-[0rem_.25rem_1.5rem_0rem_rgba(0,0,0,0.15)]">
        <div className="text-center p-9 pl-28 pr-28">
          <img className="h-9 inline-block" src={Logo} alt="RavenMail" />
        </div>
        <hr className="border-[#E1E5E8]" />
        <div className="h-80 pt-48 pb-48 flex justify-center align-middle">
          <div className="self-center after:size-8 after:absolute after:bg-white after:left-1 after:rounded-full after:right-1 after:top-1 after:bottom-1 after:m-auto size-12 rounded-full bg-gradient-to-r from-purple-500 via-pink-500 to-sky-500 animate-spin" />
        </div>
      </div>
    </div>
  );
}

function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { authenticated, initializing, onboardingStatus } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    if (!initializing) {
      if (!authenticated) {
        navigate('/login', { replace: true, state: { from: location } });
      } else if (
        onboardingStatus === 'PENDING_ADMIN_CONSENT' &&
        location.pathname !== '/onboarding'
      ) {
        navigate('/onboarding', { replace: true, state: { from: location } });
      }
      setIsChecking(false);
    }
  }, [authenticated, navigate, location, initializing, onboardingStatus]);

  if (initializing || isChecking || !authenticated) {
    return <LoadingSpinner />;
  }

  /**
   * Here we need to wrap the children with jsx fragment
   * as react build fails to identify this as a valid JSX component if not done so.
   */
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export default ProtectedRoute;
