interface ILegendMarkerProps {
  textColor?: string;
  markerColor?: string;
  text?: string;
  dotted?: boolean;
}

export default function LegendMarker({ textColor, markerColor, text, dotted }: ILegendMarkerProps) {
  return (
    <div className="flex gap-2 items-center">
      <span
        className={`w-2 h-2 rounded-full ${
          dotted ? `bg-white border-2 border-dotted border-${markerColor}` : `bg-${markerColor}`
        }`}
      />
      <span className={`text-${textColor} text-xs`}>{text}</span>
    </div>
  );
}
